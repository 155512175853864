// import type {  Dispatch, SetStateAction } from "React"
import type { Offer, Product } from "schema-dts";

import clsx from "clsx";
import getSymbolFromCurrency from "currency-symbol-map";
import { Formik, Form, FormikHelpers } from "formik";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system";

import { functions as functionsPath } from "../utils/paths";

import { BagContext } from "./Bag";
import { Button } from "./Button";
import { ProductSelectorColour } from "./ProductSelectorColour";
import { ProductSelectorSize } from "./ProductSelectorSize";
import { SnackbarContext } from "./Snackbar";
import { AddValues, addToCart } from "../utils/carts";
import { gtmEventActions } from "../utils/gtm";
import { ProductSelectorType } from "./ProductSelectorType";

export type QuickBuyProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps & {
    product: Product;
    showPrice: boolean;
    showVariants: boolean;
  };

export const QuickBuyStyled: React.FC<QuickBuyProps> = styled.div`
  ${compose(color, flexbox, grid, layout, position, space)}
`;

export const QuickBuy: React.FC<QuickBuyProps> = ({
  product,
  showToggle = false,
  ...props
}) => {
  const { bag, setBag } = useContext(BagContext);
  const { labelText, setSnackbar } = useContext(SnackbarContext);

  const [isInBag, setIsInBag] = useState(false);

  const offer = product?.offers as Offer;

  useEffect(() => {
    (async function getCarts() {
      const path = `${functionsPath}/carts`;

      const url = new URL(path, `${process.env.GATSBY_SITE_URL}`);

      const response = await fetch(url.toString(), {
        headers: {
          Accept: "application/json",
        },
        method: "GET",
      });

      if (response.ok) {
        const bags = await response.json();

        const present = bags.items.find(
          item => (item.id = product?.identifier)
        );

        setIsInBag(present);
      }
    })();
  }, []);

  const isSample = () => product?.name.toLowerCase().includes("sample");

  const isVariant = name =>
    props.showVariants &&
    Array.isArray(product?.hasVariant) &&
    product?.hasVariant?.some(variant => variant.variesBy[name]);

  const hasRefill = () =>
    props.showVariants &&
    Array.isArray(product?.hasVariant) &&
    product?.variesBy['has_refill'] && product?.variesBy['has_refill'].toLowerCase() == 'y';

  return (
    <QuickBuyStyled {...props}>
      <Formik
        className={clsx("container", "form-container")}
        initialValues={{
          identifier: product?.identifier,
        }}
        onSubmit={async (
          values: AddValues,
          { setSubmitting }: FormikHelpers<AddValues>
        ) => {

          if (isSample()) return;

          gtmEventActions(product, "addToCart", "add", 1);
          await addToCart(values, setSubmitting, setBag, setSnackbar);
        }}
      >
        <Form className={clsx("quick-buy")}>
          {isVariant("display_size") && (
            <ProductSelectorSize
              marginBottom={{ _: 2, md: 4 }}
              product={product}
            />
          )}
          {isVariant("swatch_colour") && (
            <ProductSelectorColour
              marginBottom={{ _: 2, md: 4 }}
              product={product}
            />
          )}

          {showToggle && isVariant("swatch_colour") && (hasRefill() || product?.name.toLowerCase().includes("refill")) && (
            <ProductSelectorType
              marginBottom={{ _: 2, md: 4 }}
              product={product}
            />
          )}

          
            <Button
              type="submit"
              variant="primary"
              py={{ md: 4 }}
              px={{ md: 9 }}
              className="add-to-bag-btn"
              disabled={isSample()}
            >
              {props.showPrice && (
                <React.Fragment>
                  <span
                    itemProp="offers"
                    itemScope
                    itemType="https://schema.org/Offer"
                  >
                    <span className="product-price" itemProp="price">
                      {`${getSymbolFromCurrency(
                        offer?.priceCurrency as string
                      )}${offer?.price}`}
                    </span>
                    {offer?.availability && (
                      <link
                        href={offer?.availability as string}
                        itemProp="availability"
                      />
                    )}
                  </span>{" "}
                  |
                </React.Fragment>
              )}
              <span>{isInBag ? "Added to bag" : "Add to bag"}</span>
            </Button>
            
        </Form>
      </Formik>
    </QuickBuyStyled>
  );
};
