import React from "react"
import styled from "styled-components"
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system"

import { Button, LinkButton } from "./Button"
import { Modal } from "./Modal"

const WrapperStyled = styled.div`
  // padding-top: 2.25rem;

  h1 {
    text-align: center;
  }

  p {
    text-align: center;
    margin-block-end: 2rem;
  }

  a {
    max-width: 250px;
    margin: auto;
  }

  form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1.5rem;
    row-gap: 0.75rem;
    grid-template-areas:
      "shipping language currency"
      "shipping-option language-option currency-option"
      " . button .";
    .label {
      text-transform: uppercase;
      font-weight: bold;
      font-size: var(--font-size-heading-4, 13px);
    }

    .shipping-label {
      grid-area: shipping;
    }

    .shipping-selection {
      grid-area: shipping-option;
    }

    .language-label {
      grid-area: language;
    }

    .language-selection {
      grid-area: language-option;
    }

    .currency-label {
      grid-area: currency;
    }

    .currency-selection {
      grid-area: currency-option;
    }

    .button {
      grid-area: button;
    }
  }

  ${compose(color, flexbox, grid, layout, position, space)}
`

export type ModalSiteSettingsProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps & {
    closeModal: React.DispatchWithoutAction
    modalIsOpen: boolean
  }

export const ModalSiteSettings = ({
  closeModal,
  modalIsOpen = false,
  ...props
}) => {

  const desktopStyle = {
    maxWidth: "580px",
    maxHeight: "350px",
    margin: "auto",
    height: "auto",
    overflowX: "hidden",
  };



  return (
    <Modal
      closeModal={closeModal}
      contentLabel="Country Selector"
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      ModalOverlayStyleCustom={desktopStyle}
    >
      <WrapperStyled {...props}>
        <h1>Shipping Outside the US?</h1>

        <p>Please visit our global site</p>

        <LinkButton to={"https://beautybylaperla.com/"} variant="primary">
          Visit Site
        </LinkButton>

      </WrapperStyled>
    </Modal>
  )
}
