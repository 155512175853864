import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import Chevron from "../../../static/icons/Chevron.svg";
import { Layout } from "../../components/Layout";
import { Button } from "../../components/Button";
import { SEO } from "../../components/SEO";
import { mediaQueries } from "../../theme";
import { resetPassword } from "../../utils/password_dataService";
import { UserContext } from "../../components/User";
import { useContext } from "react";
import { PasswordReset } from "../../components/PasswordReset";
import { PasswordResetResult } from "../../components/PasswordResetResult";

const ColumnTwoStyled = styled.section`
  display: grid;
  grid-auto-flow: row;
  gap: ${themeGet("space.5")}px;
  padding: 30px 20px;

  ${mediaQueries.sm} {
    padding: 70px 20px;
    padding-inline-end: 4rem;
    padding-inline-start: 4rem;
    max-width: 640px;
    margin: auto;
  }

  // .reset-block {
  //   ${mediaQueries.md} {
  //     padding-block-start: ${themeGet("space.6")}px;
  //     padding-block-end: ${themeGet("space.6")}px;
  //   }
  // }

  .sign-out-btn {
    ${mediaQueries.md} {
      max-width: 354px;
    }
  }

  .account-title {
    font-size: 24px;
    margin: 0;
    ${mediaQueries.md} {
      display: initial;
    }
  }

  .account-description {
    font-size: var(--font-size-body, 13px);
    margin: 0;
  }



  form {
    display: grid;
    grid-auto-flow: row;
    gap: 14px;
    margin-top: 16px;

    // ${mediaQueries.md} {
    //   padding-block-start: ${themeGet("space.6")}px;
    //   padding-block-end: ${themeGet("space.6")}px;
    // }

    label {
      font-size: var(--font-size-body, 13px);
    }

    .input-container {
      position: relative;
  
      input {
        width: 100%;
      }
  
      .visibility-icon {
        position: absolute;
        right: 15px;
        top: 16px;
        cursor: pointer;
      }
    }

    .reset-btn {
      margin-top: 16px;
    }
  }

  h2 {
    font-size: ${themeGet("fontSizes.6")}px;
    margin: 0;
  }
`;

const AccordionGrid = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: ${themeGet("space.6")}px;
`;

const PasswordPage: React.FC = () => {
  // const { user, setUser } = useContext(UserContext);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [isCustomerId, setIsCustomerId] = useState('');

  useEffect(() => {
    let url_string = window.location.href;

    let url = new URL(url_string);
    let c = url.searchParams.get("customer_id");

    if (c) {
      console.log("ChangePassword");
      setIsChangePassword(true);
      setIsCustomerId(c);
   
    } else {
      console.log("NOT ChangePassword");
      setIsChangePassword(false);
      setIsCustomerId('');
    }
  });

  return (
    <Layout>
      <SEO title="Reset Password" />
      <ColumnTwoStyled
        className={!isSuccess && "reset-block"}
        style={{ marginLeft: !isSuccess ? "0px" : "" }}
      >
        {!isSuccess ? (
         isChangePassword ? (<>
         <h1 className="account-title">Change Password</h1>
            <p className="account-description">
              Passwords must be at least 7 characters & contain both alphabets and numbers.
            </p>
           <PasswordReset setIsSuccess={setIsSuccess} isChangePassword={isChangePassword} isCustomerId={isCustomerId} />
         </>) : ( <>
            <h1 className="account-title">Reset Password</h1>
            <p className="account-description">
              Enter your email address and we will send you an email to reset
              your password.
            </p>
            {/* <br /> */}
            <PasswordReset setIsSuccess={setIsSuccess} />
          </>)
        ) : (
          isChangePassword ? <PasswordResetResult isChangePassword={isChangePassword} /> : <PasswordResetResult />
        )}
      </ColumnTwoStyled>
    </Layout>
  );
};

export default PasswordPage;
