import type {
  BackgroundPosition as BackgroundPositionProp,
    Colour as ColourProp,
    Image as ImageProp,
    Justify as JustifyProp,
    Link as LinkProp,
} from "../../types/components"

import { themeGet } from "@styled-system/theme-get"
import clsx from "clsx"
import React from "react"
import styled from "styled-components"
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system"

import { mediaQueries } from "../theme"

import { Link } from "./Button"
import ScrollAnimation from 'react-animate-on-scroll';
import { ImgOptimized } from "./ImgOptimized"

export const LayoutStyled = styled.aside`

  height: auto;
  height: ${props => ["shortHero"].includes(props.layout) ? "250px" : undefined};

  @supports (aspect-ratio: 1) {
    aspect-ratio: ${props =>
    ["video"].includes(props.layout) ? "16/9" : undefined};
    aspect-ratio: ${props =>
    ["hero", "overlay"].includes(props.layout) ? "3/4" : undefined};
     //aspect-ratio: ${props => ["shortHero"].includes(props.layout) ? "21/9" : undefined};
    aspect-ratio: ${props =>
    ["image", "imageAndTextOutside"].includes(props.layout)
      ? "3/4"
      : undefined};
    aspect-ratio: ${props =>
      ["image-gallery"].includes(props.layout) ? "3/2" : undefined};
    margin-top: ${props =>  ["embedVideo"].includes(props.layout) ? "1.8rem" : undefined};

    ${mediaQueries.md} {
      aspect-ratio: ${props =>
    ["hero", "overlay"].includes(props.layout) ? "21/9" : undefined};
      //aspect-ratio: ${props => ["shortHero"].includes(props.layout) ? "3/1" : undefined};
      aspect-ratio: ${props =>
    ["image", "imageAndTextOutside"].includes(props.layout)
      ? "3/4"
      : undefined};
    }
  }

  @supports not (aspect-ratio: 1) {
    padding-top: ${props =>
      {
        if(["hero"].includes(props.layout)) {
          return "134%"
        }
        if(["image-gallery"].includes(props.layout)) {
          return "66.66%"
        }
        else if(["embedVideo"].includes(props.layout)) {
          return "0";
        }
        return "56.25%";
      }
    };
    ${mediaQueries.md} {
      padding-top: ${props =>
        {
          if(["embedVideo"].includes(props.layout)) {
            return "0";
          }
          else {
            return "50%";
          }
        }
      };
    }
    margin-top: ${props =>  ["embedVideo"].includes(props.layout) ? "1.8rem !important" : undefined};
  }
  
  align-items: ${props => (props.layout === "column" ? "center" : "unset")};
  background-color: ${props =>
    ["column", "row"].includes(props.layout)
      ? themeGet(
        `colors.${props.colour ? props.colour : themeGet("colors.pink")}`
      )
      : "transparent"};

  border-radius: 0;
  ${mediaQueries.sm} {
    height: ${props => ["shortHero"].includes(props.layout) ? "360px" : undefined};
    margin-top: ${props =>  ["embedVideo"].includes(props.layout) ? "4.3rem !important" : undefined};
    border-radius: ${props =>
      props.isMobile ? "0" : (["hero", "shortHero"].includes(props.layout) ? "0" : themeGet("radii.4"))}px;  
  }
  ${mediaQueries.xsmDown} {
    margin-left: ${props => ["embedVideo"].includes(props.layout) ? "-1rem;" : undefined}  
    margin-right: ${props => ["embedVideo"].includes(props.layout) ? "-1rem;" : undefined}
  }

  display: grid;
  grid-auto-flow: ${props =>
    props.layout === "column" ? "column" : "row"};
  overflow: hidden;
  position: relative;

  .media {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: ${props => (props.backgroundPosition ? props.backgroundPosition : "center")};
    position: ${props =>
    ["hero", "shortHero", "image", "imageAndTextOutside", "overlay", "video", "embedVideo", "image-gallery"].includes(
      props.layout
    )
      ? "absolute"
      : "static"};
    top: 0;
    width: 100%;
  }

  .content {
    align-items: end;
    bottom: 0;
    color: ${props =>
    ["hero", "shortHero", "image", "imageAndTextOutside", "overlay", "video", "embedVideo"].includes(
      props.layout
    )
      ? themeGet("colors.white")
      : "inherit"};
    display: grid;
    justify-items: ${props =>
    props.justifyItems ? props.justifyItems : "start"};
    left: 0;
    min-height: 20%;
    padding-block-end: ${themeGet("space.4")}px;
    padding-block-start: ${themeGet("space.4")}px;
    padding-inline-end: ${themeGet("space.9")}px;
    padding-inline-start: ${themeGet("space.9")}px;
    pointer-events: none;
    position: ${props =>
    ["hero", "shortHero", "overlay", "video", "embedVideo"].includes(props.layout)
      ? "absolute"
      : "static"};
    right: 0;

    ${mediaQueries.md} {
      padding-block-end: ${props =>
    ["hero", "shortHero", "overlay", "video", "embedVideo"].includes(props.layout)
      ? themeGet("space.8")
      : themeGet("space.4")}px;
      padding-block-start: ${props =>
    ["hero", "shortHero", "overlay", "video", "embedVideo"].includes(props.layout)
      ? themeGet("space.8")
      : themeGet("space.4")}px;
      padding-inline-end: ${props =>
    ["hero", "shortHero", "overlay", "video", "embedVideo"].includes(props.layout)
      ? themeGet("space.12")
      : themeGet("space.9")}px;
      padding-inline-start: ${props =>
    ["hero", "shortHero", "overlay", "video", "embedVideo"].includes(props.layout)
      ? themeGet("space.12")
      : themeGet("space.9")}px;
      padding-inline-end: ${props =>
    ["hero", "shortHero"].includes(props.layout) ? "var(--app-gutter-x, 4rem)" : null};
      padding-inline-start: ${props =>
    ["hero", "shortHero"].includes(props.layout) ? "var(--app-gutter-x, 4rem)" : null};
    padding-right: ${props =>
      ["hero", "shortHero"].includes(props.layout) ? "50%" : null};
    }

    * {
      max-width: 90ch;
    }

    .title {
      font-size: var(--font-size-heading-1, 24px);
      letter-spacing: .5px;
      margin-block-end: unset;
      margin-block-start: ${themeGet("space.6")}px;
    }

    .animated > .title {
      margin-bottom: 1rem;
    }

    .tag {
      font-family: "Quicksand", sans-serif;
      font-size: var(--font-size-md, 12px);
      margin-block-end: unset;
      order: -1;
      text-transform: uppercase;
    }

    .text {
      color: inherit;
      margin-block-start: 1rem;
      transition: all 200ms fade-in;
    }

    a {
      color: inherit;
      display: block;
      font-weight: bold;
      margin-block-start: ${themeGet("space.8")}px;
      padding-block-end: ${themeGet("space.6")}px;
      pointer-events: auto;
      text-decoration: unset;
      text-transform: uppercase;
    }
  }

  .animated {
    -webkit-animation-duration: 200ms;
    animation-duration: 200ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    transition: all 300ms fade-in;
  }

  .greatthings-module_ml3__E3ZCW{
    font-weight: lighter;
    font-size: 1.2em;
    ${mediaQueries.md}{
      font-size: 1.7em;
    }

  }

  .display-desktop {
    display:none;
    ${mediaQueries.md}{
      display:inherit;
    }
  }
  .display-mobile {
    display:inherit;
    ${mediaQueries.md}{
      display:none;
    }
  }
  .display-hidden {
    display:none;
  }
  .embedVideo{
    border: none;
      iframe {
        border: none;
     }
  }
  ${compose(color, flexbox, grid, layout, position, space)}
`

export const BannerStyled = styled(LayoutStyled)``

enum LayoutProp {
  column, // card with two columns - image & content
  row, // card with two rows - image & content
  hero, // content overlaid on the background image, no rounded corners
  image, // background image with rounded corners
  imageAndTextOutside, // background image with rounded corners succeed by text
  overlay, // content overlaid on the background image, rounded corners
  video, // content overlaid on the background video
  embedVideo, // content with Vimeo content
  shortHero,
}

export const getContent: React.FC<BannerProps> = props => (
  <React.Fragment>
    {props.isAnimated ?
    (props.isAnimated === true ? props?.title && <ScrollAnimation animateIn='fadeIn'>
      <h1 className="title">{props?.title}</h1>
      </ScrollAnimation> : props?.title && <h1 className="title">{props?.title}</h1>)
    : props?.title && <h1 className="title">{props?.title}</h1>}
    {/* {props?.title && <h1 className="title"><GreatThings text={props?.title} /></h1>} */}
    {/* {props?.title && <h1 className="title">{props?.title}</h1>} */}
    {props?.tag && <h2 className="tag">{props ?.tag}</h2>}
    {props?.text && <span className="text" dangerouslySetInnerHTML={{
          __html: props?.text
        }}></span>}
    {props?.link && <Link to={props?.link ?.href}>{props ?.link ?.title}</Link>}
  </React.Fragment>
)

export type BannerProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps & {
    backgroundPosition: BackgroundPositionProp;
    colour: ColourProp
    image: ImageProp
    justifyItems: JustifyProp
    layout: LayoutProp
    link: LinkProp
    tag: String
    text: string
    title: String
    isMobile?: boolean
    isAnimated?: boolean
    iosHeight?: boolean
  }

const BannerStyledContent = (props: any) => {
  return <BannerStyled {...props}>
    {props?.image && (
      <ScrollAnimation animateIn='fadeIn' initiallyVisible={["hero", "shortHero", "image-gallery"].includes(props.layout)}>
        <ImgOptimized
          alt={props?.image?.alt as string}
          className={clsx("img-bl", "media", props?.mobile_image ? "display-desktop" : "")}
          src={props?.image?.src as string}
          title={props?.image?.title as string} />
        {props?.mobile_image &&
          <ImgOptimized
            alt={props?.mobile_image?.alt as string}
            className={clsx("img-bl", "media", props?.mobile_image ? "display-mobile" : "display-hidden")}
            src={props?.mobile_image?.src as string}
            title={props?.mobile_image?.title as string} />
          }
      </ScrollAnimation>
    )}
    {props.layout !== "imageAndTextOutside" && (
      (props?.tag || props?.text || props?.title || props?.link) && (
        <div
          className={clsx(
            "content",
            "inside",
            ["hero", "shortHero", "overlay", "video", "embedVideo"].includes(props.layout) &&
            "image-overlay-gradient"
          )}
        >
          {getContent(props)}
        </div>
      )
    )}
  </BannerStyled>
}

export const Banner: React.FC<BannerProps> = props => (
  <React.Fragment>
    {props?.link?.href ?
    <a href={props?.link?.href}>
      <BannerStyledContent {... props} />
    </a> :
    <BannerStyledContent {... props} />
    }

    {props.layout === "imageAndTextOutside" && (
      <div className={clsx("content", "outside")}>{getContent(props)}</div>
    )}
  </React.Fragment>
)
