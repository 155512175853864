import { mediaQueries } from "../theme"

export const field = `
    .field {
        display: flex;
        align-items: center;
        ${mediaQueries.md} {
        }
    }
`
