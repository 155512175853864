import { themeGet } from "@styled-system/theme-get"
import React from "react"
import styled from "styled-components"
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system"
import { mediaQueries } from "../theme"

export type WYSIWYGProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps & {
    markup: string
    width: "compact" | "full"
  }

export const WYSIWYGStyled: React.FC<WYSIWYGProps> = styled.section`
  ${compose(color, flexbox, grid, layout, position)}
  max-width: ${props => props.width === "compact" ? "50%" : "100%"};
  margin: 40px 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${themeGet("fontFamily", "Tiempos")};
    margin-block-start: 0.5rem;
  }

  h1 {
    font-size: var(--font-size-heading-1, 32px);
    letter-spacing: 0.04375rem;
  }

  h2 {
    font-size: var(--font-size-heading-2, 24px);
    letter-spacing: 0.4px;
  }

  h3 {
    font-size: var(--font-size-heading-3, 18px);
  }

  h4 {
    font-size: var(--font-size-heading-4, 16px);
  }

  h5 {
    font-size: var(--font-size-heading-5, 14px);
  }

  h6 {
    font-size: var(--font-size-heading-6, 12px);
  }

  p {
    font-size: var(--font-size-md, 13px);
    max-width: 90ch;
    line-height: 1.0625rem;
    letter-spacing: 0.0625rem;
    font-family: "Quicksand", sans-serif;
  }
  
  a {
    font-size: var(--font-size-md, 13px);
    letter-spacing: 0.0625rem;
    font-family: "Quicksand", sans-serif;
    font-weight: bold;
  }

  ${mediaQueries.md} {
    h2 {
      letter-spacing: 0.5px;
    }

    p {
    line-height: 1.25rem;
    }

    a {
      letter-spacing: 0.0875rem;
    }
  }


`

export const WYSIWYG: React.FC<WYSIWYGProps> = ({ markup, ...props }) => (
  <WYSIWYGStyled
    {...props}
    dangerouslySetInnerHTML={{
      __html: markup as string,
    }}
  />
)
