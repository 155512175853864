import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { useContext } from 'react';
import { Button, Link } from './Button';
import { SignIn, signIn, SignInSchema } from '../utils/user';
import { UserContext } from './User';
import { SnackbarContext } from './Snackbar';
import { dataLayerFormErrorsEvents } from '../utils/gtm';

export const SignInForm: React.FC<any> = props => {
  const { user, setUser } = useContext(UserContext);
  const { labelText, setSnackbar } = useContext(SnackbarContext);

  const submitClick = (events) => {

    if (events.values.emailAddress === '' || events.values.password === '') {
      let arr: any[] = [];

      for (let key of Object.keys(events.values)) {
        if(events.values[key] === '') {
             arr.push(key);
        }     
      }
      dataLayerFormErrorsEvents(arr);
    }
  }
  
  return (
    <>
      <Formik
        initialValues={{
          emailAddress: '',
          password: '',
        }}
        onSubmit={async (
          values: SignIn,
          { setSubmitting }: FormikHelpers<SignIn>,
        ) => {
          await signIn(values, setUser, setSnackbar);
          setSubmitting(false);
        }}
        validationSchema={SignInSchema}
      >
        {({ errors, touched, values }) => (
          <Form>
            <div className="input-container">
              <label htmlFor="emailAddress">EMAIL ADDRESS*</label>
              <Field
                as="input"
                id="emailAddress"
                name="emailAddress"
                placeholder="Enter your email address"
                type="email"
              />
              {errors.emailAddress && touched.emailAddress ? (
                <div className="error">{errors.emailAddress}</div>
              ) : undefined}
            </div>

            <div className="input-container">
              <label htmlFor="password">PASSWORD*</label>
              <div className="password-container">
                <Field
                  as="input"
                  id="password"
                  name="password"
                  placeholder="Enter your password"
                  type="password"
                />

                <span className="show-password"></span>
              </div>
              {errors.password && touched.password ? (
                <div className="error">{errors.password}</div>
              ) : undefined}
            </div>

            
            <div className="information">
              <Link
                  className={"animated link introTrigger"}
                  to="/account/password"
                >
                 FORGOT YOUR PASSWORD ?
                </Link>
            </div>

           

            <p className="bySigning">
              By signing up, you confirm that you have read and accepted our La
              Perla Beauty <a href="/terms-and-conditions"><b>Terms & Conditions</b></a> and <a href="/privacy-policy"><b>Privacy Policy.</b></a>
            </p>

            <Button
              type="submit"
              variant={
                errors.emailAddress || errors.password ? 'secondary' : 'primary'
              }
              py={{ md: 4 }}
              px={{ md: 9 }}
              onClick={() => {submitClick({values: values})}}
            >
              SIGN IN
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};
