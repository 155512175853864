// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-address-book-tsx": () => import("./../../../src/pages/account/address-book.tsx" /* webpackChunkName: "component---src-pages-account-address-book-tsx" */),
  "component---src-pages-account-index-tsx": () => import("./../../../src/pages/account/index.tsx" /* webpackChunkName: "component---src-pages-account-index-tsx" */),
  "component---src-pages-account-information-tsx": () => import("./../../../src/pages/account/information.tsx" /* webpackChunkName: "component---src-pages-account-information-tsx" */),
  "component---src-pages-account-orders-tsx": () => import("./../../../src/pages/account/orders.tsx" /* webpackChunkName: "component---src-pages-account-orders-tsx" */),
  "component---src-pages-account-password-tsx": () => import("./../../../src/pages/account/password.tsx" /* webpackChunkName: "component---src-pages-account-password-tsx" */),
  "component---src-pages-account-sign-in-tsx": () => import("./../../../src/pages/account/sign-in.tsx" /* webpackChunkName: "component---src-pages-account-sign-in-tsx" */),
  "component---src-pages-account-sign-up-tsx": () => import("./../../../src/pages/account/sign-up.tsx" /* webpackChunkName: "component---src-pages-account-sign-up-tsx" */),
  "component---src-pages-account-wishlist-tsx": () => import("./../../../src/pages/account/wishlist.tsx" /* webpackChunkName: "component---src-pages-account-wishlist-tsx" */),
  "component---src-pages-bag-tsx": () => import("./../../../src/pages/bag.tsx" /* webpackChunkName: "component---src-pages-bag-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-maintenance-tsx": () => import("./../../../src/pages/maintenance.tsx" /* webpackChunkName: "component---src-pages-maintenance-tsx" */),
  "component---src-pages-promotest-tsx": () => import("./../../../src/pages/promotest.tsx" /* webpackChunkName: "component---src-pages-promotest-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-business-card-tsx": () => import("./../../../src/templates/businessCard.tsx" /* webpackChunkName: "component---src-templates-business-card-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-general-tsx": () => import("./../../../src/templates/general.tsx" /* webpackChunkName: "component---src-templates-general-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

