import React, { useContext, useEffect, useState } from "react";
import { themeGet } from "@styled-system/theme-get";
import styled from "styled-components";
import clsx from "clsx";
import { mediaQueries } from "../../theme";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { SnackbarContext } from "../../components/Snackbar";

import { Layout } from "../../components/Layout";
import { ListPlain } from "../../components/ListPlain";
import { AccountLayout } from "../../components/AccountLayout";
import { SEO } from "../../components/SEO";
import { Banner } from "../../components/Banner";
import SkeletonProduct from "../../components/SkeletonProduct";
import { Button } from "../../components/Button";

import { AddressBookItem } from "../../components/AddressBookItem";
import {
  getAllAddressByCustomerId,
  AddressBook,
} from "../../utils/addressBook_dataService";
import { AddressBookInputFields } from "../../components/AddressBookInputFields";

const AddressBookColumn = styled(ListPlain)`
  .add-address {
    width: 100%;
    margin-top: 30px;
  }

  .account-title {
    margin: 0 0 40px 0;
    font-size: 32px;
  }
`;

const BannerSection = styled.div`
  .banner-section {
    padding: 0;
    margin-bottom: 40px;

    .accounts-imagebanner {
      aspect-ratio: unset;

      .image-overlay-gradient {
        background: linear-gradient(
          180deg,
          rgba(102, 95, 107, 0) 0%,
          #36313980 100%
        );
      }
    }

    .accounts-promobanner {
      aspect-ratio: unset;
      max-width: 1266px;
      margin: 70px auto;

      .image-overlay-gradient {
        background: linear-gradient(
          180deg,
          rgba(102, 95, 107, 0) 0%,
          #36313980 100%
        );
      }
    }
  }
`;

const AccountLayoutContainer = styled.div`
  max-width: 1266px;
  margin: auto;
`;

const AddressBookPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = useMediaQuery(mediaQueries.qmd);
  const [addressBook, setAddressBook]: any[] = useState({} as AddressBook);
  const [toggleAddNewAddress, setToggleAddNewAddress] = useState(false);


  useEffect(() => {
    getAllAddressByCustomerId()
      .then(addresses => {
        if (addresses) {
          setAddressBook(addresses.data);
          setIsLoading(false);
        }
      })
      .catch(err => {
        console.log("error: ", err);
      });
  }, []);

  return (
    <Layout>
      {/* <BannerSection>
        <section
          className={clsx(
            "banner-section",
            `${isMobile ? "mobile-container" : "container"}`
          )}
        >
          <Banner
            style={{ height: "326px" }}
            backgroundPosition="center center"
            className={clsx("banner accounts-imagebanner fade")}
            image={{
              alt: "Address Book",
              src:
                "https://eu-images.contentstack.com/v3/assets/blt2bb45aeece463821/blt8a6d7446ea74a3a7/61e75d72af1b76690d9f2c10/Show_Yourself_Some_Love.jpg?quality=80",
            }}
            isMobile={isMobile}
            justifyItems="left"
            layout="hero"
            tag="La Perla Beauty"
            iosHeight={true}
            title="Address Book"
            isAnimated={false}
          />
        </section>
      </BannerSection> */}
      <AccountLayoutContainer>
        <AccountLayout>
          <SEO title="My address book" />
          <AddressBookColumn>
          <h1 className="account-title">Address Book</h1>
            {!toggleAddNewAddress && (
              <ListPlain className="address-book-grid">
                {!isLoading ? (
                  addressBook?.length > 0 ? (
                    addressBook.map((address, index) => (
                      <li key={index}>
                        <AddressBookItem {...address} />
                      </li>
                    ))
                  ) : (
                    <li>
                      <span>You have no saved addresses</span>
                    </li>
                  )
                ) : (
                  [1, 2].map(loading => (
                    <li key={loading}>
                      <SkeletonProduct />
                    </li>
                  ))
                )}
              </ListPlain>
            )}

            {toggleAddNewAddress && (
              <AddressBookInputFields
                setToggleAddNewAddress={setToggleAddNewAddress}
              />
            )}
            {!toggleAddNewAddress && (
              <div>
                <Button
                  variant="primary"
                  py="5"
                  px="7"
                  className="add-address"
                  onClick={() => setToggleAddNewAddress(!toggleAddNewAddress)}
                >
                  Add New Address
                </Button>
              </div>
            )}
          </AddressBookColumn>
        </AccountLayout>
      </AccountLayoutContainer>
    </Layout>
  );
};

export default AddressBookPage;
