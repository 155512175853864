import React, { useEffect, useState } from "react";

import SkeletonItem from "./SkeletonItem";
import styled from "styled-components";

import { mediaQueries } from "../theme";

const SkeletonProductStyled = styled.div`
  .skeleton-wrapper {
    width: 100%;
    position: relative;
  }

  .shimmer-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .shimmer {
    width: 50%;
    height: 100%;
    opacity: 0.2;
    background: rgba(238, 238, 238, 0.966);
    transform: skewX(-20deg);
    animation: loader 1s linear infinite;
  }
  @keyframes loader {
    0% {
      transform: translateX(-150%);
    }
    50% {
      transform: translateX(-60%);
    }
    100% {
      transform: translateX(150%);
    }
  }
`;

const SkeletonProduct = () => {
  return (
    <SkeletonProductStyled>
      <div className="skeleton-wrapper">
        <SkeletonItem type="thumbnail" />
        <SkeletonItem type="text-lg" />
        <SkeletonItem type="text-md" />
        <SkeletonItem type="text-sm" />
        <div className="shimmer-wrapper">
          <div className="shimmer"></div>
        </div>
      </div>
    </SkeletonProductStyled>
  );
};

export default SkeletonProduct;
