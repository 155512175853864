import type { Product } from "schema-dts";

import type { ContentstackPromotionalBannerComponent } from "../../graphql-types";

import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system";

import { ProductCard } from "./ProductCard";
import { Banner } from "./Banner";
import { mediaQueries } from "../theme";

const ProductListingStyled = styled.ul`
  display: grid;
  list-style: none;
  padding: 0;
  row-gap: 40px;

  .promotional-banner {
    grid-column: ${props => (props.view === "grid" ? "span 2" : "span 1")};

    aside {
      border-radius: 20px;
      aspect-ratio: unset;
      // aspect-ratio: 3/3;
      // ${mediaQueries.md} {
      //   aspect-ratio: 21/10;
      // }
    }
  }

  ${compose(color, flexbox, grid, layout, position, space)}
`;

export type ProductListingProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps & {
    items?: Product[];
    promotionalBanners?: ContentstackPromotionalBannerComponent;
    view: "grid" | "list";
  };

export const ProductListing: React.FC<ProductListingProps> = props => {

  // const prodIdArray = props.items?.map((product: Product, index) => {
  //   let arraytemp: any[] = [];

  //   arraytemp.push(`'${product?.productID}'`);

  //   return arraytemp;
  // });

  // const bvInlineRatingScript = (
  //   <Helmet>
  //     <script type="text/javascript">
  //       {` 
  //       let prodArray = [];
  //       prodArray.push(${prodIdArray});

  //       console.log("BV CONFIG inline_ratings_array:", prodArray);
  //           $BV.ui( 'rr', 'inline_ratings', {
  //             productIds : prodArray,
  //           containerPrefix : 'BVRRInlineRating'
  //           });
  //       `}
  //     </script>
  //   </Helmet>
  // );

  const gridItems = props.items?.map((product: Product, index) => (
    <li className="product" key={`product-${index}`}>
      <ProductCard key={index} product={product} />
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(product)}</script>
      </Helmet>
    </li>
  ));

  // console.log("props.promotionalBanners:", props.promotionalBanners);
  props.promotionalBanners?.forEach((promotionalBanner, index) => {
    gridItems.splice(
      promotionalBanner?.grid_position,
      0,
      <li className="promotional-banner" key={`promotional-banner-${index}`}>
        <Banner {...promotionalBanner?.banner[0]} />
      </li>
    );
  });

  return (
    <ProductListingStyled
      borderTop={1}
      gridAutoFlow="row"
      gridColumnGap={
        props.view === "grid"
          ? { _: 6, sm: 6, md: 8, lg: 10 }
          : { _: 6, sm: 6, md: 8, lg: 10 }
      }
      // gridRowGap={
      //   props.view === "grid"
      //     ? { _: 4, sm: 6, md: 8, lg: 10 }
      //     : { _: 4, sm: 6, md: 8, lg: 10 }
      // }
      gridTemplateColumns={
        props.view === "grid"
          ? {
              _: "repeat(2, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(4, 1fr)",
              xl: "repeat(4, 1fr)",
            }
          : {
              _: "repeat(1, 1fr)",
              sm: "repeat(1, 1fr)",
              md: "repeat(2, 1fr)",
              xl: "repeat(2, 1fr)",
            }
      }
      view={props.view}
    >
      {gridItems}

      {/* {bvInlineRatingScript} */}
    </ProductListingStyled>
  );
};
