export const table = `
    table,
    tr,
    td {
        border-collapse: collapse;
        border-style: solid;
    }

    td {
        padding: 1ch;
    }
`