import { themeGet } from "@styled-system/theme-get";
import { Formik, Field, Form, FormikHelpers } from "formik";
import fetch from "node-fetch";
import React, { useState } from "react";
import { dataLayerEvents, dataLayerFormErrorsEvents } from "../utils/gtm";
import styled from "styled-components";
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system";

import { mediaQueries } from "../theme";

import { functions as functionsPath } from "../utils/paths";

import { Button } from "./Button";
import { Modal } from "./Modal";
import { useMediaQuery } from "../hooks/useMediaQuery";

const NewsletterStyled = styled.div`
  form {
    grid-template-columns: 2fr 1fr;

    ${mediaQueries.md} {
      align-items: center;
      gap: ${themeGet("space.6")}px;
      grid-auto-flow: column;
      grid-template-columns: unset;
      justify-content: flex-end;
    }
  }

  input {
    height: 100%;

    ${themeGet("mediaQueries.md")} {
      min-width: 14rem;
    }
  }

  label {
    display: none;

    ${mediaQueries.md} {
      display: block;
    }
  }

  .information {
    display: grid;
    justify-content: end;
    margin-block-end: 1rem;
  }

  .confirmation {
    display: block;
    justify-content: end;
    margin-block-start: 1rem;
    font-size: 8px;
    ${mediaQueries.md} {
      float: right;
      text-align: left;
      width: 38rem;
      display: none;
    }
  }
  .confirmation.not-empty,
  &:focus-within .confirmation {
    ${mediaQueries.md} {
      display: block;
    }
  }
  &:focus-within button {
    background-color: ${themeGet("colors.black")};
    color: ${themeGet("colors.white")};
  }

  &:focus-within input {
    border-color: ${themeGet("colors.black")};
    border-style: solid;
  }



  ${compose(color, flexbox, grid, layout, position, space)}
`;

export type NewsletterSignupProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps;

interface Values {
  emailAddress: string;
}

export const NewsletterSignup: React.FC = () => {
  // const [message, setMessage] = useState<string | undefined>(undefined);
  const [emailEmpty, setEmailEmpty] = useState<boolean>(true);
  const isMobile = useMediaQuery(mediaQueries.qmd);

  const [modalIsOpen, setIsOpen] = useState(false);

  function closeModal() {
    document.body.style.overflow = "scroll";
    setIsOpen(false);
  }

  function openModal() {
    document.body.style.overflow = "hidden";
    setIsOpen(true);
  }

  const validate = value => {
    let errorMessage;

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      errorMessage = "Invalid email address";
    }
    if (value === "") {
      setEmailEmpty(true);
    } else {
      setEmailEmpty(false);
    }

    // if(errorMessage) {
    //   dataLayerFormErrorsEvents(['emailAddress']);
    // }

    return errorMessage;
  };

  const submitClick = isError => {
    if (isError) {
      let arr: any[] = [];

      for (let key of Object.keys(isError.errors)) {
        // console.log(key);
        arr.push(key);
      }
      dataLayerFormErrorsEvents(arr);
    }
  };

  return (
    <NewsletterStyled>
      {/* {message && <div className="information">{message}</div>} */}
      <Formik
        initialValues={{
          emailAddress: "",
        }}
        onSubmit={async (
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {

          const path = `${functionsPath}/subscribe-to-our-newsletter`;

          const url = new URL(path, `${process.env.GATSBY_SITE_URL}`);

          const response = await fetch(url, {
            body: JSON.stringify(values),
            headers: {
              Accept: "application/json",
            },
            method: "POST",
          });

          setSubmitting(false);

          if (response.ok) {
            if (window.cookieAccepted) {
              dataLayerEvents("newsletter");
            }
            openModal();
            // setMessage("Thank you for subscribing");
          } else {
            dataLayerFormErrorsEvents(["emailAddress"]);
          }
        }}
      >
        <Form>
          <label htmlFor="emailAddress">Sign up to our newsletter</label>
          <Field
            as="input"
            id="emailAddress"
            name="emailAddress"
            placeholder="Enter your email address"
            type="email"
            validate={validate}
          />
          <Button
            type="submit"
            variant="secondary"
            py={{ md: 4 }}
            px={{ md: 9 }}
          >
            Sign up
          </Button>
        </Form>
      </Formik>
      <div className={emailEmpty ? "confirmation" : "confirmation not-empty"}>
        By clicking sign up, you are agreeing to receive marketing
        communications from La Perla Beauty in line with our{" "}
        <a href="/privacy-policy">privacy policy.</a>
      </div>

      <Modal
        closeModal={closeModal}
        contentLabel="Thanks for Subscribing"
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="newsletter-thankyou"
        ModalOverlayStyleCustom={{
          border: "none",
          borderRadius: 20,
          padding: "35px 36px",
          aspectRatio: isMobile ? "4/4" : "9/3",
          maxHeight: "322px",
          maxWidth: "948px",
          margin: "auto",
          backgroundColor: "#fff",
          height: "min-content",
        }}
      >

        <div className="content-container">
          <div className="image-container">
            <img src="/images/b0a09ce98fd5953510a511cb3c486184c16051f4.png" alt="" />
          </div>
          <div className="text-container">
            <h1>This is the beginning of something special...</h1>
            <p>Thank you for choosing to join us. We look forward to revealing more to you about our divine fragrance, bodycare and makeup collections.</p>
          </div>
        </div>


      </Modal>
    </NewsletterStyled>
  );
};
