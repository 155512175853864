import type {
  BigCommerceCategories,
  BigCommerceGql_CategoryTreeItem,
  CategoryPageQuery,
} from "../../graphql-types"

import { themeGet } from "@styled-system/theme-get"
import clsx from "clsx"
import { PageProps, graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { Breadcrumb } from "../components/Breadcrumb"
import { Button, Link } from "../components/Button"
import { Layout } from "../components/Layout"
import { ProductListing } from "../components/ProductListing"
import { MenuProductListing } from "../components/MenuProductListing"
import { SEO } from "../components/SEO"
import { MenuCategory } from "../components/MenuCategory"

import { mediaQueries } from "../theme"

import { standardiseBigCommerceProductGroup } from "../utils/standardiseBigCommerceProduct"
import { gtmEventEcommerceImpressions } from "../utils/gtm"
import { Helmet } from "react-helmet"
import { useMediaQuery } from "../hooks/useMediaQuery"

type PageContextCategory = PageContextTypeBreadcrumb & {
  category: BigCommerceCategories
}

const CategoryHeaderStyled = styled.header`
  display: grid;
  justify-items: center;
  margin-block-end: ${themeGet("space.7")}px;
  text-align: center;

  span {
    font-size: ${themeGet("fontSizes.1")}px;
  }

  ${mediaQueries.md} {
    text-align: center;
    span {
      font-size: revert;
    }
  }

  .read-more-button{
    color: #000;
  }

  .read-more-content{
    overflow: hidden;

    & > div > div,
    & > div > p,
    & > div > span{
      display: none;
    }

    ${mediaQueries.md} {
      & > div > div,
      & > div > p,
      & > div > span{
        display: block;
      }
    }

    & > div > div:first-child,
    & > div > p:first-child,
    & > div > span:first-child{
      display: block;
    }
  }

  .read-less-content {

    & > div{
      height: auto !important;
    }

    & > div > div{
      display: block;
    }
  }
`

const CategoryPage: React.FC<
  PageProps<CategoryPageQuery, PageContextCategory>
> = ({ data, pageContext }) => {
  const {
    breadcrumb: { crumbs },
    category,
    page,
  } = pageContext

  const [view, setView] = useState("grid");
  const isMobile = useMediaQuery(mediaQueries.qmd);
  const [readMoreOpen, setReadMoreOpen] = useState(false);

  const flatCategories = data.bigCommerceGQL?.site?.categoryTree?.flatMap(
    categoryTreeItem => [categoryTreeItem, ...categoryTreeItem?.children],
  );

  const currentCategory = flatCategories.find(
    (categoryTreeItem: BigCommerceGql_CategoryTreeItem) =>
      categoryTreeItem?.entityId === category?.bigcommerce_id,
  );

  const tabs = currentCategory?.children;

  const sortingOrder = data?.allBigCommerceProducts?.edges.reduce(
    (acc, { node }) =>  {acc[node.bigcommerce_id] = node.sort_order; return acc;}, {})

  const products = data?.bigCommerceGQL?.site?.route?.node?.products?.edges
  .map(({ node: productFormatBigCommerce }) =>
      standardiseBigCommerceProductGroup({
      productFormatBigCommerce,
    }),
  )
  .sort((a, b) => sortingOrder[a.productID] - sortingOrder[b.productID]);

  const promotionalBanners =
    data.allContentstackCategory?.edges?.[0]?.node?.banners;

  useEffect(() => {
    gtmEventEcommerceImpressions(products, category?.name ?? '');
    // gtmEventEcommerceClick(products, category?.name ?? '');

      let windowWidth = window.innerWidth;
      let readMoreContent = document.querySelector(".read-more-content").firstChild;
      let readMoreContentChild = readMoreContent.firstChild;
      let initialDescHeightStyle = getComputedStyle(readMoreContentChild);

      if(windowWidth < 960){

        if(readMoreContent.children.length === 1){
          readMoreContent.style.height = "34px";
        }
        else if(readMoreContent.children.length > 1){
          readMoreContent.style.height = ((parseInt(initialDescHeightStyle.height) + 10) + "px");
        }
        else{
          readMoreContent.style.height = "auto";
        }
      }

      window.addEventListener("resize", function(){
        windowWidth = window.innerWidth;

        if(windowWidth < 960){
          initialDescHeightStyle = getComputedStyle(readMoreContentChild);

          if(readMoreContent.children.length === 1){
            readMoreContent.style.height = "34px";
          }
          else if(readMoreContent.children.length > 1){
            readMoreContent.style.height = ((parseInt(initialDescHeightStyle.height) + 10) + "px");
          }
          else{
            readMoreContent.style.height = "auto";
          }
        }else{
          readMoreContent.style.height = "auto";

          //console.log("DESKTOP SIZE");
        }
      });
  },[]);

  return (
    <Layout>
      <SEO title={page?.title} />

      <Breadcrumb crumbs={crumbs} />

      <div className={clsx("container")} category-id={category?.bigcommerce_id}>

        <CategoryHeaderStyled>
          <h1>{category?.name}</h1>

          <div className={readMoreOpen ? "read-less-content" : "read-more-content"}>
            {category?.description && <div
              dangerouslySetInnerHTML={{
                __html: category?.description as string,
              }}
            ></div>}
          </div>

          {isMobile && category?.description && (
            <Button
              className="read-more-button"
              onClick={() => setReadMoreOpen(!readMoreOpen)}
            >
              {readMoreOpen ? "Read less" : "Read more"}
            </Button>
          )}
        </CategoryHeaderStyled>
      </div>

      {tabs?.length && (
        <MenuCategory marginTop={{ _: 4, sm: 4, md: 6, lg: 8 }}>
          {tabs.map(category => (
            <Link
              id={category?.entityId}
              key={category?.entityId}
              title={category?.name}
              to={category?.path}
            >
              {category?.name}
            </Link>
          ))}
        </MenuCategory>
      )}

      <MenuProductListing
        productCount={products.length}
        setView={setView}
        view={view}
      />

      <main className={clsx("container")}>
        <ProductListing
          items={products}
          promotionalBanners={promotionalBanners}
          view={view}
        />
      </main>

      <Helmet>
        {
          process.env.GATSBY_LOCALE?.toLowerCase() === "it" ?
            <script src="//emails.laperla.com/resources/sharing/popoverv2.js?sharing=lp-popover&domain=emails.laperla.com&id=6ZPY-56J%2Fitalian-signup%22&delay=0&mobile=&keydismiss=&width=600"></script>
            :
            process.env.GATSBY_ENV?.toLowerCase().includes("us") ?
              <script src="//r1.dotdigital-pages.com/resources/sharing/popoverv2.js?sharing=lp-popover&domain=r1.dotdigital-pages.com&id=6ZPY-35T%2Fpup-up%22&delay=5&mobile=&keydismiss=&width=600"></script>
              :
              <script src="//emails.laperla.com/resources/sharing/popoverv2.js?sharing=lp-popover&domain=emails.laperla.com&id=6ZPY-1HE%2Fwebsitepopupbeauty%22&delay=5&mobile=&keydismiss=&width=400"></script>
        }
      </Helmet>
    </Layout>
  )
}

export default CategoryPage

export const query = graphql`
   query CategoryPage($id: Int, $slug: String!, $locale: String) {
    allBigCommerceProducts {
      edges {
        node {
          sort_order
          bigcommerce_id
        }
      }
    }
     allContentstackCategory(filter: { bigcommerce_id: { eq: $id }, locale: {eq: $locale} }) {
      edges {
        node {
          ...Contentstack_categoryFragment
        }
      }
    }
    allContentstackProduct(filter: {locale: {eq: $locale}})  {
      edges {
        node {
          ...Contentstack_productFragment
        }
      }
    }
    bigCommerceGQL {
      site {
        route(path: $slug) {
          node {
            ... on BigCommerceGQL_Category {
              id
              name
              products(first: 50) {
                edges {
                  node {
                    ...BigCommerceGQL_ProductFragment
                  }
                }
              }
            }
          }
        }
        categoryTree {
          children {
            description
            entityId
            name
            path
            productCount
          }
          description
          entityId
          name
          path
          productCount
        }
      }
    }
  }
`
