import type { ProductGroup } from "schema-dts";

import clsx from "clsx";
import React from "react";
import styled from "styled-components";
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system";
import { Link } from "gatsby";

const ProductSelectorSizeStyled = styled.div`

  .form-fields {
    grid-auto-flow: column;
    justify-content: start;
    display: flex;
    flex-wrap: wrap;
  }

  .form-field-type {
    justify-content: flex-start;
  }

  .active label {
    text-decoration: underline;
    font-weight: bold;
  }

  ${compose(color, flexbox, grid, layout, position, space)}
`;

export type ProductSelectorTypeProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps & {
    productGroup: ProductGroup;
  };

export const ProductSelectorType: React.FC<ProductSelectorTypeProps> = ({
  product,
  ...props
}) => (
  <ProductSelectorSizeStyled {...props}>
    <h3 className="title" id="group_label_sizes">
      TYPE
    </h3>
    <div aria-labelledby="group_label_sizes" className={clsx("form-fields", "form-field-type")}>
      <Link
        to={product?.url.indexOf("refill") > -1 ? product?.url.replace("-refill", "") : product?.url}
        className={"field " + (product?.url.indexOf("refill") < 0 ? 'active' : '')}
      >
        <label className="product-radio-label">Standard</label>
      </Link>

      <Link
        to={product?.url.indexOf("refill") > -1 ? product?.url : product?.url.endsWith("/") ? product?.url.substring(0, product?.url.length - 1) + "-refill"
            : product?.url + "-refill"
        }
        className={"field " + (product?.url.indexOf("refill") > -1 ? 'active' : '')}
      >
        <label className="product-radio-label">Refill</label>
      </Link>
    </div>
  </ProductSelectorSizeStyled>
);