import clsx from "clsx"
import { Field, Form, Formik, FormikHelpers } from "formik"
import React, { useContext, useState } from "react"
import { dataLayerFormErrorsEvents } from "../../utils/gtm"
import styled from "styled-components"
import * as Yup from "yup"
import { Button, LinkButton } from "../../components/Button"
import { Layout } from "../../components/Layout"
import { SEO } from "../../components/SEO"
import { SnackbarContext } from "../../components/Snackbar"
import { register } from "../../utils/user"


interface Values {
  emailAddress: string
  firstName: string
  lastName: string
  password: string
}

const SignInSchema = Yup.object().shape({
  emailAddress: Yup.string().email("Invalid email").required("Required"),
  firstName: Yup.string().min(2, "Name is too short").required("Required"),
  lastName: Yup.string().min(2, "Name is too short").required("Required"),
  password: Yup.string().min(7, "Password is too short")
  .matches(/[a-zA-Z]+/ , 'Password must contain alphabetic characters.')
  .matches(/[0-9]+/ , 'Password must contain numeric characters.')
  .required("Required"),
})

const StyledThankYou = styled.div`
& {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

a {
  display: inline;
  margin-left: 1rem;
  line-height: 3rem;
}
`;

const SignUpForm: React.FC = () => {
  const { labelText, setSnackbar } = useContext(SnackbarContext);
  const [ hideForm, setHideForm ] = useState(false);

  const submitClick = (events) => {
    
    if (events.values.emailAddress === '' || events.values.firstName === '' || events.values.lastName === '' || events.values.password === '') {
      let arr: any[] = [];

      for (let key of Object.keys(events.values)) {
        if(events.values[key] === '') {
             arr.push(key);
        }     
      }
      dataLayerFormErrorsEvents(arr);
    }
  }

  
  
  return !hideForm ? (
    <Formik
      initialValues={{
        emailAddress: '',
        firstName: '',
        lastName: '',
        password: '',
      }}
      onSubmit={async (values: Values, { setSubmitting }: FormikHelpers<Values>, ) => {
        setHideForm(await register(values, setSnackbar));
        setSubmitting(false);
      }}
      validationSchema={SignInSchema} >

      {({ errors, touched, values }) => (
           
        <Form>
          <label htmlFor="emailAddress">Sign up</label>
          <Field
            as="input"
            id="firstName"
            name="firstName"
            placeholder="First Name"
            type="text"
          />
          {errors.firstName && touched.firstName ? (
            <div className="error">{errors.firstName}</div>
          ) : undefined}
          <Field
            as="input"
            id="lastName"
            name="lastName"
            placeholder="Last Name"
            type="text"
          />
          {errors.lastName && touched.lastName ? (
            <div className="error">{errors.lastName}</div>
          ) : undefined}
          <Field
            as="input"
            id="emailAddress"
            name="emailAddress"
            placeholder="Your email address"
            type="email"
          />
          {errors.emailAddress && touched.emailAddress ? (
            <div className="error">{errors.emailAddress}</div>
          ) : undefined}
          <Field
            as="input"
            id="password"
            name="password"
            placeholder="Create Password"
            type="password"
          />
          {errors.password && touched.password ? (
            <div className="error">{errors.password}</div>
          ) : undefined}
          <Button
            type="submit"
            variant={
              errors.firstName ||
              errors.lastName ||
              errors.emailAddress ||
              errors.password
                ? 'secondary'
                : 'primary'
            }
            py={{ md: 4 }}
            px={{ md: 9 }}
            onClick={() => {submitClick({values: values})}}
          >
            Sign up
          </Button>
          <p className="bySigning">
          By signing up, you confirm that you have read and accepted our La Perla Beauty <a href="/terms-and-conditions"><b>Terms & Conditions</b></a> and <a href="/privacy-policy"><b>Privacy Policy.</b></a>
          </p>
        </Form>
      )}

    </Formik>
  ) : (
    <StyledThankYou>
      Thank you for the registration. You can now
      <LinkButton to="/account/sign-in" variant="primary" py={5}>
        SIGN IN
      </LinkButton>
    </StyledThankYou>
  );
}

const SignUpPage: React.FC = () => {  
  return (
    <Layout>
      <SEO title="Sign up" />
      <main className={clsx("container")}>
        <h1>Sign up</h1>
        <SignUpForm />
      </main>
    </Layout>
  )
}

export default SignUpPage
