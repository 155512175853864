import clsx from "clsx"
import React from "react"
import { SignInForm } from "../../components/SignInForm"
import { Layout } from "../../components/Layout"
import { SEO } from "../../components/SEO"

interface Values {
  emailAddress: string
  password: string
}

const SignInPage: React.FC = () => (
  <Layout>
    <SEO title="Sign in" />
    <main className={clsx("container")}>
      <h1>Sign in</h1>
      <SignInForm />
    </main>
  </Layout>
)

export default SignInPage
