import type { Bag } from "../../types/BigCommerce";

import { functions as functionsPath } from "./paths";
import { Account, getUserToken } from "./user";
import Cookies from "universal-cookie";
import { navigate } from "gatsby";

export interface AddWishlistValues {
  identifier: string;
  product_id: string;
  name?: string;
  userToken?: string;
}


export const getWishlistsByCustomerToken = async (): Promise<any> => {
  const _userToken = getUserToken();

  if (!_userToken) {
    // navigate("/account");
    return { data: null, status: "NoLogin" };
  }

  const path = `${functionsPath}/wishlists?action=getAllWishlists&userToken=${_userToken}`;

  const url = new URL(path, `${process.env.GATSBY_SITE_URL}`);

  const response = await fetch(url.toString(), {
    headers: {
      Accept: "application/json",
    },
    method: "GET",
  });

  if (response.ok) {
    const data: any = await response.json();

    return { data: data, status: "Ok" };
  } else {
    // console.log("Order is not valid");
    return { data: null, status: "Failed" };
  }
};

export const getWishlistsByWishlistId = async (): Promise<any> => {

  const _userToken = getUserToken();

  if (!_userToken) {
    return { data: null, status: "NoLogin" };
  }

  const wishlistId = localStorage.getItem("wishlistId");

  if (!wishlistId) {
    return { data: null, status: "NoWishlist" };
  }

  // if (wishlistId) {
  const path = `${functionsPath}/wishlists?action=getAWishlist&wishlist_id=${wishlistId}`;
  // }

  const url = new URL(path, `${process.env.GATSBY_SITE_URL}`);

  const response = await fetch(url.toString(), {
    headers: {
      Accept: "application/json",
    },
    method: "GET",
  });

  if (response.ok) {
    const data: any = await response.json();

    return { data: data, status: "Ok" };
  } else {
    // console.log("Order is not valid");
    return { data: null, status: "Failed" };
  }
};

export const addToWishlist = async (
  values: AddWishlistValues,
  setSubmitting: (isSubmitting: boolean) => void
): Promise<any> => {
  let path = `${functionsPath}/wishlists?action=createWishlist`;

  const _userToken = getUserToken();

  if (!_userToken) {
    navigate("/account");
    return { data: null, status: "NoLogin" };
  }

  const wishlistId = localStorage.getItem("wishlistId");

  if (wishlistId) {
    path = `${functionsPath}/wishlists?action=addWishlistItems&wishlist_id=${wishlistId}`;
  } else {
    values.name = "My Wishlist";
    values.userToken = _userToken;
  }

  const url = new URL(path, `${process.env.GATSBY_SITE_URL}`);

  const response = await fetch(url.toString(), {
    body: JSON.stringify(values),
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
    },
    method: "POST",
  });

  setSubmitting(false);

  if (response.ok) {
    const data: any = await response.json();

    return { data: data, status: "Ok" };
  } else {
    return { data: null, status: "Failed" };
  }
};


export const deleteFromWishlist = async (
  itemId: number,
  setSubmitting: (isSubmitting: boolean) => void
): Promise<any> => {

  const _userToken = getUserToken();

  if (!_userToken) {
    return { data: null, status: "NoLogin" };
  }

  // console.log("itemId", itemId)

  const wishlistId = localStorage.getItem("wishlistId");

  if (wishlistId) {
    let path = `${functionsPath}/wishlists?action=deleteFromWishlist&wishlist_id=${wishlistId}&wishlistItemId=${itemId}`;

    const url = new URL(path, `${process.env.GATSBY_SITE_URL}`);

    const response = await fetch(url.toString(), {
      headers: {
        Accept: "application/json",
      },
      method: "DELETE",
    });

    setSubmitting(false);

    if (response.ok) {
      // const data: any = await response.json();

      // console.log(data.statusCode)

      setTimeout(() => {
        window.location.reload();
      }, 3000);


      return { data: response.status, status: "Ok" };
    } else {
      return { data: null, status: "Failed" };
    }
  } else {
    setSubmitting(false);
    return;
  }


};
