import type { Bag } from "../../types/BigCommerce";

import { functions as functionsPath } from "./paths";
import { Account, getUserToken } from "./user";
import Cookies from "universal-cookie";
import { navigate } from "gatsby";

export const getOrders = async (): Promise<any> => {
  const _userToken = getUserToken();

  if (!_userToken) {
    navigate("/account");
    return { data: null, status: "NoLogin" };
  }

  const path = `${functionsPath}/orders?action=getOrders&userToken=${_userToken}`;

  const url = new URL(path, `${process.env.GATSBY_SITE_URL}`);

  const response = await fetch(url.toString(), {
    headers: {
      Accept: "application/json",
    },
    method: "GET",
  });

  if (response.ok && response.status != 500) {
    const data: any = await response.json();

    return { data: data, status: "Ok" };
  } else {
    // console.log("Order is not valid");
    return { data: null, status: "Failed" };
  }
};

export const dummyOrderList = [
  {
    base_handling_cost: "0.0000",
    base_shipping_cost: "0.0000",
    base_wrapping_cost: "0.0000",
    billing_address: {
      city: "LA",
      company: "La Perla Beauty",
      country: "United States",
      country_iso2: "US",
      email: "pamela.reynolds@laperlab.com",
      first_name: "Pamela",
      form_fields: [],
      last_name: "Reynolds",
      phone: "+447999030963",
      state: "California",
      street_1: "5th Floor, 39 St. James Street​",
      street_2: "",
      zip: "90210",
    },
    cart_id: "5885f2a0-e891-465d-a91a-7360ab4558bd",
    channel_id: 1,
    coupon_discount: "0.0000",
    coupons: {
      resource: "/orders/2000071/coupons",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000071/coupons",
    },
    credit_card_type: "visa",
    currency_code: "USD",
    currency_exchange_rate: "1.0000000000",
    currency_id: 2,
    custom_status: "Awaiting Fulfillment",
    customer_id: 10,
    customer_locale: "en",
    customer_message: "",
    date_created: "Wed, 29 Sep 2021 13:30:59 +0000",
    date_modified: "Wed, 29 Sep 2021 13:31:00 +0000",
    date_shipped: "",
    default_currency_code: "USD",
    default_currency_id: 2,
    discount_amount: "0.0000",
    ebay_order_id: "0",
    external_id: null,
    external_merchant_id: null,
    external_source: null,
    geoip_country: "United Kingdom",
    geoip_country_iso2: "GB",
    gift_certificate_amount: "0.0000",
    handling_cost_ex_tax: "0.0000",
    handling_cost_inc_tax: "0.0000",
    handling_cost_tax: "0.0000",
    handling_cost_tax_class_id: 2,
    id: 2000071,
    ip_address: "90.243.23.164",
    ip_address_v6: "",
    is_deleted: false,
    is_email_opt_in: false,
    items_shipped: 0,
    items_total: 6,
    order_is_digital: false,
    order_source: "manual",
    payment_method: "Test Payment Provider",
    payment_provider_id: "2000071",
    payment_status: "paid",
    products: {
      resource: "/orders/2000071/products",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000071/products",
    },
    refunded_amount: "0.0000",
    shipping_address_count: 1,
    shipping_addresses: {
      resource: "/orders/2000071/shipping_addresses",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000071/shipping_addresses",
    },
    shipping_cost_ex_tax: "0.0000",
    shipping_cost_inc_tax: "0.0000",
    shipping_cost_tax: "0.0000",
    shipping_cost_tax_class_id: 2,
    staff_notes: "",
    status: "Awaiting Fulfillment",
    status_id: 11,
    store_credit_amount: "0.0000",
    store_default_currency_code: "USD",
    store_default_to_transactional_exchange_rate: "1.0000000000",
    subtotal_ex_tax: "1700.0000",
    subtotal_inc_tax: "1700.0000",
    subtotal_tax: "0.0000",
    tax_provider_id: "AvaTaxProvider",
    total_ex_tax: "1700.0000",
    total_inc_tax: "1700.0000",
    total_tax: "0.0000",
    wrapping_cost_ex_tax: "0.0000",
    wrapping_cost_inc_tax: "0.0000",
    wrapping_cost_tax: "0.0000",
    wrapping_cost_tax_class_id: 3,
  },
  {
    base_handling_cost: "0.0000",
    base_shipping_cost: "0.0000",
    base_wrapping_cost: "0.0000",
    billing_address: {
      city: "LA",
      company: "La Perla Beauty",
      country: "United States",
      country_iso2: "US",
      email: "pamela.reynolds@laperlab.com",
      first_name: "Pamela",
      form_fields: [],
      last_name: "Reynolds",
      phone: "+447999030963",
      state: "California",
      street_1: "5th Floor, 39 St. James Street​",
      street_2: "",
      zip: "90210",
    },
    cart_id: "062f2549-6928-4b60-b0f6-a6b3de291c2a",
    channel_id: 1,
    coupon_discount: "0.0000",
    coupons: {
      resource: "/orders/2000072/coupons",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000072/coupons",
    },
    credit_card_type: "visa",
    currency_code: "USD",
    currency_exchange_rate: "1.0000000000",
    currency_id: 2,
    custom_status: "Awaiting Fulfillment",
    customer_id: 10,
    customer_locale: "en",
    customer_message: "",
    date_created: "Wed, 29 Sep 2021 13:32:48 +0000",
    date_modified: "Wed, 29 Sep 2021 13:32:49 +0000",
    date_shipped: "",
    default_currency_code: "USD",
    default_currency_id: 2,
    discount_amount: "0.0000",
    ebay_order_id: "0",
    external_id: null,
    external_merchant_id: null,
    external_source: null,
    geoip_country: "United Kingdom",
    geoip_country_iso2: "GB",
    gift_certificate_amount: "0.0000",
    handling_cost_ex_tax: "0.0000",
    handling_cost_inc_tax: "0.0000",
    handling_cost_tax: "0.0000",
    handling_cost_tax_class_id: 2,
    id: 2000072,
    ip_address: "90.243.23.164",
    ip_address_v6: "",
    is_deleted: false,
    is_email_opt_in: false,
    items_shipped: 0,
    items_total: 3,
    order_is_digital: false,
    order_source: "manual",
    payment_method: "Test Payment Provider",
    payment_provider_id: "2000072",
    payment_status: "paid",
    products: {
      resource: "/orders/2000072/products",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000072/products",
    },
    refunded_amount: "0.0000",
    shipping_address_count: 1,
    shipping_addresses: {
      resource: "/orders/2000072/shipping_addresses",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000072/shipping_addresses",
    },
    shipping_cost_ex_tax: "0.0000",
    shipping_cost_inc_tax: "0.0000",
    shipping_cost_tax: "0.0000",
    shipping_cost_tax_class_id: 2,
    staff_notes: "",
    status: "Awaiting Fulfillment",
    status_id: 11,
    store_credit_amount: "0.0000",
    store_default_currency_code: "USD",
    store_default_to_transactional_exchange_rate: "1.0000000000",
    subtotal_ex_tax: "70.0000",
    subtotal_inc_tax: "70.0000",
    subtotal_tax: "0.0000",
    tax_provider_id: "AvaTaxProvider",
    total_ex_tax: "70.0000",
    total_inc_tax: "70.0000",
    total_tax: "0.0000",
    wrapping_cost_ex_tax: "0.0000",
    wrapping_cost_inc_tax: "0.0000",
    wrapping_cost_tax: "0.0000",
    wrapping_cost_tax_class_id: 3,
  },
  {
    base_handling_cost: "0.0000",
    base_shipping_cost: "5.0000",
    base_wrapping_cost: "0.0000",
    billing_address: {
      city: "LA",
      company: "La Perla Beauty",
      country: "United States",
      country_iso2: "US",
      email: "pamela.reynolds@laperlab.com",
      first_name: "Pamela",
      form_fields: [],
      last_name: "Reynolds",
      phone: "+447999030963",
      state: "California",
      street_1: "5th Floor, 39 St. James Street​",
      street_2: "",
      zip: "90210",
    },
    cart_id: "366d5f9b-108f-4f10-a54b-ad361e8e21ee",
    channel_id: 1,
    coupon_discount: "0.0000",
    coupons: {
      resource: "/orders/2000073/coupons",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000073/coupons",
    },
    credit_card_type: "visa",
    currency_code: "USD",
    currency_exchange_rate: "1.0000000000",
    currency_id: 2,
    custom_status: "Awaiting Fulfillment",
    customer_id: 10,
    customer_locale: "en",
    customer_message: "",
    date_created: "Wed, 29 Sep 2021 13:48:50 +0000",
    date_modified: "Wed, 29 Sep 2021 13:48:54 +0000",
    date_shipped: "",
    default_currency_code: "USD",
    default_currency_id: 2,
    discount_amount: "0.0000",
    ebay_order_id: "0",
    external_id: null,
    external_merchant_id: null,
    external_source: "",
    geoip_country: "United Kingdom",
    geoip_country_iso2: "GB",
    gift_certificate_amount: "0.0000",
    handling_cost_ex_tax: "0.0000",
    handling_cost_inc_tax: "0.0000",
    handling_cost_tax: "0.0000",
    handling_cost_tax_class_id: 2,
    id: 2000073,
    ip_address: "90.243.23.164",
    ip_address_v6: "",
    is_deleted: false,
    is_email_opt_in: false,
    items_shipped: 0,
    items_total: 3,
    order_is_digital: false,
    order_source: "www",
    payment_method: "Test Payment Provider",
    payment_provider_id: "",
    payment_status: "captured",
    products: {
      resource: "/orders/2000073/products",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000073/products",
    },
    refunded_amount: "0.0000",
    shipping_address_count: 1,
    shipping_addresses: {
      resource: "/orders/2000073/shipping_addresses",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000073/shipping_addresses",
    },
    shipping_cost_ex_tax: "5.0000",
    shipping_cost_inc_tax: "5.0000",
    shipping_cost_tax: "0.0000",
    shipping_cost_tax_class_id: 2,
    staff_notes: "",
    status: "Awaiting Fulfillment",
    status_id: 11,
    store_credit_amount: "0.0000",
    store_default_currency_code: "USD",
    store_default_to_transactional_exchange_rate: "1.0000000000",
    subtotal_ex_tax: "70.0000",
    subtotal_inc_tax: "70.0000",
    subtotal_tax: "0.0000",
    tax_provider_id: "AvaTaxProvider",
    total_ex_tax: "75.0000",
    total_inc_tax: "75.0000",
    total_tax: "0.0000",
    wrapping_cost_ex_tax: "0.0000",
    wrapping_cost_inc_tax: "0.0000",
    wrapping_cost_tax: "0.0000",
    wrapping_cost_tax_class_id: 3,
  },
  {
    base_handling_cost: "0.0000",
    base_shipping_cost: "5.0000",
    base_wrapping_cost: "0.0000",
    billing_address: {
      city: "LA",
      company: "La Perla Beauty",
      country: "United States",
      country_iso2: "US",
      email: "pamela.reynolds@laperlab.com",
      first_name: "Pamela",
      form_fields: [],
      last_name: "Reynolds",
      phone: "+447999030963",
      state: "California",
      street_1: "5th Floor, 39 St. James Street​",
      street_2: "",
      zip: "90210",
    },
    cart_id: "9434e161-ffba-4fdd-b198-94a2c5b1eaf6",
    channel_id: 1,
    coupon_discount: "0.0000",
    coupons: {
      resource: "/orders/2000074/coupons",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000074/coupons",
    },
    credit_card_type: null,
    currency_code: "USD",
    currency_exchange_rate: "1.0000000000",
    currency_id: 2,
    custom_status: "Awaiting Payment",
    customer_id: 10,
    customer_locale: "en",
    customer_message: "",
    date_created: "Wed, 29 Sep 2021 14:08:45 +0000",
    date_modified: "Wed, 29 Sep 2021 14:08:49 +0000",
    date_shipped: "",
    default_currency_code: "USD",
    default_currency_id: 2,
    discount_amount: "0.0000",
    ebay_order_id: "0",
    external_id: null,
    external_merchant_id: null,
    external_source: "",
    geoip_country: "United Kingdom",
    geoip_country_iso2: "GB",
    gift_certificate_amount: "0.0000",
    handling_cost_ex_tax: "0.0000",
    handling_cost_inc_tax: "0.0000",
    handling_cost_tax: "0.0000",
    handling_cost_tax_class_id: 2,
    id: 2000074,
    ip_address: "90.243.23.164",
    ip_address_v6: "",
    is_deleted: false,
    is_email_opt_in: false,
    items_shipped: 0,
    items_total: 3,
    order_is_digital: false,
    order_source: "www",
    payment_method: "Stripe (Credit Card)",
    payment_provider_id: "pi_3Jf3SmJRKWLZuL6n1O8NW7S6",
    payment_status: "authorized",
    products: {
      resource: "/orders/2000074/products",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000074/products",
    },
    refunded_amount: "0.0000",
    shipping_address_count: 1,
    shipping_addresses: {
      resource: "/orders/2000074/shipping_addresses",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000074/shipping_addresses",
    },
    shipping_cost_ex_tax: "5.0000",
    shipping_cost_inc_tax: "5.0000",
    shipping_cost_tax: "0.0000",
    shipping_cost_tax_class_id: 2,
    staff_notes: "",
    status: "Awaiting Payment",
    status_id: 7,
    store_credit_amount: "0.0000",
    store_default_currency_code: "USD",
    store_default_to_transactional_exchange_rate: "1.0000000000",
    subtotal_ex_tax: "70.0000",
    subtotal_inc_tax: "70.0000",
    subtotal_tax: "0.0000",
    tax_provider_id: "AvaTaxProvider",
    total_ex_tax: "75.0000",
    total_inc_tax: "75.0000",
    total_tax: "0.0000",
    wrapping_cost_ex_tax: "0.0000",
    wrapping_cost_inc_tax: "0.0000",
    wrapping_cost_tax: "0.0000",
    wrapping_cost_tax_class_id: 3,
  },
  {
    base_handling_cost: "0.0000",
    base_shipping_cost: "5.0000",
    base_wrapping_cost: "0.0000",
    billing_address: {
      city: "LA",
      company: "La Perla Beauty",
      country: "United States",
      country_iso2: "US",
      email: "pamela.reynolds@laperlab.com",
      first_name: "Pamela",
      form_fields: [],
      last_name: "Reynolds",
      phone: "+447999030963",
      state: "California",
      street_1: "5th Floor, 39 St. James Street​",
      street_2: "",
      zip: "90210",
    },
    cart_id: "74ab2351-11a7-4a83-9a88-c4d8cede79af",
    channel_id: 1,
    coupon_discount: "0.0000",
    coupons: {
      resource: "/orders/2000075/coupons",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000075/coupons",
    },
    credit_card_type: null,
    currency_code: "USD",
    currency_exchange_rate: "1.0000000000",
    currency_id: 2,
    custom_status: "Shipped",
    customer_id: 10,
    customer_locale: "en",
    customer_message: "",
    date_created: "Thu, 30 Sep 2021 10:06:32 +0000",
    date_modified: "Thu, 30 Sep 2021 16:45:10 +0000",
    date_shipped: "",
    default_currency_code: "USD",
    default_currency_id: 2,
    discount_amount: "0.0000",
    ebay_order_id: "0",
    external_id: null,
    external_merchant_id: null,
    external_source: "",
    geoip_country: "United Kingdom",
    geoip_country_iso2: "GB",
    gift_certificate_amount: "0.0000",
    handling_cost_ex_tax: "0.0000",
    handling_cost_inc_tax: "0.0000",
    handling_cost_tax: "0.0000",
    handling_cost_tax_class_id: 2,
    id: 2000075,
    ip_address: "90.243.23.164",
    ip_address_v6: "",
    is_deleted: true,
    is_email_opt_in: false,
    items_shipped: 2,
    items_total: 2,
    order_is_digital: false,
    order_source: "www",
    payment_method: "Stripe (Credit Card)",
    payment_provider_id: "",
    payment_status: "declined",
    products: {
      resource: "/orders/2000075/products",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000075/products",
    },
    refunded_amount: "0.0000",
    shipping_address_count: 1,
    shipping_addresses: {
      resource: "/orders/2000075/shipping_addresses",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000075/shipping_addresses",
    },
    shipping_cost_ex_tax: "5.0000",
    shipping_cost_inc_tax: "5.0000",
    shipping_cost_tax: "0.0000",
    shipping_cost_tax_class_id: 2,
    staff_notes: "",
    status: "Shipped",
    status_id: 2,
    store_credit_amount: "0.0000",
    store_default_currency_code: "USD",
    store_default_to_transactional_exchange_rate: "1.0000000000",
    subtotal_ex_tax: "108.0000",
    subtotal_inc_tax: "108.0000",
    subtotal_tax: "0.0000",
    tax_provider_id: "AvaTaxProvider",
    total_ex_tax: "113.0000",
    total_inc_tax: "113.0000",
    total_tax: "0.0000",
    wrapping_cost_ex_tax: "0.0000",
    wrapping_cost_inc_tax: "0.0000",
    wrapping_cost_tax: "0.0000",
    wrapping_cost_tax_class_id: 3,
  },
  {
    base_handling_cost: "0.0000",
    base_shipping_cost: "5.0000",
    base_wrapping_cost: "0.0000",
    billing_address: {
      city: "LA",
      company: "La Perla Beauty",
      country: "United States",
      country_iso2: "US",
      email: "pamela.reynolds@laperlab.com",
      first_name: "Pamela",
      form_fields: [],
      last_name: "Reynolds",
      phone: "+447999030963",
      state: "California",
      street_1: "5th Floor, 39 St. James Street​",
      street_2: "",
      zip: "90210",
    },
    cart_id: "74ab2351-11a7-4a83-9a88-c4d8cede79af",
    channel_id: 1,
    coupon_discount: "0.0000",
    coupons: {
      resource: "/orders/2000076/coupons",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000076/coupons",
    },
    credit_card_type: null,
    currency_code: "USD",
    currency_exchange_rate: "1.0000000000",
    currency_id: 2,
    custom_status: "Shipped",
    customer_id: 10,
    customer_locale: "en",
    customer_message: "",
    date_created: "Thu, 30 Sep 2021 10:12:16 +0000",
    date_modified: "Thu, 30 Sep 2021 16:05:22 +0000",
    date_shipped: "Thu, 30 Sep 2021 16:05:22 +0000",
    default_currency_code: "USD",
    default_currency_id: 2,
    discount_amount: "0.0000",
    ebay_order_id: "0",
    external_id: null,
    external_merchant_id: null,
    external_source: "",
    geoip_country: "United Kingdom",
    geoip_country_iso2: "GB",
    gift_certificate_amount: "0.0000",
    handling_cost_ex_tax: "0.0000",
    handling_cost_inc_tax: "0.0000",
    handling_cost_tax: "0.0000",
    handling_cost_tax_class_id: 2,
    id: 2000076,
    ip_address: "90.243.23.164",
    ip_address_v6: "",
    is_deleted: false,
    is_email_opt_in: false,
    items_shipped: 2,
    items_total: 2,
    order_is_digital: false,
    order_source: "www",
    payment_method: "Stripe (Credit Card)",
    payment_provider_id: "pi_3JfMFSJRKWLZuL6n1V0CVOXP",
    payment_status: "authorized",
    products: {
      resource: "/orders/2000076/products",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000076/products",
    },
    refunded_amount: "0.0000",
    shipping_address_count: 1,
    shipping_addresses: {
      resource: "/orders/2000076/shipping_addresses",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000076/shipping_addresses",
    },
    shipping_cost_ex_tax: "5.0000",
    shipping_cost_inc_tax: "5.0000",
    shipping_cost_tax: "0.0000",
    shipping_cost_tax_class_id: 2,
    staff_notes: "",
    status: "Shipped",
    status_id: 2,
    store_credit_amount: "0.0000",
    store_default_currency_code: "USD",
    store_default_to_transactional_exchange_rate: "1.0000000000",
    subtotal_ex_tax: "108.0000",
    subtotal_inc_tax: "108.0000",
    subtotal_tax: "0.0000",
    tax_provider_id: "AvaTaxProvider",
    total_ex_tax: "113.0000",
    total_inc_tax: "113.0000",
    total_tax: "0.0000",
    wrapping_cost_ex_tax: "0.0000",
    wrapping_cost_inc_tax: "0.0000",
    wrapping_cost_tax: "0.0000",
    wrapping_cost_tax_class_id: 3,
  },
  {
    base_handling_cost: "0.0000",
    base_shipping_cost: "5.0000",
    base_wrapping_cost: "0.0000",
    billing_address: {
      city: "LA",
      company: "La Perla Beauty",
      country: "United States",
      country_iso2: "US",
      email: "pamela.reynolds@laperlab.com",
      first_name: "Pamela",
      form_fields: [],
      last_name: "Reynolds",
      phone: "+447999030963",
      state: "California",
      street_1: "5th Floor, 39 St. James Street​",
      street_2: "",
      zip: "90210",
    },
    cart_id: "d7834ccf-c07a-40cd-8c59-94d0c06f5ff8",
    channel_id: 1,
    coupon_discount: "0.0000",
    coupons: {
      resource: "/orders/2000077/coupons",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000077/coupons",
    },
    credit_card_type: null,
    currency_code: "USD",
    currency_exchange_rate: "1.0000000000",
    currency_id: 2,
    custom_status: "Awaiting Payment",
    customer_id: 10,
    customer_locale: "en",
    customer_message: "",
    date_created: "Thu, 30 Sep 2021 10:26:47 +0000",
    date_modified: "Thu, 30 Sep 2021 10:26:51 +0000",
    date_shipped: "",
    default_currency_code: "USD",
    default_currency_id: 2,
    discount_amount: "0.0000",
    ebay_order_id: "0",
    external_id: null,
    external_merchant_id: null,
    external_source: "",
    geoip_country: "United Kingdom",
    geoip_country_iso2: "GB",
    gift_certificate_amount: "0.0000",
    handling_cost_ex_tax: "0.0000",
    handling_cost_inc_tax: "0.0000",
    handling_cost_tax: "0.0000",
    handling_cost_tax_class_id: 2,
    id: 2000077,
    ip_address: "90.243.23.164",
    ip_address_v6: "",
    is_deleted: false,
    is_email_opt_in: false,
    items_shipped: 0,
    items_total: 3,
    order_is_digital: false,
    order_source: "www",
    payment_method: "Stripe (Credit Card)",
    payment_provider_id: "pi_3JfMTWJRKWLZuL6n1nrOPnuF",
    payment_status: "authorized",
    products: {
      resource: "/orders/2000077/products",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000077/products",
    },
    refunded_amount: "0.0000",
    shipping_address_count: 1,
    shipping_addresses: {
      resource: "/orders/2000077/shipping_addresses",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000077/shipping_addresses",
    },
    shipping_cost_ex_tax: "5.0000",
    shipping_cost_inc_tax: "5.0000",
    shipping_cost_tax: "0.0000",
    shipping_cost_tax_class_id: 2,
    staff_notes: "",
    status: "Awaiting Payment",
    status_id: 7,
    store_credit_amount: "0.0000",
    store_default_currency_code: "USD",
    store_default_to_transactional_exchange_rate: "1.0000000000",
    subtotal_ex_tax: "340.0000",
    subtotal_inc_tax: "340.0000",
    subtotal_tax: "0.0000",
    tax_provider_id: "AvaTaxProvider",
    total_ex_tax: "345.0000",
    total_inc_tax: "345.0000",
    total_tax: "0.0000",
    wrapping_cost_ex_tax: "0.0000",
    wrapping_cost_inc_tax: "0.0000",
    wrapping_cost_tax: "0.0000",
    wrapping_cost_tax_class_id: 3,
  },
  {
    base_handling_cost: "0.0000",
    base_shipping_cost: "5.0000",
    base_wrapping_cost: "0.0000",
    billing_address: {
      city: "LA",
      company: "La Perla Beauty",
      country: "United States",
      country_iso2: "US",
      email: "pamela.reynolds@laperlab.com",
      first_name: "Pamela",
      form_fields: [],
      last_name: "Reynolds",
      phone: "+447999030963",
      state: "California",
      street_1: "5th Floor, 39 St. James Street​",
      street_2: "",
      zip: "90210",
    },
    cart_id: "5206171f-7ec8-4a72-90fb-fcb59d6c9728",
    channel_id: 1,
    coupon_discount: "0.0000",
    coupons: {
      resource: "/orders/2000078/coupons",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000078/coupons",
    },
    credit_card_type: null,
    currency_code: "USD",
    currency_exchange_rate: "1.0000000000",
    currency_id: 2,
    custom_status: "Incomplete",
    customer_id: 10,
    customer_locale: "en",
    customer_message: "",
    date_created: "Thu, 30 Sep 2021 10:28:47 +0000",
    date_modified: "Thu, 30 Sep 2021 10:29:46 +0000",
    date_shipped: "",
    default_currency_code: "USD",
    default_currency_id: 2,
    discount_amount: "0.0000",
    ebay_order_id: "0",
    external_id: null,
    external_merchant_id: null,
    external_source: "",
    geoip_country: "United Kingdom",
    geoip_country_iso2: "GB",
    gift_certificate_amount: "0.0000",
    handling_cost_ex_tax: "0.0000",
    handling_cost_inc_tax: "0.0000",
    handling_cost_tax: "0.0000",
    handling_cost_tax_class_id: 2,
    id: 2000078,
    ip_address: "90.243.23.164",
    ip_address_v6: "",
    is_deleted: true,
    is_email_opt_in: false,
    items_shipped: 0,
    items_total: 2,
    order_is_digital: false,
    order_source: "www",
    payment_method: "Stripe (Credit Card)",
    payment_provider_id: "",
    payment_status: "declined",
    products: {
      resource: "/orders/2000078/products",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000078/products",
    },
    refunded_amount: "0.0000",
    shipping_address_count: 1,
    shipping_addresses: {
      resource: "/orders/2000078/shipping_addresses",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000078/shipping_addresses",
    },
    shipping_cost_ex_tax: "5.0000",
    shipping_cost_inc_tax: "5.0000",
    shipping_cost_tax: "0.0000",
    shipping_cost_tax_class_id: 2,
    staff_notes: "",
    status: "Incomplete",
    status_id: 0,
    store_credit_amount: "0.0000",
    store_default_currency_code: "USD",
    store_default_to_transactional_exchange_rate: "1.0000000000",
    subtotal_ex_tax: "150.0000",
    subtotal_inc_tax: "150.0000",
    subtotal_tax: "0.0000",
    tax_provider_id: "AvaTaxProvider",
    total_ex_tax: "155.0000",
    total_inc_tax: "155.0000",
    total_tax: "0.0000",
    wrapping_cost_ex_tax: "0.0000",
    wrapping_cost_inc_tax: "0.0000",
    wrapping_cost_tax: "0.0000",
    wrapping_cost_tax_class_id: 3,
  },
  {
    base_handling_cost: "0.0000",
    base_shipping_cost: "5.0000",
    base_wrapping_cost: "0.0000",
    billing_address: {
      city: "LA",
      company: "La Perla Beauty",
      country: "United States",
      country_iso2: "US",
      email: "pamela.reynolds@laperlab.com",
      first_name: "Pamela",
      form_fields: [],
      last_name: "Reynolds",
      phone: "+447999030963",
      state: "California",
      street_1: "5th Floor, 39 St. James Street​",
      street_2: "",
      zip: "90210",
    },
    cart_id: "5206171f-7ec8-4a72-90fb-fcb59d6c9728",
    channel_id: 1,
    coupon_discount: "0.0000",
    coupons: {
      resource: "/orders/2000079/coupons",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000079/coupons",
    },
    credit_card_type: null,
    currency_code: "USD",
    currency_exchange_rate: "1.0000000000",
    currency_id: 2,
    custom_status: "Awaiting Payment",
    customer_id: 10,
    customer_locale: "en",
    customer_message: "",
    date_created: "Thu, 30 Sep 2021 10:29:46 +0000",
    date_modified: "Thu, 30 Sep 2021 10:29:49 +0000",
    date_shipped: "",
    default_currency_code: "USD",
    default_currency_id: 2,
    discount_amount: "0.0000",
    ebay_order_id: "0",
    external_id: null,
    external_merchant_id: null,
    external_source: "",
    geoip_country: "United Kingdom",
    geoip_country_iso2: "GB",
    gift_certificate_amount: "0.0000",
    handling_cost_ex_tax: "0.0000",
    handling_cost_inc_tax: "0.0000",
    handling_cost_tax: "0.0000",
    handling_cost_tax_class_id: 2,
    id: 2000079,
    ip_address: "90.243.23.164",
    ip_address_v6: "",
    is_deleted: false,
    is_email_opt_in: false,
    items_shipped: 0,
    items_total: 2,
    order_is_digital: false,
    order_source: "www",
    payment_method: "Stripe (Credit Card)",
    payment_provider_id: "pi_3JfMWOJRKWLZuL6n04K6pN4j",
    payment_status: "authorized",
    products: {
      resource: "/orders/2000079/products",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000079/products",
    },
    refunded_amount: "0.0000",
    shipping_address_count: 1,
    shipping_addresses: {
      resource: "/orders/2000079/shipping_addresses",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000079/shipping_addresses",
    },
    shipping_cost_ex_tax: "5.0000",
    shipping_cost_inc_tax: "5.0000",
    shipping_cost_tax: "0.0000",
    shipping_cost_tax_class_id: 2,
    staff_notes: "",
    status: "Awaiting Payment",
    status_id: 7,
    store_credit_amount: "0.0000",
    store_default_currency_code: "USD",
    store_default_to_transactional_exchange_rate: "1.0000000000",
    subtotal_ex_tax: "150.0000",
    subtotal_inc_tax: "150.0000",
    subtotal_tax: "0.0000",
    tax_provider_id: "AvaTaxProvider",
    total_ex_tax: "155.0000",
    total_inc_tax: "155.0000",
    total_tax: "0.0000",
    wrapping_cost_ex_tax: "0.0000",
    wrapping_cost_inc_tax: "0.0000",
    wrapping_cost_tax: "0.0000",
    wrapping_cost_tax_class_id: 3,
  },
  {
    base_handling_cost: "0.0000",
    base_shipping_cost: "5.0000",
    base_wrapping_cost: "0.0000",
    billing_address: {
      city: "LA",
      company: "La Perla Beauty",
      country: "United States",
      country_iso2: "US",
      email: "pamela.reynolds@laperlab.com",
      first_name: "Pamela",
      form_fields: [],
      last_name: "Reynolds",
      phone: "+447999030963",
      state: "California",
      street_1: "5th Floor, 39 St. James Street​",
      street_2: "",
      zip: "90210",
    },
    cart_id: "fd490879-f482-4941-9199-1d36039ed397",
    channel_id: 1,
    coupon_discount: "0.0000",
    coupons: {
      resource: "/orders/2000080/coupons",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000080/coupons",
    },
    credit_card_type: null,
    currency_code: "USD",
    currency_exchange_rate: "1.0000000000",
    currency_id: 2,
    custom_status: "Awaiting Payment",
    customer_id: 10,
    customer_locale: "en",
    customer_message: "",
    date_created: "Thu, 30 Sep 2021 13:23:36 +0000",
    date_modified: "Thu, 30 Sep 2021 13:23:40 +0000",
    date_shipped: "",
    default_currency_code: "USD",
    default_currency_id: 2,
    discount_amount: "0.0000",
    ebay_order_id: "0",
    external_id: null,
    external_merchant_id: null,
    external_source: "",
    geoip_country: "United Kingdom",
    geoip_country_iso2: "GB",
    gift_certificate_amount: "0.0000",
    handling_cost_ex_tax: "0.0000",
    handling_cost_inc_tax: "0.0000",
    handling_cost_tax: "0.0000",
    handling_cost_tax_class_id: 2,
    id: 2000080,
    ip_address: "90.243.23.164",
    ip_address_v6: "",
    is_deleted: false,
    is_email_opt_in: false,
    items_shipped: 0,
    items_total: 3,
    order_is_digital: false,
    order_source: "www",
    payment_method: "Stripe (Credit Card)",
    payment_provider_id: "pi_3JfPEdJRKWLZuL6n1TeeveYx",
    payment_status: "authorized",
    products: {
      resource: "/orders/2000080/products",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000080/products",
    },
    refunded_amount: "0.0000",
    shipping_address_count: 1,
    shipping_addresses: {
      resource: "/orders/2000080/shipping_addresses",
      url:
        "https://api.bigcommerce.com/stores/fj9kxa9upa/v2/orders/2000080/shipping_addresses",
    },
    shipping_cost_ex_tax: "5.0000",
    shipping_cost_inc_tax: "5.0000",
    shipping_cost_tax: "0.0000",
    shipping_cost_tax_class_id: 2,
    staff_notes: "",
    status: "Awaiting Payment",
    status_id: 7,
    store_credit_amount: "0.0000",
    store_default_currency_code: "USD",
    store_default_to_transactional_exchange_rate: "1.0000000000",
    subtotal_ex_tax: "70.0000",
    subtotal_inc_tax: "70.0000",
    subtotal_tax: "0.0000",
    tax_provider_id: "AvaTaxProvider",
    total_ex_tax: "75.0000",
    total_inc_tax: "75.0000",
    total_tax: "0.0000",
    wrapping_cost_ex_tax: "0.0000",
    wrapping_cost_inc_tax: "0.0000",
    wrapping_cost_tax: "0.0000",
    wrapping_cost_tax_class_id: 3,
  },
];
