export const bag = "/bag"
export const category = "/category"
export const home = "/"
export const myAccount = "/account"
export const product = "/product"

export const fragranceFinder = "/fragrance-finder"

export const register = "/register"
export const search = "/search"
export const signIn = "/sign-in"

export const signUp = "/sign-up"

export const functions = "/.netlify/functions"

export const functionCarts = "/.netlify/functions/carts"

export const functionSignIn = "/.netlify/functions/sign-in"

export const functionSignUp = "/.netlify/functions/sign-up"

export const functionSignUpToNewsletter = "/.netlify/functions/sign-up-to-newsletter"

export const functionOrders = "/.netlify/functions/orders"
