import { mediaQueries, theme } from "../theme"

export const body = `
    body {
        scroll-behavior: smooth;
        --font-size-body: ${theme.fontSizes.bodyMobile}px;
        --font-size-heading-1: ${theme.fontSizes.heading1Mobile}px;
        --font-size-heading-2: ${theme.fontSizes.heading2Mobile}px;
        --font-size-heading-3: ${theme.fontSizes.heading3Mobile}px;
        --font-size-heading-4: ${theme.fontSizes.heading4Mobile}px;
        --font-size-heading-5: ${theme.fontSizes.heading5Mobile}px;
        --font-size-heading-6: ${theme.fontSizes.heading6Mobile}px;

        --font-size-sm: ${theme.fontSizes.smallMobile}px;
        --font-size-md: ${theme.fontSizes.mediumMobile}px;
        --font-size-lg: ${theme.fontSizes[5]}px;
        --font-size-xl: ${theme.fontSizes[6]}px;

        --header-min-height: 38px;

        color: ${theme.colors.text};
        font-size: var(--font-size-body, 13px);
        margin: 0;
    
        ${mediaQueries.sm} {
            --font-size-body: ${theme.fontSizes.bodyDesktop}px;
            --font-size-heading-1: ${theme.fontSizes.heading1Desktop}px;
            --font-size-heading-2: ${theme.fontSizes.heading2Desktop}px;
            --font-size-heading-3: ${theme.fontSizes.heading3Desktop}px;
            --font-size-heading-4: ${theme.fontSizes.heading4Desktop}px;
            --font-size-heading-5: ${theme.fontSizes.heading5Desktop}px;
            --font-size-heading-6: ${theme.fontSizes.heading6Desktop}px;
            
            --font-size-sm: ${theme.fontSizes.smallDesktop}px;
            --font-size-md: ${theme.fontSizes.mediumDesktop}px;
            --font-size-lg: ${theme.fontSizes[6]}px;
            --font-size-xl: ${theme.fontSizes[7]}px;
        }

        ${mediaQueries.md} {
         
            --header-min-height: 124px;
        }
    }
`
