import type { Bag } from "../../types/BigCommerce";

import { functions as functionsPath } from "./paths";
import { Account, getUserToken } from "./user";
import Cookies from "universal-cookie";
import { navigate } from "gatsby";

export interface AddressBook {
  first_name: string;
  last_name: string;
  company: string;
  street_1: string;
  street_2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
  address_type: string;
}

const endpoint = "/address-book";

export const getAllAddressByCustomerId = async (): Promise<any> => {
  const _userToken = getUserToken();

  if (!_userToken) {
    navigate("/account");
    return { data: null, status: "NoLogin" };
  }

  const path = `${functionsPath}${endpoint}?action=getAllAddress&userToken=${_userToken}`;

  const url = new URL(path, `${process.env.GATSBY_SITE_URL}`);

  const response = await fetch(url.toString(), {
    headers: {
      Accept: "application/json",
    },
    method: "GET",
  });

  if (response.ok) {
    const data: any = await response.json();

    return { data: data, status: "Ok" };
  } else {
    return { data: null, status: "Failed" };
  }
};

export const addAddressByCustId = async (
  address: AddressBook,
  setSubmitting: (isSubmitting: boolean) => void,
  setSnackbar: (labelText: string | undefined) => void
): Promise<any> => {
  const _userToken = getUserToken();

  if (!_userToken) {
    return { data: null, status: "NoLogin" };
  }

  let path = `${functionsPath}${endpoint}?action=createAddress&userToken=${_userToken}`;

  const url = new URL(path, `${process.env.GATSBY_SITE_URL}`);

  const response = await fetch(url.toString(), {
    body: JSON.stringify(address),
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
    },
    method: "POST",
  });

  setSubmitting(false);

  if (response.ok) {
    const data: any = await response.json();
    setSnackbar("New address added");
    setTimeout(() => {
      window.location.reload();
    }, 3000);
    return { data: data, status: "Ok" };
  } else {
    setSnackbar("New address can't be added.");
    return { data: null, status: "Failed" };
  }
};

export const updateAddress = async (
  addressId: number,
  address: AddressBook,
  setSnackbar: (labelText: string | undefined) => void
): Promise<any> => {
  const _userToken = getUserToken();

  if (!_userToken) {
    return { data: null, status: "NoLogin" };
  }

  if (addressId) {
    let path = `${functionsPath}${endpoint}?action=updateAddress&userToken=${_userToken}&address_id=${addressId}`;

    const url = new URL(path, `${process.env.GATSBY_SITE_URL}`);

    const response = await fetch(url.toString(), {
      body: JSON.stringify(address),
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
      },
      method: "PUT",
    });

    // setSubmitting(false);

    if (response.ok) {
      setSnackbar("Address Updated");
      const data: any = await response.json();
      return { data: data, status: "Ok" };
    } else {
      return { data: null, status: "Failed" };
    }
  } else {
    // setSubmitting(false);
    return;
  }
};

export const deleteAddress = async (
  addressId: number,
  setSnackbar: (labelText: string | undefined) => void
): Promise<any> => {
  const _userToken = getUserToken();

  if (!_userToken) {
    return { data: null, status: "NoLogin" };
  }

  if (addressId) {
    let path = `${functionsPath}${endpoint}?action=deleteAddress&userToken=${_userToken}&address_id=${addressId}`;

    const url = new URL(path, `${process.env.GATSBY_SITE_URL}`);

    const response = await fetch(url.toString(), {
      headers: {
        Accept: "application/json",
      },
      method: "DELETE",
    });

    // setSubmitting(false);

    if (response.ok) {
      setSnackbar("Address Deleted");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      return { data: response.status, status: "Ok" };
    } else {
      return { data: null, status: "Failed" };
    }
  } else {
    // setSubmitting(false);
    return;
  }
};
