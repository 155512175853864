import * as Yup from 'yup';
import { navigate } from 'gatsby';

import { functions as functionsPath } from './paths';
import { updateCartUser } from './carts';
import { dataLayerEvents, dataLayerFormErrorsEvents, customDimensionsEvents } from './gtm';

export interface Register {
  emailAddress: string;
  firstName: string;
  lastName: string;
  password: string;
}

export interface SignIn {
  emailAddress: string;
  password: string;
}

export interface Account {
  userToken: string | null;
}

export const SignInSchema = Yup.object().shape({
  emailAddress: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().min(2, 'Password is too short').required('Required')
});

export const signIn = async (values: SignIn, setAccount: (data: Account) => void, setSnackbar: (labelText: string | undefined) => void): Promise<void> => {

  const path = `${functionsPath}/sign-in`;

  const url = new URL(path, `${process.env.GATSBY_SITE_URL}`);

  const response = await fetch(url.toString(), {
    headers: {
      Accept: 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(values)
  });

  if (response.ok) {
    const data: Account = await response.json();
    setAccount(data);
    localStorage.setItem('user', JSON.stringify(data));
    await updateCartUser();

    if (window.cookieAccepted) {
      dataLayerEvents('login form');
    }

    navigate('/account/information');
  } else {
    dataLayerFormErrorsEvents(['emailAddress', 'password']);
    setSnackbar('Sorry, the username and/or password are not recognised');
  }
};


export const register = async (values: Register, setSnackbar: (labelText: string | undefined) => void): Promise<boolean> => {

  const path = `${functionsPath}/register`;

  const url = new URL(path, `${process.env.GATSBY_SITE_URL}`);

  const response = await fetch(url.toString(), {
    headers: {
      Accept: 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(values)
  });

  if (response.ok) {
    if (window.cookieAccepted) {
      dataLayerEvents('register form');
    }

    return true;
  } else {
    dataLayerFormErrorsEvents(['firstName', 'lastName', 'emailAddress', 'password']);
    if (response.status === 409) {
      setSnackbar('Sorry, that email address has already been registered');
    } else {
      setSnackbar('Sorry, there appears to be an issue. Please contact customer care.');
    }
    return false;
  }
};

export const isSignedIn = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  const user = localStorage.getItem('user');
  if (!user) {
    return false;
  }
  const userToken = (JSON.parse(user) as Account)?.userToken;
  return userToken && userToken !== '0';
};

export const signOut = async (setAccount: (data: Account) => void): Promise<void> => {
  const signedOutData = {userToken: null};
  setAccount(signedOutData);
  localStorage.setItem('user', JSON.stringify(signedOutData));
  await updateCartUser();
  customDimensionsEvents({userId: null});
  navigate('/');
};


export const getUserToken = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  const user = localStorage.getItem('user');
  if (!user) {
    return false;
  }
  const userToken = (JSON.parse(user) as Account)?.userToken;

  return userToken && userToken;
};
