import CloseQuote from "../../static/icons/CloseQuote.svg"
import OpenQuote from "../../static/icons/OpenQuote.svg"

import { mediaQueries, theme } from "../theme"

export const blockquote = `
    --blockquote-symbol-height: 37px;
    --blockquote-symbol-width: 50px;

    ${mediaQueries.md} {
        --blockquote-symbol-height: 56px;
        --blockquote-symbol-width: 78px;
    }

    blockquote {
        --background-color: ${theme.colors.lightgrey};

        font-family: "Tiempos", serif;
        font-size: var(--font-size-heading-4, 16px);
        line-height: 22px;
        letter-spacing: .2px;
        margin: unset;
        padding-block-end: 20px;
        padding-block-start: 20px;
        padding-inline-end: 45px;
        padding-inline-start: 45px;
        //max-width: ${theme.space[12]}ch;
        position: relative;
        z-index: 1;
        text-align: center;

        ${mediaQueries.sm} {
            padding-inline-end: 70px;
            padding-inline-start: 70px;
        }

        ${mediaQueries.md} {
            //padding-block-end: var(--blockquote-symbol-height, 37px);
            //padding-block-start: var(--blockquote-symbol-height, 37px);
            //padding-inline-end: calc(var(--blockquote-symbol-width, 50px) * 2);
            //padding-inline-start: calc(var(--blockquote-symbol-width, 50px) * 2);
            padding: 20px 108px;
            font-size: var(--font-size-heading-3, 18px);
            line-height: 24px;
            letter-spacing: .3px;
            text-align: left;
        }

        &::before,
        &::after {
            background-color: var(--background-color, ${theme.colors.lightgrey});
            background-repeat: no-repeat;
            background-size: contain;
            content: "";
            //height: var(--blockquote-symbol-height, 37px);
            mask-repeat: no-repeat;
            mask-size: contain;
            position: absolute;
            transform: translateX(10%);
            //width: var(--blockquote-symbol-width, 50px);
            z-index: -1;
            width: 34px;
            height: 25px;

            ${mediaQueries.sm} {
                width: 51px;
                height: 37px;
            }

            ${mediaQueries.md} {
                width: 78px;
                height: 56px;
            }
        }

        &::before {
            left: 0;
            mask-image: url(${OpenQuote});
            mask-position: top;
            top: 12%;

            ${mediaQueries.md} {
                top: 15%;
            }
        }

        &::after {
            bottom: 12%;
            mask-image: url(${CloseQuote});
            mask-position: bottom;
            right: 5px;

            ${mediaQueries.md} {
                bottom: 15%;
            }
        }
    }
`
