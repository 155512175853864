import type { Offer, Product } from "schema-dts";

import { themeGet } from "@styled-system/theme-get";
import clsx from "clsx";
import React, { useContext } from "react";
import styled from "styled-components";
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system";

import { ReactComponent as CloseIcon } from "../../static/icons/Close.svg";
import { ReactComponent as MinusIcon } from "../../static/icons/Minus.svg";
import { ReactComponent as PlusIcon } from "../../static/icons/Plus.svg";

import { mediaQueries } from "../theme";
import { deleteCartItem, updateCartItem } from "../utils/carts";
import { BagContext } from "./Bag";
import { SnackbarContext } from "./Snackbar";
import { ImgOptimized } from "./ImgOptimized";
import { gtmRemoveAction } from "../utils/gtm"

const BagProductStyled = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: ${(props) =>
    props.layout === "compact" ? "auto 1fr" : "1fr 1fr"};

  ${mediaQueries.md} {
    gap: 1.5rem;
    grid-template-columns: ${(props) =>
      props.layout === "compact" ? "auto 1fr" : "1fr 2fr"};
  }

  .image {
    border-radius: ${themeGet("radii.3")}px;
    height: 160px;
    width: 160px;
    object-fit: cover;

    ${mediaQueries.md} {
      height: ${(props) => (props.layout === "compact" ? "160px" : "200px")};
    }
  }

  .variants {
    display: grid;
    grid-auto-flow: column;
  }

  .details {
    align-content: space-between;
    display: grid;

    .variant {
      text-align: left;
    }

    .title-wrapper {
      align-content: center;
      display: grid;
      gap: 12px;
      grid-auto-flow: column;
      justify-content: space-between;
      margin-block-end: 8px;
      padding-block-start: 1rem;

      ${mediaQueries.md} {
        gap: 1rem;
      }


      .title {
        font-size: ${themeGet("fontSizes.4")}px;
        margin: 0;
        text-align: start;
      }

      .close-icon {
        height: 18px;
        padding: 0;
        width: 18px;
      }
    }

    .quantity-wrapper {
      align-items: center;
      bottom: 0px;
      display: flex;
      justify-content: space-between;
      width: 100%;

      ${mediaQueries.md} {
        bottom: 30%;
      }

      .button-wrapper {
        align-items: center;
        display: grid;
        gap: 0.25rem;
        grid-auto-flow: column;

        .quantity-icon {
          border-style: solid;
          border-color: ${themeGet("colors.black")};
          height: 30px;
          padding: 0px;
          width: 30px;

          svg {
            margin: unset;
          }
        }

        .total {
          border: none;
          padding: unset;
          text-align: center;
          width: 2rem;

          ${mediaQueries.md} {
            text-align: right;
          }
        }
      }

      .price {
        font-family: "Tiempos", serif;
        font-size: ${themeGet("fontSizes.3")}px;
        text-align: end;
      }
    }
  }

  ${compose(color, flexbox, grid, layout, position, space)}
`;

export type BagProductProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps & {
    layout: "compact" | "full";
    product: Product;
    quantity: number;
  };

export const BagProduct: React.FC<BagProductProps> = (props) => {
  const { bag, setBag } = useContext(BagContext);
  const { labelText, setSnackbar } = useContext(SnackbarContext);

  const incrementAmount = async () => {
    await updateCartItem(
      {
        itemId: props.id,
        identifier: props.product_id,
        quantity: props.quantity + 1,
      },
      setBag,
      setSnackbar
    );
  };

  const decrementAmount = async () => {
    if (props.quantity > 1) {
      await updateCartItem(
        {
          itemId: props.id,
          identifier: props.product_id,
          quantity: props.quantity - 1,
        },
        setBag,
        setSnackbar
      );
    } else {
      await deleteCartItem(
        {
          itemId: props.id,
        },
        setBag,
        setSnackbar
      );
    }
  };
  const deleteItem = async () => {
    // console.log("props remove: ", props)
    gtmRemoveAction(props, 'removeFromCart', 'remove', 1);
    await deleteCartItem(
      {
        itemId: props.id,
      },
      setBag,
      setSnackbar
    );
  };

  const image = props.image_url ? (
    <ImgOptimized
      alt={props.name}
      className="image"
      itemProp="image"
      src={props.image_url}
    />
  ) : undefined;

  return (
    <BagProductStyled
      {...props}
      id={props.id}
      sku={props.sku}
      className="basket-item"
    >
      {image}
      <div className="details">
        <div>
          <div className="title-wrapper">
            <h1 className="title">{props.name}</h1>
            <button className={clsx("close-icon", "icon")} onClick={deleteItem}>
              <CloseIcon />
            </button>
          </div>
          <div className="variants">
            {"color" in props && (
              <div className={clsx("variant", "color")}>
                <span className={clsx("label", "sr-only")}>Colour</span>
                <span className="value">{props.color}</span>
              </div>
            )}
            {"size" in props && (
              <div className={clsx("variant", "size")}>
                <span className={clsx("label", "sr-only")}>Size</span>
                <span className="value">{props.size?.value}</span>
                <span className="unitText">{props.size?.unitText}</span>
              </div>
            )}
          </div>
        </div>
        <div className="quantity-wrapper">
          <div className="button-wrapper">
          <React.Fragment>
                <button
                  className={clsx("quantity-icon", "icon")}
                  onClick={decrementAmount}
                >
                  <MinusIcon />
                </button>

                <input
                  className="total"
                  name="quantity"
                  type="number"
                  value={props.quantity}
                />
                <button
                  className={clsx("quantity-icon", "icon")}
                  onClick={incrementAmount}
                >
                  <PlusIcon />
                </button>
              </React.Fragment>
            {/* {props?.isMiniBag ? (
              <React.Fragment>
                Quantity:
                <input
                  className="total"
                  name="quantity"
                  type="number"
                  value={props.quantity}
                />
              </React.Fragment>
            ) : (

            )} */}
          </div>
          <span>
            {props?.sale_price === 0
              ? "Free"
              : props?.sale_price &&
                new Intl.NumberFormat(props?.locale, {
                  style: "currency",
                  currency: props?.currency_code,
                }).format(props?.sale_price as number)}
          </span>
        </div>
      </div>
    </BagProductStyled>
  );
};
