import styled from "styled-components"
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system"

export type HoriontalRuleProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps

export const HoriontalRule: React.FC<HoriontalRuleProps> = styled.hr`
  border-width: 2px;
  ${compose(color, flexbox, grid, layout, position, space)}
`
