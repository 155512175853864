import React, { useEffect, useState } from "react";

import styled from "styled-components";

import { mediaQueries } from "../theme";

const SkeletonItemStyled = styled.div`

.skeleton {
    margin: 10px 0;
 }

.skeleton .thumbnail {
    width: 100%;
    min-height: 200px;
    background: #D2DBE2;
    border-radius: 7px;
 }
 .skeleton .text-lg {
    width: 100%;
    margin-top: 10px;
    background: #D2DBE2;
    border-radius: 7px;
    min-height: 10px;
 }
 .skeleton .text-md {
    width: 70%;
    margin-top: 10px;
    background: #D2DBE2;
    border-radius: 7px;
    min-height: 10px;
 }
 .skeleton .text-sm {
    width: 40%;
    margin-top: 10px;
    background: #D2DBE2;
    border-radius: 7px;
    min-height: 10px;
 }
`;

const SkeletonItem = ({ type }) => {
  return (
    <SkeletonItemStyled>
      <div className="skeleton">
        <div className={type}> </div>
      </div>
    </SkeletonItemStyled>
  );
};

export default SkeletonItem;
