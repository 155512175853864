import type {
  Contentstack_Pages,
  BusinessCardPageQuery,
} from "../../graphql-types";

import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system";

import { PageProps, graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { mediaQueries } from "../theme";

import { Layout } from "../components/Layout";
import { SEO } from "../components/SEO";
import { ListPlain } from "../components/ListPlain";
import { ImgOptimized } from "../components/ImgOptimized";
import { LogotypeLink } from "../components/LogotypeLink";
import LeafPlain from "../components/LeafPlain";
import { Link } from "../components/Button";

import { ReactComponent as Logotype } from "../../static/icons/Logotype.svg";
import { ReactComponent as Telephone } from "../../static/icons/telephone.svg";
import { ReactComponent as Envelope } from "../../static/icons/envelope.svg";
import { ReactComponent as Location } from "../../static/icons/location.svg";
import Linkedin from "../../static/icons/socials/linkedin-icon.svg";
import Instagram from "../../static/icons/socials/instagram-icon.svg";
import Facebook from "../../static/icons/socials/facebook-icon.svg";
import Youtube from "../../static/icons/socials/youtube-icon.svg";
import Twitter from "../../static/icons/socials/twitter-icon.svg";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { downloadVCard } from "../utils/vcard_creator";
import { isEmptyString } from "../utils/functionHelpers";

type BusinessCardContextPage = PageContextTypeBreadcrumb & {
  page: Contentstack_Pages;
};

const BusinessCardLayoutStyled = styled.section``;

const BusinessCardStyled = styled.div`
  .wrapper {
    padding: 0 20px;

    height: 100%;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    ${mediaQueries.sm} {
      min-width: 798px;
      padding: 0;
      // max-width: 1266px;
      margin: auto;
      max-height: 650px;
    }
  }

  .footer {
    border-top-width: 2px;
    border-top-style: solid;
    padding: 30px 0 20px;

    ${mediaQueries.sm} {
      padding: 0;
      border-top-width: 1px;
    }

    .footer-socials {
      align-items: center;
      display: grid;
      gap: 16px;
      grid-auto-flow: row;
      justify-content: center;
      min-height: 150px;

      ${mediaQueries.sm} {
        grid-auto-flow: column;
        justify-content: space-between;
        max-width: 1266px;
        margin: auto;
        min-height: 80px;
      }

      p {
        color: #363139;
        font-family: "Quicksand";
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.8px;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;

        ${mediaQueries.sm} {
          text-align: initial;
        }
      }

      .logo-container {
        & > ul {
          padding-left: 0;

          h3 {
            margin: 0;
            font-family: "Quicksand";
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 1px;
            line-height: 20px;
            text-transform: uppercase;
            display: none;

            ${mediaQueries.sm} {
              display: block;
            }
          }

          li {
            a {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }

  .header-logo-container {
    text-align: center;
    padding: 40px 0;
    display: none;

    ${mediaQueries.sm} {
      min-width: 798px;
      background: none;
      display: block;
    }
  }

  .content-outer-container {
    padding: 35px 15px 90px;
  }

  .leaf-container {
    ${mediaQueries.md} {
      --image-height: clamp(160px, 50vw, 250px);
      --image-width: clamp(120px, 35.7vw, 200px);
      margin-block-end: 0;
      margin-block-start: 0;
    }
  }

  .image-and-text-inside {
    ${mediaQueries.md} {
      padding-block-end: 0;
      padding-block-start: 0;
      margin-block-end: 0;
      margin-block-start: 0;
    }
  }

  .leaf-content-container {
    max-width: 960px;
    margin: auto;

    & > div {
      margin-bottom: 0;
    }

    figure {
      height: 213px;
      width: 160px;
      margin-block-end: 32px;
      margin-block-start: 32px;

      ${mediaQueries.sm} {
        height: var(--image-height, 215px);
        width: var(--image-width, 160px);

        margin-block-end: 64px;
        margin-block-start: 64px;
      }
    }

    .leaf {
      padding-inline-start: calc(var(--image-width, 0) * 0.7);
      ${mediaQueries.sm} {
        min-width: 650px;
        padding-inline-start: calc(var(--image-width, 0) * 0.5);
      }

      .content {
        margin-block-end: 34px;
        margin-block-start: 34px;
        padding-left: 15px;

        ${mediaQueries.sm} {
          padding-left: 55px;
          margin-block-end: 64px;
          margin-block-start: 64px;
        }
      }
    }
  }

  .contact-name {
    color: #363139;
    font-family: "Tiempos Fine";
    font-size: 24px;
    letter-spacing: 0.5px;
    line-height: 30px;
  }

  .contact-position {
    color: #363139;
    font-family: "Quicksand";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 20px;
    margin: 8px 0 0;
    text-transform: uppercase;

    ${mediaQueries.sm} {
      margin: 8px 0 0;
    }
  }

  .contact-business-name {
    color: #363139;
    font-family: "Tiempos Fine";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 20px;
    margin: 8px 0 0;
    text-transform: uppercase;

    ${mediaQueries.sm} {
      margin: 8px 0 30px;
    }
  }

  .contact-details {
    display: flex;
    align-items: center;
    column-gap: 18px;
    margin-bottom: 10px;

    p,
    a {
      margin: 0;
      color: #363139;
      font-family: "Quicksand";
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 20px;
    }

    a {
      text-transform: lowercase;
      text-decoration: underline;
    }
  }

  .vcard-link {
    margin-top: 40px;
    color: #363139;
    font-family: "Quicksand";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 18px;
    display: grid;
    text-transform: uppercase;
    cursor: pointer;

    ${mediaQueries.sm} {
      display: block;
    }
  }
`;

const LogotypeStyled = styled(Logotype)`
  height: 20px;
  width: auto;

  ${mediaQueries.sm} {
    height: 30px;
  }

  ${compose(color, flexbox, grid, layout, position, space)}
`;

const TelephoneStyled = styled(Telephone)`
  height: 18px;
  width: auto;

  // ${mediaQueries.sm} {
  //   height: 18px;
  // }

  ${compose(color, flexbox, grid, layout, position, space)}
`;

const LocationStyled = styled(Location)`
  height: 18px;
  width: auto;

  // ${mediaQueries.sm} {
  //   height: 18px;
  // }

  ${compose(color, flexbox, grid, layout, position, space)}
`;

const EnvelopeStyled = styled(Envelope)`
  height: 14px;
  width: auto;

  // ${mediaQueries.sm} {
  //   height: 30px;
  // }

  ${compose(color, flexbox, grid, layout, position, space)}
`;

const SocialLinkListStyled = styled(ListPlain)`
  align-items: center;
  display: grid;
  gap: 30px;
  grid-auto-flow: column;
  justify-content: center;
  padding-inline-start: 2rem;

  ${mediaQueries.sm} {
    gap: 34px;
  }

  a {
    color: inherit;
    font-size: ${themeGet("fontSizes.8")}px;
    text-decoration: none;
  }
`;

const BusinessCardPage: React.FC<
  PageProps<BusinessCardPageQuery, BusinessCardContextPage>
> = ({ data, pageContext }) => {
  const {
    breadcrumb: { crumbs },
    page,
  } = pageContext;

  const isMobile = useMediaQuery(mediaQueries.qsm);

  // console.log(
  //   "data from contentStack business card: ",
  //   data.contentstackBusinessCard
  // );
  
  const businessCardData = data?.contentstackBusinessCard;
  const socialMedia = [
    businessCardData?.linkedin,
    businessCardData?.instagram && businessCardData?.instagram.includes("@")
      ? "https://www.instagram.com/" + businessCardData?.instagram.split("@")[1]
      : businessCardData?.instagram,
    businessCardData?.facebook,
    businessCardData?.youtube,
    businessCardData?.twitter,
  ];

  const socialMediaName = [
    "linkedin",
    "instagram",
    "facebook",
    "youtube",
    "twitter",
  ];
  const socialMediaIcons = [Linkedin, Instagram, Facebook, Youtube, Twitter];

  // console.log("social media:", socialMediaName[0]);
  // console.log("businessCardData: ", businessCardData)

  const downloadMyVCard = async () => {
    // console.log("download click");
    await downloadVCard(businessCardData);
  };

  const contentDesktop = (
    <React.Fragment>
      <h2 className="contact-name">
        {businessCardData.first_name} {businessCardData.surname}
      </h2>
      <h3 className="contact-position">{businessCardData.position}</h3>
      <h3 className="contact-business-name">{businessCardData.business_name}</h3>
      <span className="contact-details">
        <TelephoneStyled /> <p>{businessCardData.phone}</p>
      </span>
      { !isEmptyString(businessCardData.phone_2) && (
        <span className="contact-details">
          <TelephoneStyled /> <p>{businessCardData.phone_2}</p>
        </span>
      )}
      {!isEmptyString(businessCardData.address) && <span className="contact-details">
          <LocationStyled /> <p>{businessCardData.address}</p>
        </span>
      }
      <span className="contact-details">
        <EnvelopeStyled />
        <a href={`mailto:${businessCardData.email}`}>
          {businessCardData.email}
        </a>
      </span>
      <Link className="vcard-link" onClick={downloadMyVCard}>
        Download Vcard
      </Link>
    </React.Fragment>
  );

  const contentMobile = (
    <React.Fragment>
      <h2 className="contact-name">
        {businessCardData.first_name} {businessCardData.surname}
      </h2>
      <h3 className="contact-position">{businessCardData.position}</h3>
      <h3 className="contact-business-name">{businessCardData.business_name}</h3>
    </React.Fragment>
  );

  const outerContentMobile = (
    <React.Fragment>
      <span className="contact-details">
        <TelephoneStyled /> <p>{businessCardData.phone}</p>
      </span>
      {!isEmptyString(businessCardData.phone_2) && (
        <span className="contact-details">
          <TelephoneStyled /> <p>{businessCardData.phone_2}</p>
        </span>
      )}
      {!isEmptyString(businessCardData.address) && <span className="contact-details">
          <LocationStyled /> <p>{businessCardData.address}</p>
        </span>
      }
      <span className="contact-details">
        <EnvelopeStyled />
        <a href={`mailto:${businessCardData.email}`}>
          {businessCardData.email}
        </a>
      </span>
      {/* <Link className="vcard-link" onClick={downloadMyVCard}>
        Download Vcard
      </Link> */}
    </React.Fragment>
  );

  return (
      <BusinessCardLayoutStyled>
        <Layout>
          <SEO title={page?.title} />

          <BusinessCardStyled>
            <div
              className="wrapper"
              style={{
                backgroundImage:
                  !isMobile && businessCardData?.background_image
                    ? "url(" + businessCardData?.background_image.src + ")"
                    : "none",
              }}
            >
              <div className="header-logo-container">
                {/* <LogotypeStyled /> */}
              </div>
              <div className="leaf-content-container">
                <LeafPlain
                  colour={businessCardData?.background_colour}
                  image={businessCardData?.profile_picture}
                  layout="image-and-text-inside"
                >
                  {isMobile ? contentMobile : contentDesktop}
                </LeafPlain>

                {isMobile && (
                  <div className="content-outer-container">
                    {outerContentMobile}
                  </div>
                )}
              </div>
            </div>
            <div className="footer">
              <div className="footer-socials">
                <div className="logo-container">
                  <SocialLinkListStyled>
                    <h3>Follow me</h3>
                    {socialMedia.map((link, index) => (
                      <React.Fragment key={index}>
                        {link && (
                          <li key={index}>
                            <a href={link}>
                              <span className="sr-only">{link}</span>

                              <img
                                src={socialMediaIcons[index]}
                                title={socialMediaName[index]}
                              />
                            </a>
                          </li>
                        )}
                      </React.Fragment>
                    ))}
                  </SocialLinkListStyled>
                </div>
                <p>www.beautybylaperla.com</p>
              </div>
            </div>
          </BusinessCardStyled>
        </Layout>
      </BusinessCardLayoutStyled>
  );
};

export default BusinessCardPage;

export const query = graphql`
  query BusinessCard($contentstackId: String!) {
    contentstackBusinessCard(id: { eq: $contentstackId }) {
      ...Contentstack_businessCardFragment
    }
  }
`;
