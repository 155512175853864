import type { Product } from "schema-dts";

import clsx from "clsx";
import { Formik, Form, FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system";

import { functions as functionsPath } from "../utils/paths";

import { ReactComponent as WishlistAbsentIcon } from "../../static/icons/WishlistAbsent.svg";
import { ReactComponent as WishlistActiveIcon } from "../../static/icons/WishlistActive.svg";

import { Button } from "./Button";

import { getWishlistsByWishlistId, addToWishlist, deleteFromWishlist } from "../utils/wishlists_dataService";

export type QuickWishlistProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps & { product: Product };

interface Values {
  identifier: string;
  product_id: string;
}

export const QuickWishlistStyled: React.FC<QuickWishlistProps> = styled.div`
  ${compose(color, flexbox, grid, layout, position, space)}
`;

export const QuickWishlist: React.FC<QuickWishlistProps> = ({
  product,
  ...props
}) => {
  const [isInWishlist, setIsInWishlist] = useState(false);
  const [wishlistItemId, setwishlistItemId] = useState(0);

  useEffect(() => {
    refreshWishlist();

    // (async function getWishlists() {
    //   const response = await fetch(url.toString(), {
    //     headers: {
    //       Accept: "application/json",
    //     },
    //     method: "GET",
    //   })

    //   // console.log(response)

    //   if (response.ok) {
    //     const wishlists = await response.json()

    //     const present = wishlists.items.find(
    //       item => (item.id = product?.identifier)
    //     )

    //     setIsInWishlist(present)
    //   }
    // })()
  }, []);

  function refreshWishlist() {
    getWishlistsByWishlistId()
    .then(wishlistsData => {

      if (wishlistsData && wishlistsData.data) {
        const present = wishlistsData.data.items.find( item => (item.variant_id === parseInt(product?.identifier)) );
        present && setwishlistItemId(present.id);
        setIsInWishlist(present);
      }
    })
    .catch(err => {
      console.log("error: ", err);
    });
  }


  return (
    // null // hide temporary

    <QuickWishlistStyled {...props}>
      <Formik
        initialValues={{
          identifier: product?.identifier,
          product_id: product?.productID,
        }}
        onSubmit={async (
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {

          if (isInWishlist) {
            let _wishlistItemId = wishlistItemId;
            // console.log("wishlistItemId: ", wishlistItemId);
            const wishlistItemRemovedData = await deleteFromWishlist(_wishlistItemId, setSubmitting);

            wishlistItemRemovedData.data && refreshWishlist();
            // console.log("wishlistItemRemovedData: ", wishlistItemRemovedData.data);
          } else {
            const wishlistsData = await addToWishlist(values, setSubmitting);

            if (wishlistsData) {
              const present = wishlistsData.data.items.find( item => (item.id = product?.identifier) );
              setIsInWishlist(present);
            }

          }

          // const response = await fetch(url.toString(), {
          //   body: JSON.stringify(values),
          //   headers: {
          //     Accept: "application/json",
          //   },
          //   method: "POST",
          // })

          // console.log(response)

          // if (response.ok) {
          //   const wishlists = await response.json()

          //   const present = wishlists.items.find(
          //     item => (item.id = product?.identifier)
          //   )

          //   setIsInWishlist(present)
          // }

          // setSubmitting(false)
        }}
      >
        <Form>
          <Button type="submit" variant={props.type === "text" && "primary"}>
            <span className={clsx(props.type !== "text" && "sr-only")}>
              Add to Wishlist
            </span>
            {props.type !== "text" &&
              (isInWishlist ? <WishlistActiveIcon /> : <WishlistAbsentIcon />)}
          </Button>
        </Form>
      </Formik>
    </QuickWishlistStyled>
  );
};
