import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from "yup"
import React, { useContext } from 'react';
import { Button, Link } from './Button';
import { ResetPassword, resetPassword, ChangePassword, changePassword } from '../utils/password_dataService';

import { SnackbarContext } from './Snackbar';
import { dataLayerFormErrorsEvents } from '../utils/gtm';
import { useToggle } from '../hooks/useToggle';
import VisibilityInactive from "../../static/icons/VisibilityInactive.svg";
import VisibilityActive from "../../static/icons/VisibilityActive.svg";
import Cookies from "universal-cookie";

export const PasswordReset: React.FC<any> = ({setIsSuccess, isChangePassword = false, isCustomerId = '', ...props}) => {

  const [isPasswordToggled, setPasswordToggle] = useToggle();
  const [isConfirmPasswordToggled, setConfirmPasswordToggle] = useToggle();

  const PasswordResetValidation = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  })

  const ChangePasswordValidation = Yup.object().shape({
    password: Yup.string().min(2, "Password is too short").required("Required"),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), undefined], '• Passwords do not match').min(2, "Password is too short").required("Required"),
  })

  
  return (
    <>
     {!isChangePassword ?  <Formik
        initialValues={{
          email: ''
        }}
        onSubmit={async (
          values: ResetPassword,
          { setSubmitting }: FormikHelpers<ResetPassword>,
        ) => {
          let status = await resetPassword(values);

          localStorage.removeItem('reset_pw_email');
          if (status.status == "success") {
            setIsSuccess(true);
            
            if (!localStorage.getItem('reset_pw_email') || localStorage.getItem('reset_pw_email') != values.email) {
              localStorage.setItem("reset_pw_email", values.email);
            }
            
          }

          setSubmitting(false);
        }}
        validationSchema={PasswordResetValidation}
      >
        {({ errors, touched, values }) => (
          <Form>
            <label htmlFor="email">EMAIL ADDRESS*</label>
            <Field
              as="input"
              id="email"
              name="email"
              placeholder="Enter your email address"
              type="email"
            />
            {errors.email && touched.email ? (
              <div className="error">{errors.email}</div>
            ) : undefined}

            <Button
            className="reset-btn"
              type="submit"
              variant={
                errors.email ? 'secondary' : 'primary'
              }
              py={{ md: 4 }}
              px={{ md: 9 }}
            >
              Reset Password
            </Button>
          </Form>
        ) 
       }
      </Formik>
    :   <Formik
    initialValues={{
      password: '',
      confirmPassword: ''
    }}
    onSubmit={async (
      values: ChangePassword,
      { setSubmitting }: FormikHelpers<ChangePassword>,
    ) => {
      let status = await changePassword(values, isCustomerId);

      if (status.status == "success") {
        setIsSuccess(true);
      }

      setSubmitting(false);
    }}
    validationSchema={ChangePasswordValidation}
  >
    {({ errors, touched, values }) => (
  <Form>
    <label htmlFor="password">NEW PASSWORD*</label>
    <div className="input-container">
    <Field
      as="input"
      id="password"
      name="password"
      placeholder="Enter your password"
      type={isPasswordToggled ? "text" : "password"}
    />
    <img className="visibility-icon" src={isPasswordToggled ? VisibilityActive : VisibilityInactive} alt="Visibility" 
      onClick={() => setPasswordToggle()}/>
    </div>
   
    {errors.password && touched.password ? (
      <div className="error">{errors.password}</div>
    ) : undefined}

    <label htmlFor="password">CONFIRM PASSWORD*</label>
    <div className="input-container">
      <Field
      as="input"
      id="confirmPassword"
      name="confirmPassword"
      placeholder="Confirm password"
      type={isConfirmPasswordToggled ? "text" : "password"}
    />
    <img className="visibility-icon" src={isConfirmPasswordToggled ? VisibilityActive : VisibilityInactive} alt="Visibility" 
      onClick={() => setConfirmPasswordToggle()}/>
    </div>
    {errors.confirmPassword && touched.confirmPassword ? (
      <div className="error">{errors.confirmPassword}</div>
    ) : undefined}

    <Button
    className="reset-btn"
      type="submit"
      variant={
        errors.password || errors.confirmPassword ? 'secondary' : 'primary'
      }
      py={{ md: 4 }}
      px={{ md: 9 }}
    >
      Continue
    </Button>
  </Form>
)
   }
  </Formik>
    }
    </>
  );
};


