export const priceList = {
  "122HKD": {
    price_list_id: 1,
    variant_id: 87,
    price: 350,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 350,
    date_created: "2021-05-24T19:17:17Z",
    date_modified: "2021-05-24T19:17:17Z",
    currency: "hkd",
    product_id: 122,
  },
  "122AED": {
    price_list_id: 1,
    variant_id: 87,
    price: 185,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 185,
    date_created: "2021-05-24T19:17:17Z",
    date_modified: "2021-05-24T19:17:17Z",
    currency: "aed",
    product_id: 122,
  },
  "122CHF": {
    price_list_id: 1,
    variant_id: 87,
    price: 48,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 48,
    date_created: "2021-05-24T19:17:17Z",
    date_modified: "2021-05-24T19:17:17Z",
    currency: "chf",
    product_id: 122,
  },
  "122GBP": {
    price_list_id: 1,
    variant_id: 87,
    price: 32,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 32,
    date_created: "2021-05-24T19:17:17Z",
    date_modified: "2021-05-24T19:17:17Z",
    currency: "gbp",
    product_id: 122,
  },
  "122EUR": {
    price_list_id: 1,
    variant_id: 87,
    price: 37,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 37,
    date_created: "2021-05-24T19:17:17Z",
    date_modified: "2021-05-24T19:17:17Z",
    currency: "eur",
    product_id: 122,
  },
  "123HKD": {
    price_list_id: 1,
    variant_id: 88,
    price: 350,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 350,
    date_created: "2021-05-24T19:17:18Z",
    date_modified: "2021-05-24T19:17:18Z",
    currency: "hkd",
    product_id: 123,
  },
  "123AED": {
    price_list_id: 1,
    variant_id: 88,
    price: 185,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 185,
    date_created: "2021-05-24T19:17:18Z",
    date_modified: "2021-05-24T19:17:18Z",
    currency: "aed",
    product_id: 123,
  },
  "123CHF": {
    price_list_id: 1,
    variant_id: 88,
    price: 48,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 48,
    date_created: "2021-05-24T19:17:18Z",
    date_modified: "2021-05-24T19:17:18Z",
    currency: "chf",
    product_id: 123,
  },
  "123GBP": {
    price_list_id: 1,
    variant_id: 88,
    price: 32,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 32,
    date_created: "2021-05-24T19:17:18Z",
    date_modified: "2021-05-24T19:17:18Z",
    currency: "gbp",
    product_id: 123,
  },
  "123EUR": {
    price_list_id: 1,
    variant_id: 88,
    price: 37,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 37,
    date_created: "2021-05-24T19:17:18Z",
    date_modified: "2021-05-24T19:17:18Z",
    currency: "eur",
    product_id: 123,
  },
  "124HKD": {
    price_list_id: 1,
    variant_id: 89,
    price: 350,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 350,
    date_created: "2021-05-24T19:17:18Z",
    date_modified: "2021-05-24T19:17:18Z",
    currency: "hkd",
    product_id: 124,
  },
  "124AED": {
    price_list_id: 1,
    variant_id: 89,
    price: 185,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 185,
    date_created: "2021-05-24T19:17:18Z",
    date_modified: "2021-05-24T19:17:18Z",
    currency: "aed",
    product_id: 124,
  },
  "124CHF": {
    price_list_id: 1,
    variant_id: 89,
    price: 48,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 48,
    date_created: "2021-05-24T19:17:18Z",
    date_modified: "2021-05-24T19:17:18Z",
    currency: "chf",
    product_id: 124,
  },
  "124GBP": {
    price_list_id: 1,
    variant_id: 89,
    price: 32,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 32,
    date_created: "2021-05-24T19:17:18Z",
    date_modified: "2021-05-24T19:17:18Z",
    currency: "gbp",
    product_id: 124,
  },
  "124EUR": {
    price_list_id: 1,
    variant_id: 89,
    price: 37,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 37,
    date_created: "2021-05-24T19:17:18Z",
    date_modified: "2021-05-24T19:17:18Z",
    currency: "eur",
    product_id: 124,
  },
  "125GBP": {
    price_list_id: 1,
    variant_id: 90,
    price: 42,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 42,
    date_created: "2021-05-24T19:17:16Z",
    date_modified: "2021-05-24T19:17:16Z",
    currency: "gbp",
    product_id: 125,
  },
  "125EUR": {
    price_list_id: 1,
    variant_id: 90,
    price: 48,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 48,
    date_created: "2021-05-24T19:17:16Z",
    date_modified: "2021-05-24T19:17:16Z",
    currency: "eur",
    product_id: 125,
  },
  "125CHF": {
    price_list_id: 1,
    variant_id: 90,
    price: 62,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 62,
    date_created: "2021-05-24T19:17:16Z",
    date_modified: "2021-05-24T19:17:16Z",
    currency: "chf",
    product_id: 125,
  },
  "125AED": {
    price_list_id: 1,
    variant_id: 90,
    price: 240,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 240,
    date_created: "2021-05-24T19:17:16Z",
    date_modified: "2021-05-24T19:17:16Z",
    currency: "aed",
    product_id: 125,
  },
  "125HKD": {
    price_list_id: 1,
    variant_id: 90,
    price: 450,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 450,
    date_created: "2021-05-24T19:17:16Z",
    date_modified: "2021-05-24T19:17:16Z",
    currency: "hkd",
    product_id: 125,
  },
  "126HKD": {
    price_list_id: 1,
    variant_id: 91,
    price: 330,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 330,
    date_created: "2021-05-24T19:17:19Z",
    date_modified: "2021-05-24T19:17:19Z",
    currency: "hkd",
    product_id: 126,
  },
  "126AED": {
    price_list_id: 1,
    variant_id: 91,
    price: 175,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 175,
    date_created: "2021-05-24T19:17:19Z",
    date_modified: "2021-05-24T19:17:19Z",
    currency: "aed",
    product_id: 126,
  },
  "126CHF": {
    price_list_id: 1,
    variant_id: 91,
    price: 45,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 45,
    date_created: "2021-05-24T19:17:19Z",
    date_modified: "2021-05-24T19:17:19Z",
    currency: "chf",
    product_id: 126,
  },
  "126GBP": {
    price_list_id: 1,
    variant_id: 91,
    price: 31,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 31,
    date_created: "2021-05-24T19:17:19Z",
    date_modified: "2021-05-24T19:17:19Z",
    currency: "gbp",
    product_id: 126,
  },
  "126EUR": {
    price_list_id: 1,
    variant_id: 91,
    price: 35,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 35,
    date_created: "2021-05-24T19:17:19Z",
    date_modified: "2021-05-24T19:17:19Z",
    currency: "eur",
    product_id: 126,
  },
  "127HKD": {
    price_list_id: 1,
    variant_id: 92,
    price: 450,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 450,
    date_created: "2021-05-24T19:17:17Z",
    date_modified: "2021-05-24T19:17:17Z",
    currency: "hkd",
    product_id: 127,
  },
  "127AED": {
    price_list_id: 1,
    variant_id: 92,
    price: 240,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 240,
    date_created: "2021-05-24T19:17:17Z",
    date_modified: "2021-05-24T19:17:17Z",
    currency: "aed",
    product_id: 127,
  },
  "127CHF": {
    price_list_id: 1,
    variant_id: 92,
    price: 62,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 62,
    date_created: "2021-05-24T19:17:17Z",
    date_modified: "2021-05-24T19:17:17Z",
    currency: "chf",
    product_id: 127,
  },
  "127GBP": {
    price_list_id: 1,
    variant_id: 92,
    price: 42,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 42,
    date_created: "2021-05-24T19:17:17Z",
    date_modified: "2021-05-24T19:17:17Z",
    currency: "gbp",
    product_id: 127,
  },
  "127EUR": {
    price_list_id: 1,
    variant_id: 92,
    price: 48,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 48,
    date_created: "2021-05-24T19:17:17Z",
    date_modified: "2021-05-24T19:17:17Z",
    currency: "eur",
    product_id: 127,
  },
  "128CHF": {
    price_list_id: 1,
    variant_id: 93,
    price: 45,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 45,
    date_created: "2021-05-24T19:17:18Z",
    date_modified: "2021-05-24T19:17:18Z",
    currency: "chf",
    product_id: 128,
  },
  "128HKD": {
    price_list_id: 1,
    variant_id: 93,
    price: 330,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 330,
    date_created: "2021-05-24T19:17:18Z",
    date_modified: "2021-05-24T19:17:18Z",
    currency: "hkd",
    product_id: 128,
  },
  "128AED": {
    price_list_id: 1,
    variant_id: 93,
    price: 175,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 175,
    date_created: "2021-05-24T19:17:18Z",
    date_modified: "2021-05-24T19:17:18Z",
    currency: "aed",
    product_id: 128,
  },
  "128GBP": {
    price_list_id: 1,
    variant_id: 93,
    price: 31,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 31,
    date_created: "2021-05-24T19:17:18Z",
    date_modified: "2021-05-24T19:17:18Z",
    currency: "gbp",
    product_id: 128,
  },
  "128EUR": {
    price_list_id: 1,
    variant_id: 93,
    price: 35,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 35,
    date_created: "2021-05-24T19:17:18Z",
    date_modified: "2021-05-24T19:17:18Z",
    currency: "eur",
    product_id: 128,
  },
  "129HKD": {
    price_list_id: 1,
    variant_id: 94,
    price: 450,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 450,
    date_created: "2021-05-24T19:17:17Z",
    date_modified: "2021-05-24T19:17:17Z",
    currency: "hkd",
    product_id: 129,
  },
  "129AED": {
    price_list_id: 1,
    variant_id: 94,
    price: 240,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 240,
    date_created: "2021-05-24T19:17:17Z",
    date_modified: "2021-05-24T19:17:17Z",
    currency: "aed",
    product_id: 129,
  },
  "129EUR": {
    price_list_id: 1,
    variant_id: 94,
    price: 48,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 48,
    date_created: "2021-05-24T19:17:17Z",
    date_modified: "2021-05-24T19:17:17Z",
    currency: "eur",
    product_id: 129,
  },
  "129CHF": {
    price_list_id: 1,
    variant_id: 94,
    price: 62,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 62,
    date_created: "2021-05-24T19:17:17Z",
    date_modified: "2021-05-24T19:17:17Z",
    currency: "chf",
    product_id: 129,
  },
  "129GBP": {
    price_list_id: 1,
    variant_id: 94,
    price: 42,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 42,
    date_created: "2021-05-24T19:17:17Z",
    date_modified: "2021-05-24T19:17:17Z",
    currency: "gbp",
    product_id: 129,
  },
  "130EUR": {
    price_list_id: 1,
    variant_id: 95,
    price: 35,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 35,
    date_created: "2021-05-24T19:17:18Z",
    date_modified: "2021-05-24T19:17:18Z",
    currency: "eur",
    product_id: 130,
  },
  "130GBP": {
    price_list_id: 1,
    variant_id: 95,
    price: 31,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 31,
    date_created: "2021-05-24T19:17:18Z",
    date_modified: "2021-05-24T19:17:18Z",
    currency: "gbp",
    product_id: 130,
  },
  "130CHF": {
    price_list_id: 1,
    variant_id: 95,
    price: 45,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 45,
    date_created: "2021-05-24T19:17:18Z",
    date_modified: "2021-05-24T19:17:18Z",
    currency: "chf",
    product_id: 130,
  },
  "130AED": {
    price_list_id: 1,
    variant_id: 95,
    price: 175,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 175,
    date_created: "2021-05-24T19:17:18Z",
    date_modified: "2021-05-24T19:17:18Z",
    currency: "aed",
    product_id: 130,
  },
  "130HKD": {
    price_list_id: 1,
    variant_id: 95,
    price: 330,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 330,
    date_created: "2021-05-24T19:17:18Z",
    date_modified: "2021-05-24T19:17:18Z",
    currency: "hkd",
    product_id: 130,
  },
  "131EUR": {
    price_list_id: 1,
    variant_id: 96,
    price: 48,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 48,
    date_created: "2021-05-24T19:17:17Z",
    date_modified: "2021-05-24T19:17:17Z",
    currency: "eur",
    product_id: 131,
  },
  "131HKD": {
    price_list_id: 1,
    variant_id: 96,
    price: 450,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 450,
    date_created: "2021-05-24T19:17:17Z",
    date_modified: "2021-05-24T19:17:17Z",
    currency: "hkd",
    product_id: 131,
  },
  "131AED": {
    price_list_id: 1,
    variant_id: 96,
    price: 240,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 240,
    date_created: "2021-05-24T19:17:17Z",
    date_modified: "2021-05-24T19:17:17Z",
    currency: "aed",
    product_id: 131,
  },
  "131CHF": {
    price_list_id: 1,
    variant_id: 96,
    price: 62,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 62,
    date_created: "2021-05-24T19:17:17Z",
    date_modified: "2021-05-24T19:17:17Z",
    currency: "chf",
    product_id: 131,
  },
  "131GBP": {
    price_list_id: 1,
    variant_id: 96,
    price: 42,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 42,
    date_created: "2021-05-24T19:17:17Z",
    date_modified: "2021-05-24T19:17:17Z",
    currency: "gbp",
    product_id: 131,
  },
  "132GBP": {
    price_list_id: 1,
    variant_id: 97,
    price: 31,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 31,
    date_created: "2021-05-24T19:17:19Z",
    date_modified: "2021-05-24T19:17:19Z",
    currency: "gbp",
    product_id: 132,
  },
  "132CHF": {
    price_list_id: 1,
    variant_id: 97,
    price: 45,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 45,
    date_created: "2021-05-24T19:17:19Z",
    date_modified: "2021-05-24T19:17:19Z",
    currency: "chf",
    product_id: 132,
  },
  "132AED": {
    price_list_id: 1,
    variant_id: 97,
    price: 175,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 175,
    date_created: "2021-05-24T19:17:19Z",
    date_modified: "2021-05-24T19:17:19Z",
    currency: "aed",
    product_id: 132,
  },
  "132HKD": {
    price_list_id: 1,
    variant_id: 97,
    price: 330,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 330,
    date_created: "2021-05-24T19:17:19Z",
    date_modified: "2021-05-24T19:17:19Z",
    currency: "hkd",
    product_id: 132,
  },
  "132EUR": {
    price_list_id: 1,
    variant_id: 97,
    price: 35,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 35,
    date_created: "2021-05-24T19:17:19Z",
    date_modified: "2021-05-24T19:17:19Z",
    currency: "eur",
    product_id: 132,
  },
  "133EUR": {
    price_list_id: 1,
    variant_id: 98,
    price: 54,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 54,
    date_created: "2021-05-24T19:17:19Z",
    date_modified: "2021-05-24T19:17:19Z",
    currency: "eur",
    product_id: 133,
  },
  "133GBP": {
    price_list_id: 1,
    variant_id: 98,
    price: 47,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 47,
    date_created: "2021-05-24T19:17:19Z",
    date_modified: "2021-05-24T19:17:19Z",
    currency: "gbp",
    product_id: 133,
  },
  "133CHF": {
    price_list_id: 1,
    variant_id: 98,
    price: 70,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 70,
    date_created: "2021-05-24T19:17:19Z",
    date_modified: "2021-05-24T19:17:19Z",
    currency: "chf",
    product_id: 133,
  },
  "133AED": {
    price_list_id: 1,
    variant_id: 98,
    price: 270,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 270,
    date_created: "2021-05-24T19:17:19Z",
    date_modified: "2021-05-24T19:17:19Z",
    currency: "aed",
    product_id: 133,
  },
  "133HKD": {
    price_list_id: 1,
    variant_id: 98,
    price: 500,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 500,
    date_created: "2021-05-24T19:17:19Z",
    date_modified: "2021-05-24T19:17:19Z",
    currency: "hkd",
    product_id: 133,
  },
  "134HKD": {
    price_list_id: 1,
    variant_id: 99,
    price: 500,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 500,
    date_created: "2021-05-24T19:17:19Z",
    date_modified: "2021-05-24T19:17:19Z",
    currency: "hkd",
    product_id: 134,
  },
  "134AED": {
    price_list_id: 1,
    variant_id: 99,
    price: 270,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 270,
    date_created: "2021-05-24T19:17:19Z",
    date_modified: "2021-05-24T19:17:19Z",
    currency: "aed",
    product_id: 134,
  },
  "134CHF": {
    price_list_id: 1,
    variant_id: 99,
    price: 70,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 70,
    date_created: "2021-05-24T19:17:19Z",
    date_modified: "2021-05-24T19:17:19Z",
    currency: "chf",
    product_id: 134,
  },
  "134GBP": {
    price_list_id: 1,
    variant_id: 99,
    price: 47,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 47,
    date_created: "2021-05-24T19:17:19Z",
    date_modified: "2021-05-24T19:17:19Z",
    currency: "gbp",
    product_id: 134,
  },
  "134EUR": {
    price_list_id: 1,
    variant_id: 99,
    price: 54,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 54,
    date_created: "2021-05-24T19:17:19Z",
    date_modified: "2021-05-24T19:17:19Z",
    currency: "eur",
    product_id: 134,
  },
  "135EUR": {
    price_list_id: 1,
    variant_id: 100,
    price: 54,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 54,
    date_created: "2021-05-24T19:17:20Z",
    date_modified: "2021-05-24T19:17:20Z",
    currency: "eur",
    product_id: 135,
  },
  "135GBP": {
    price_list_id: 1,
    variant_id: 100,
    price: 47,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 47,
    date_created: "2021-05-24T19:17:20Z",
    date_modified: "2021-05-24T19:17:20Z",
    currency: "gbp",
    product_id: 135,
  },
  "135CHF": {
    price_list_id: 1,
    variant_id: 100,
    price: 70,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 70,
    date_created: "2021-05-24T19:17:20Z",
    date_modified: "2021-05-24T19:17:20Z",
    currency: "chf",
    product_id: 135,
  },
  "135AED": {
    price_list_id: 1,
    variant_id: 100,
    price: 270,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 270,
    date_created: "2021-05-24T19:17:20Z",
    date_modified: "2021-05-24T19:17:20Z",
    currency: "aed",
    product_id: 135,
  },
  "135HKD": {
    price_list_id: 1,
    variant_id: 100,
    price: 500,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 500,
    date_created: "2021-05-24T19:17:20Z",
    date_modified: "2021-05-24T19:17:20Z",
    currency: "hkd",
    product_id: 135,
  },
  "136EUR": {
    price_list_id: 1,
    variant_id: 101,
    price: 54,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 54,
    date_created: "2021-05-24T19:17:20Z",
    date_modified: "2021-05-24T19:17:20Z",
    currency: "eur",
    product_id: 136,
  },
  "136GBP": {
    price_list_id: 1,
    variant_id: 101,
    price: 47,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 47,
    date_created: "2021-05-24T19:17:20Z",
    date_modified: "2021-05-24T19:17:20Z",
    currency: "gbp",
    product_id: 136,
  },
  "136CHF": {
    price_list_id: 1,
    variant_id: 101,
    price: 70,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 70,
    date_created: "2021-05-24T19:17:20Z",
    date_modified: "2021-05-24T19:17:20Z",
    currency: "chf",
    product_id: 136,
  },
  "136AED": {
    price_list_id: 1,
    variant_id: 101,
    price: 270,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 270,
    date_created: "2021-05-24T19:17:20Z",
    date_modified: "2021-05-24T19:17:20Z",
    currency: "aed",
    product_id: 136,
  },
  "136HKD": {
    price_list_id: 1,
    variant_id: 101,
    price: 500,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 500,
    date_created: "2021-05-24T19:17:20Z",
    date_modified: "2021-05-24T19:17:20Z",
    currency: "hkd",
    product_id: 136,
  },
  "137EUR": {
    price_list_id: 1,
    variant_id: 102,
    price: 54,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 54,
    date_created: "2021-05-24T19:17:20Z",
    date_modified: "2021-05-24T19:17:20Z",
    currency: "eur",
    product_id: 137,
  },
  "137HKD": {
    price_list_id: 1,
    variant_id: 102,
    price: 500,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 500,
    date_created: "2021-05-24T19:17:20Z",
    date_modified: "2021-05-24T19:17:20Z",
    currency: "hkd",
    product_id: 137,
  },
  "137AED": {
    price_list_id: 1,
    variant_id: 102,
    price: 270,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 270,
    date_created: "2021-05-24T19:17:20Z",
    date_modified: "2021-05-24T19:17:20Z",
    currency: "aed",
    product_id: 137,
  },
  "137GBP": {
    price_list_id: 1,
    variant_id: 102,
    price: 47,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 47,
    date_created: "2021-05-24T19:17:20Z",
    date_modified: "2021-05-24T19:17:20Z",
    currency: "gbp",
    product_id: 137,
  },
  "137CHF": {
    price_list_id: 1,
    variant_id: 102,
    price: 70,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 70,
    date_created: "2021-05-24T19:17:20Z",
    date_modified: "2021-05-24T19:17:20Z",
    currency: "chf",
    product_id: 137,
  },
  "138EUR": {
    price_list_id: 1,
    variant_id: 103,
    price: 54,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 54,
    date_created: "2021-05-24T19:17:20Z",
    date_modified: "2021-05-24T19:17:20Z",
    currency: "eur",
    product_id: 138,
  },
  "138GBP": {
    price_list_id: 1,
    variant_id: 103,
    price: 47,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 47,
    date_created: "2021-05-24T19:17:20Z",
    date_modified: "2021-05-24T19:17:20Z",
    currency: "gbp",
    product_id: 138,
  },
  "138CHF": {
    price_list_id: 1,
    variant_id: 103,
    price: 70,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 70,
    date_created: "2021-05-24T19:17:20Z",
    date_modified: "2021-05-24T19:17:20Z",
    currency: "chf",
    product_id: 138,
  },
  "138AED": {
    price_list_id: 1,
    variant_id: 103,
    price: 270,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 270,
    date_created: "2021-05-24T19:17:20Z",
    date_modified: "2021-05-24T19:17:20Z",
    currency: "aed",
    product_id: 138,
  },
  "138HKD": {
    price_list_id: 1,
    variant_id: 103,
    price: 500,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 500,
    date_created: "2021-05-24T19:17:20Z",
    date_modified: "2021-05-24T19:17:20Z",
    currency: "hkd",
    product_id: 138,
  },
  "139HKD": {
    price_list_id: 1,
    variant_id: 104,
    price: 500,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 500,
    date_created: "2021-05-24T19:17:21Z",
    date_modified: "2021-05-24T19:17:21Z",
    currency: "hkd",
    product_id: 139,
  },
  "139AED": {
    price_list_id: 1,
    variant_id: 104,
    price: 270,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 270,
    date_created: "2021-05-24T19:17:21Z",
    date_modified: "2021-05-24T19:17:21Z",
    currency: "aed",
    product_id: 139,
  },
  "139CHF": {
    price_list_id: 1,
    variant_id: 104,
    price: 70,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 70,
    date_created: "2021-05-24T19:17:21Z",
    date_modified: "2021-05-24T19:17:21Z",
    currency: "chf",
    product_id: 139,
  },
  "139GBP": {
    price_list_id: 1,
    variant_id: 104,
    price: 47,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 47,
    date_created: "2021-05-24T19:17:21Z",
    date_modified: "2021-05-24T19:17:21Z",
    currency: "gbp",
    product_id: 139,
  },
  "139EUR": {
    price_list_id: 1,
    variant_id: 104,
    price: 54,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 54,
    date_created: "2021-05-24T19:17:21Z",
    date_modified: "2021-05-24T19:17:21Z",
    currency: "eur",
    product_id: 139,
  },
  "140EUR": {
    price_list_id: 1,
    variant_id: 105,
    price: 54,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 54,
    date_created: "2021-05-24T19:17:21Z",
    date_modified: "2021-05-24T19:17:21Z",
    currency: "eur",
    product_id: 140,
  },
  "140GBP": {
    price_list_id: 1,
    variant_id: 105,
    price: 47,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 47,
    date_created: "2021-05-24T19:17:21Z",
    date_modified: "2021-05-24T19:17:21Z",
    currency: "gbp",
    product_id: 140,
  },
  "140CHF": {
    price_list_id: 1,
    variant_id: 105,
    price: 70,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 70,
    date_created: "2021-05-24T19:17:21Z",
    date_modified: "2021-05-24T19:17:21Z",
    currency: "chf",
    product_id: 140,
  },
  "140AED": {
    price_list_id: 1,
    variant_id: 105,
    price: 270,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 270,
    date_created: "2021-05-24T19:17:21Z",
    date_modified: "2021-05-24T19:17:21Z",
    currency: "aed",
    product_id: 140,
  },
  "140HKD": {
    price_list_id: 1,
    variant_id: 105,
    price: 500,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 500,
    date_created: "2021-05-24T19:17:21Z",
    date_modified: "2021-05-24T19:17:21Z",
    currency: "hkd",
    product_id: 140,
  },
  "141EUR": {
    price_list_id: 1,
    variant_id: 106,
    price: 52,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 52,
    date_created: "2021-05-24T19:17:21Z",
    date_modified: "2021-05-24T19:17:21Z",
    currency: "eur",
    product_id: 141,
  },
  "141GBP": {
    price_list_id: 1,
    variant_id: 106,
    price: 45,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 45,
    date_created: "2021-05-24T19:17:21Z",
    date_modified: "2021-05-24T19:17:21Z",
    currency: "gbp",
    product_id: 141,
  },
  "141CHF": {
    price_list_id: 1,
    variant_id: 106,
    price: 67,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 67,
    date_created: "2021-05-24T19:17:21Z",
    date_modified: "2021-05-24T19:17:21Z",
    currency: "chf",
    product_id: 141,
  },
  "141AED": {
    price_list_id: 1,
    variant_id: 106,
    price: 260,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 260,
    date_created: "2021-05-24T19:17:21Z",
    date_modified: "2021-05-24T19:17:21Z",
    currency: "aed",
    product_id: 141,
  },
  "141HKD": {
    price_list_id: 1,
    variant_id: 106,
    price: 480,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 480,
    date_created: "2021-05-24T19:17:21Z",
    date_modified: "2021-05-24T19:17:21Z",
    currency: "hkd",
    product_id: 141,
  },
  "142HKD": {
    price_list_id: 1,
    variant_id: 107,
    price: 480,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 480,
    date_created: "2021-05-24T19:17:21Z",
    date_modified: "2021-05-24T19:17:21Z",
    currency: "hkd",
    product_id: 142,
  },
  "142AED": {
    price_list_id: 1,
    variant_id: 107,
    price: 260,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 260,
    date_created: "2021-05-24T19:17:21Z",
    date_modified: "2021-05-24T19:17:21Z",
    currency: "aed",
    product_id: 142,
  },
  "142CHF": {
    price_list_id: 1,
    variant_id: 107,
    price: 67,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 67,
    date_created: "2021-05-24T19:17:21Z",
    date_modified: "2021-05-24T19:17:21Z",
    currency: "chf",
    product_id: 142,
  },
  "142GBP": {
    price_list_id: 1,
    variant_id: 107,
    price: 45,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 45,
    date_created: "2021-05-24T19:17:21Z",
    date_modified: "2021-05-24T19:17:21Z",
    currency: "gbp",
    product_id: 142,
  },
  "142EUR": {
    price_list_id: 1,
    variant_id: 107,
    price: 52,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 52,
    date_created: "2021-05-24T19:17:21Z",
    date_modified: "2021-05-24T19:17:21Z",
    currency: "eur",
    product_id: 142,
  },
  "143HKD": {
    price_list_id: 1,
    variant_id: 108,
    price: 950,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 950,
    date_created: "2021-05-24T19:17:13Z",
    date_modified: "2021-05-24T19:17:13Z",
    currency: "hkd",
    product_id: 143,
  },
  "143AED": {
    price_list_id: 1,
    variant_id: 108,
    price: 505,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 505,
    date_created: "2021-05-24T19:17:13Z",
    date_modified: "2021-05-24T19:17:13Z",
    currency: "aed",
    product_id: 143,
  },
  "143CHF": {
    price_list_id: 1,
    variant_id: 108,
    price: 135,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 135,
    date_created: "2021-05-24T19:17:13Z",
    date_modified: "2021-05-24T19:17:13Z",
    currency: "chf",
    product_id: 143,
  },
  "143GBP": {
    price_list_id: 1,
    variant_id: 108,
    price: 88,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 88,
    date_created: "2021-05-24T19:17:13Z",
    date_modified: "2021-05-24T19:17:13Z",
    currency: "gbp",
    product_id: 143,
  },
  "143EUR": {
    price_list_id: 1,
    variant_id: 108,
    price: 100,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 100,
    date_created: "2021-05-24T19:17:13Z",
    date_modified: "2021-05-24T19:17:13Z",
    currency: "eur",
    product_id: 143,
  },
  "145GBP": {
    price_list_id: 1,
    variant_id: 110,
    price: 57,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 57,
    date_created: "2021-05-24T19:17:11Z",
    date_modified: "2021-05-24T19:17:11Z",
    currency: "gbp",
    product_id: 145,
  },
  "145EUR": {
    price_list_id: 1,
    variant_id: 110,
    price: 65,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 65,
    date_created: "2021-05-24T19:17:11Z",
    date_modified: "2021-05-24T19:17:11Z",
    currency: "eur",
    product_id: 145,
  },
  "145CHF": {
    price_list_id: 1,
    variant_id: 110,
    price: 90,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 90,
    date_created: "2021-05-24T19:17:11Z",
    date_modified: "2021-05-24T19:17:11Z",
    currency: "chf",
    product_id: 145,
  },
  "145AED": {
    price_list_id: 1,
    variant_id: 110,
    price: 330,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 330,
    date_created: "2021-05-24T19:17:11Z",
    date_modified: "2021-05-24T19:17:11Z",
    currency: "aed",
    product_id: 145,
  },
  "145HKD": {
    price_list_id: 1,
    variant_id: 110,
    price: 660,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 660,
    date_created: "2021-05-24T19:17:11Z",
    date_modified: "2021-05-24T19:17:11Z",
    currency: "hkd",
    product_id: 145,
  },
  "146EUR": {
    price_list_id: 1,
    variant_id: 111,
    price: 95,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 95,
    date_created: "2021-05-24T19:17:11Z",
    date_modified: "2021-05-24T19:17:11Z",
    currency: "eur",
    product_id: 146,
  },
  "146GBP": {
    price_list_id: 1,
    variant_id: 111,
    price: 83,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 83,
    date_created: "2021-05-24T19:17:11Z",
    date_modified: "2021-05-24T19:17:11Z",
    currency: "gbp",
    product_id: 146,
  },
  "146CHF": {
    price_list_id: 1,
    variant_id: 111,
    price: 130,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 130,
    date_created: "2021-05-24T19:17:11Z",
    date_modified: "2021-05-24T19:17:11Z",
    currency: "chf",
    product_id: 146,
  },
  "146AED": {
    price_list_id: 1,
    variant_id: 111,
    price: 480,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 480,
    date_created: "2021-05-24T19:17:11Z",
    date_modified: "2021-05-24T19:17:11Z",
    currency: "aed",
    product_id: 146,
  },
  "146HKD": {
    price_list_id: 1,
    variant_id: 111,
    price: 900,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 900,
    date_created: "2021-05-24T19:17:11Z",
    date_modified: "2021-05-24T19:17:11Z",
    currency: "hkd",
    product_id: 146,
  },
  "155CHF": {
    price_list_id: 1,
    variant_id: 120,
    price: 135,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 135,
    date_created: "2021-05-24T19:17:12Z",
    date_modified: "2021-05-24T19:17:12Z",
    currency: "chf",
    product_id: 155,
  },
  "155EUR": {
    price_list_id: 1,
    variant_id: 120,
    price: 100,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 100,
    date_created: "2021-05-24T19:17:12Z",
    date_modified: "2021-05-24T19:17:12Z",
    currency: "eur",
    product_id: 155,
  },
  "155GBP": {
    price_list_id: 1,
    variant_id: 120,
    price: 88,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 88,
    date_created: "2021-05-24T19:17:12Z",
    date_modified: "2021-05-24T19:17:12Z",
    currency: "gbp",
    product_id: 155,
  },
  "155AED": {
    price_list_id: 1,
    variant_id: 120,
    price: 505,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 505,
    date_created: "2021-05-24T19:17:12Z",
    date_modified: "2021-05-24T19:17:12Z",
    currency: "aed",
    product_id: 155,
  },
  "155HKD": {
    price_list_id: 1,
    variant_id: 120,
    price: 950,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 950,
    date_created: "2021-05-24T19:17:12Z",
    date_modified: "2021-05-24T19:17:12Z",
    currency: "hkd",
    product_id: 155,
  },
  "156HKD": {
    price_list_id: 1,
    variant_id: 121,
    price: 950,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 950,
    date_created: "2021-05-24T19:17:12Z",
    date_modified: "2021-05-24T19:17:12Z",
    currency: "hkd",
    product_id: 156,
  },
  "156EUR": {
    price_list_id: 1,
    variant_id: 121,
    price: 100,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 100,
    date_created: "2021-05-24T19:17:12Z",
    date_modified: "2021-05-24T19:17:12Z",
    currency: "eur",
    product_id: 156,
  },
  "156GBP": {
    price_list_id: 1,
    variant_id: 121,
    price: 88,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 88,
    date_created: "2021-05-24T19:17:12Z",
    date_modified: "2021-05-24T19:17:12Z",
    currency: "gbp",
    product_id: 156,
  },
  "156AED": {
    price_list_id: 1,
    variant_id: 121,
    price: 505,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 505,
    date_created: "2021-05-24T19:17:12Z",
    date_modified: "2021-05-24T19:17:12Z",
    currency: "aed",
    product_id: 156,
  },
  "156CHF": {
    price_list_id: 1,
    variant_id: 121,
    price: 135,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 135,
    date_created: "2021-05-24T19:17:12Z",
    date_modified: "2021-05-24T19:17:12Z",
    currency: "chf",
    product_id: 156,
  },
  "157HKD": {
    price_list_id: 1,
    variant_id: 122,
    price: 950,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 950,
    date_created: "2021-05-24T19:17:13Z",
    date_modified: "2021-05-24T19:17:13Z",
    currency: "hkd",
    product_id: 157,
  },
  "157EUR": {
    price_list_id: 1,
    variant_id: 122,
    price: 100,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 100,
    date_created: "2021-05-24T19:17:13Z",
    date_modified: "2021-05-24T19:17:13Z",
    currency: "eur",
    product_id: 157,
  },
  "157GBP": {
    price_list_id: 1,
    variant_id: 122,
    price: 88,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 88,
    date_created: "2021-05-24T19:17:13Z",
    date_modified: "2021-05-24T19:17:13Z",
    currency: "gbp",
    product_id: 157,
  },
  "157CHF": {
    price_list_id: 1,
    variant_id: 122,
    price: 135,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 135,
    date_created: "2021-05-24T19:17:13Z",
    date_modified: "2021-05-24T19:17:13Z",
    currency: "chf",
    product_id: 157,
  },
  "157AED": {
    price_list_id: 1,
    variant_id: 122,
    price: 505,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 505,
    date_created: "2021-05-24T19:17:13Z",
    date_modified: "2021-05-24T19:17:13Z",
    currency: "aed",
    product_id: 157,
  },
  "158HKD": {
    price_list_id: 1,
    variant_id: 123,
    price: 950,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 950,
    date_created: "2021-05-24T19:17:14Z",
    date_modified: "2021-05-24T19:17:14Z",
    currency: "hkd",
    product_id: 158,
  },
  "158AED": {
    price_list_id: 1,
    variant_id: 123,
    price: 505,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 505,
    date_created: "2021-05-24T19:17:14Z",
    date_modified: "2021-05-24T19:17:14Z",
    currency: "aed",
    product_id: 158,
  },
  "158CHF": {
    price_list_id: 1,
    variant_id: 123,
    price: 135,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 135,
    date_created: "2021-05-24T19:17:14Z",
    date_modified: "2021-05-24T19:17:14Z",
    currency: "chf",
    product_id: 158,
  },
  "158GBP": {
    price_list_id: 1,
    variant_id: 123,
    price: 88,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 88,
    date_created: "2021-05-24T19:17:14Z",
    date_modified: "2021-05-24T19:17:14Z",
    currency: "gbp",
    product_id: 158,
  },
  "158EUR": {
    price_list_id: 1,
    variant_id: 123,
    price: 100,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 100,
    date_created: "2021-05-24T19:17:14Z",
    date_modified: "2021-05-24T19:17:14Z",
    currency: "eur",
    product_id: 158,
  },
  "159EUR": {
    price_list_id: 1,
    variant_id: 124,
    price: 100,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 100,
    date_created: "2021-05-24T19:17:14Z",
    date_modified: "2021-05-24T19:17:14Z",
    currency: "eur",
    product_id: 159,
  },
  "159GBP": {
    price_list_id: 1,
    variant_id: 124,
    price: 88,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 88,
    date_created: "2021-05-24T19:17:14Z",
    date_modified: "2021-05-24T19:17:14Z",
    currency: "gbp",
    product_id: 159,
  },
  "159CHF": {
    price_list_id: 1,
    variant_id: 124,
    price: 135,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 135,
    date_created: "2021-05-24T19:17:14Z",
    date_modified: "2021-05-24T19:17:14Z",
    currency: "chf",
    product_id: 159,
  },
  "159AED": {
    price_list_id: 1,
    variant_id: 124,
    price: 505,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 505,
    date_created: "2021-05-24T19:17:14Z",
    date_modified: "2021-05-24T19:17:14Z",
    currency: "aed",
    product_id: 159,
  },
  "159HKD": {
    price_list_id: 1,
    variant_id: 124,
    price: 950,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 950,
    date_created: "2021-05-24T19:17:14Z",
    date_modified: "2021-05-24T19:17:14Z",
    currency: "hkd",
    product_id: 159,
  },
  "160EUR": {
    price_list_id: 1,
    variant_id: 125,
    price: 100,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 100,
    date_created: "2021-05-24T19:17:15Z",
    date_modified: "2021-05-24T19:17:15Z",
    currency: "eur",
    product_id: 160,
  },
  "160GBP": {
    price_list_id: 1,
    variant_id: 125,
    price: 88,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 88,
    date_created: "2021-05-24T19:17:15Z",
    date_modified: "2021-05-24T19:17:15Z",
    currency: "gbp",
    product_id: 160,
  },
  "160CHF": {
    price_list_id: 1,
    variant_id: 125,
    price: 135,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 135,
    date_created: "2021-05-24T19:17:15Z",
    date_modified: "2021-05-24T19:17:15Z",
    currency: "chf",
    product_id: 160,
  },
  "160AED": {
    price_list_id: 1,
    variant_id: 125,
    price: 505,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 505,
    date_created: "2021-05-24T19:17:15Z",
    date_modified: "2021-05-24T19:17:15Z",
    currency: "aed",
    product_id: 160,
  },
  "160HKD": {
    price_list_id: 1,
    variant_id: 125,
    price: 950,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 950,
    date_created: "2021-05-24T19:17:15Z",
    date_modified: "2021-05-24T19:17:15Z",
    currency: "hkd",
    product_id: 160,
  },
  "161EUR": {
    price_list_id: 1,
    variant_id: 126,
    price: 100,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 100,
    date_created: "2021-05-24T19:17:15Z",
    date_modified: "2021-05-24T19:17:15Z",
    currency: "eur",
    product_id: 161,
  },
  "161GBP": {
    price_list_id: 1,
    variant_id: 126,
    price: 88,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 88,
    date_created: "2021-05-24T19:17:15Z",
    date_modified: "2021-05-24T19:17:15Z",
    currency: "gbp",
    product_id: 161,
  },
  "161CHF": {
    price_list_id: 1,
    variant_id: 126,
    price: 135,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 135,
    date_created: "2021-05-24T19:17:15Z",
    date_modified: "2021-05-24T19:17:15Z",
    currency: "chf",
    product_id: 161,
  },
  "161AED": {
    price_list_id: 1,
    variant_id: 126,
    price: 505,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 505,
    date_created: "2021-05-24T19:17:15Z",
    date_modified: "2021-05-24T19:17:15Z",
    currency: "aed",
    product_id: 161,
  },
  "161HKD": {
    price_list_id: 1,
    variant_id: 126,
    price: 950,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 950,
    date_created: "2021-05-24T19:17:15Z",
    date_modified: "2021-05-24T19:17:15Z",
    currency: "hkd",
    product_id: 161,
  },
  "165EUR": {
    price_list_id: 1,
    variant_id: 130,
    price: 95,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 95,
    date_created: "2021-05-24T19:17:16Z",
    date_modified: "2021-05-24T19:17:16Z",
    currency: "eur",
    product_id: 165,
  },
  "165GBP": {
    price_list_id: 1,
    variant_id: 130,
    price: 83,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 83,
    date_created: "2021-05-24T19:17:16Z",
    date_modified: "2021-05-24T19:17:16Z",
    currency: "gbp",
    product_id: 165,
  },
  "165CHF": {
    price_list_id: 1,
    variant_id: 130,
    price: 130,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 130,
    date_created: "2021-05-24T19:17:16Z",
    date_modified: "2021-05-24T19:17:16Z",
    currency: "chf",
    product_id: 165,
  },
  "165AED": {
    price_list_id: 1,
    variant_id: 130,
    price: 480,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 480,
    date_created: "2021-05-24T19:17:16Z",
    date_modified: "2021-05-24T19:17:16Z",
    currency: "aed",
    product_id: 165,
  },
  "165HKD": {
    price_list_id: 1,
    variant_id: 130,
    price: 900,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 900,
    date_created: "2021-05-24T19:17:16Z",
    date_modified: "2021-05-24T19:17:16Z",
    currency: "hkd",
    product_id: 165,
  },
  "166EUR": {
    price_list_id: 1,
    variant_id: 131,
    price: 95,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 95,
    date_created: "2021-05-24T19:17:16Z",
    date_modified: "2021-05-24T19:17:16Z",
    currency: "eur",
    product_id: 166,
  },
  "166GBP": {
    price_list_id: 1,
    variant_id: 131,
    price: 83,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 83,
    date_created: "2021-05-24T19:17:16Z",
    date_modified: "2021-05-24T19:17:16Z",
    currency: "gbp",
    product_id: 166,
  },
  "166CHF": {
    price_list_id: 1,
    variant_id: 131,
    price: 130,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 130,
    date_created: "2021-05-24T19:17:16Z",
    date_modified: "2021-05-24T19:17:16Z",
    currency: "chf",
    product_id: 166,
  },
  "166AED": {
    price_list_id: 1,
    variant_id: 131,
    price: 480,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 480,
    date_created: "2021-05-24T19:17:16Z",
    date_modified: "2021-05-24T19:17:16Z",
    currency: "aed",
    product_id: 166,
  },
  "166HKD": {
    price_list_id: 1,
    variant_id: 131,
    price: 900,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 900,
    date_created: "2021-05-24T19:17:16Z",
    date_modified: "2021-05-24T19:17:16Z",
    currency: "hkd",
    product_id: 166,
  },
  "144GBP": {
    price_list_id: 1,
    variant_id: 109,
    price: 118,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 118,
    date_created: "2021-05-27T06:49:17Z",
    date_modified: "2021-05-27T06:49:17Z",
    currency: "gbp",
    product_id: 144,
  },
  "144EUR": {
    price_list_id: 1,
    variant_id: 109,
    price: 135,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 135,
    date_created: "2021-05-27T06:49:30Z",
    date_modified: "2021-05-27T06:49:30Z",
    currency: "eur",
    product_id: 144,
  },
  "144CHF": {
    price_list_id: 1,
    variant_id: 109,
    price: 185,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 185,
    date_created: "2021-05-27T06:49:44Z",
    date_modified: "2021-05-27T06:49:44Z",
    currency: "chf",
    product_id: 144,
  },
  "147CHF": {
    price_list_id: 1,
    variant_id: 112,
    price: 390,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 390,
    date_created: "2021-05-27T06:51:27Z",
    date_modified: "2021-05-27T06:51:27Z",
    currency: "chf",
    product_id: 147,
  },
  "147EUR": {
    price_list_id: 1,
    variant_id: 112,
    price: 290,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 290,
    date_created: "2021-05-27T06:52:15Z",
    date_modified: "2021-05-27T06:54:15Z",
    currency: "eur",
    product_id: 147,
  },
  "147GBP": {
    price_list_id: 1,
    variant_id: 112,
    price: 254,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 254,
    date_created: "2021-05-27T06:52:45Z",
    date_modified: "2021-05-27T06:52:45Z",
    currency: "gbp",
    product_id: 147,
  },
  "148GBP": {
    price_list_id: 1,
    variant_id: 113,
    price: 254,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 254,
    date_created: "2021-05-27T06:54:42Z",
    date_modified: "2021-05-27T06:54:42Z",
    currency: "gbp",
    product_id: 148,
  },
  "148EUR": {
    price_list_id: 1,
    variant_id: 113,
    price: 290,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 290,
    date_created: "2021-05-27T06:54:15Z",
    date_modified: "2021-05-27T06:54:15Z",
    currency: "eur",
    product_id: 148,
  },
  "148CHF": {
    price_list_id: 1,
    variant_id: 113,
    price: 390,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 390,
    date_created: "2021-05-27T06:53:38Z",
    date_modified: "2021-05-27T06:53:38Z",
    currency: "chf",
    product_id: 148,
  },
  "149GBP": {
    price_list_id: 1,
    variant_id: 114,
    price: 254,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 254,
    date_created: "2021-05-27T06:54:42Z",
    date_modified: "2021-05-27T06:54:42Z",
    currency: "gbp",
    product_id: 149,
  },
  "149CHF": {
    price_list_id: 1,
    variant_id: 114,
    price: 390,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 390,
    date_created: "2021-05-27T06:53:38Z",
    date_modified: "2021-05-27T06:53:38Z",
    currency: "chf",
    product_id: 149,
  },
  "149EUR": {
    price_list_id: 1,
    variant_id: 114,
    price: 290,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 290,
    date_created: "2021-05-27T06:54:15Z",
    date_modified: "2021-05-27T06:54:15Z",
    currency: "eur",
    product_id: 149,
  },
  "150CHF": {
    price_list_id: 1,
    variant_id: 115,
    price: 390,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 390,
    date_created: "2021-05-27T06:53:38Z",
    date_modified: "2021-05-27T06:53:38Z",
    currency: "chf",
    product_id: 150,
  },
  "150GBP": {
    price_list_id: 1,
    variant_id: 115,
    price: 254,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 254,
    date_created: "2021-05-27T06:54:42Z",
    date_modified: "2021-05-27T06:54:42Z",
    currency: "gbp",
    product_id: 150,
  },
  "150EUR": {
    price_list_id: 1,
    variant_id: 115,
    price: 290,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 290,
    date_created: "2021-05-27T06:54:15Z",
    date_modified: "2021-05-27T06:54:15Z",
    currency: "eur",
    product_id: 150,
  },
  "151EUR": {
    price_list_id: 1,
    variant_id: 116,
    price: 290,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 290,
    date_created: "2021-05-27T06:54:15Z",
    date_modified: "2021-05-27T06:54:15Z",
    currency: "eur",
    product_id: 151,
  },
  "151GBP": {
    price_list_id: 1,
    variant_id: 116,
    price: 254,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 254,
    date_created: "2021-05-27T06:54:42Z",
    date_modified: "2021-05-27T06:54:42Z",
    currency: "gbp",
    product_id: 151,
  },
  "151CHF": {
    price_list_id: 1,
    variant_id: 116,
    price: 390,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 390,
    date_created: "2021-05-27T06:53:38Z",
    date_modified: "2021-05-27T06:53:38Z",
    currency: "chf",
    product_id: 151,
  },
  "152EUR": {
    price_list_id: 1,
    variant_id: 117,
    price: 290,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 290,
    date_created: "2021-05-27T06:54:15Z",
    date_modified: "2021-05-27T06:54:15Z",
    currency: "eur",
    product_id: 152,
  },
  "152GBP": {
    price_list_id: 1,
    variant_id: 117,
    price: 254,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 254,
    date_created: "2021-05-27T06:54:42Z",
    date_modified: "2021-05-27T06:54:42Z",
    currency: "gbp",
    product_id: 152,
  },
  "152CHF": {
    price_list_id: 1,
    variant_id: 117,
    price: 390,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 390,
    date_created: "2021-05-27T06:53:38Z",
    date_modified: "2021-05-27T06:53:38Z",
    currency: "chf",
    product_id: 152,
  },
  "153EUR": {
    price_list_id: 1,
    variant_id: 118,
    price: 290,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 290,
    date_created: "2021-05-27T06:54:15Z",
    date_modified: "2021-05-27T06:54:15Z",
    currency: "eur",
    product_id: 153,
  },
  "153CHF": {
    price_list_id: 1,
    variant_id: 118,
    price: 390,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 390,
    date_created: "2021-05-27T06:53:38Z",
    date_modified: "2021-05-27T06:53:38Z",
    currency: "chf",
    product_id: 153,
  },
  "153GBP": {
    price_list_id: 1,
    variant_id: 118,
    price: 254,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 254,
    date_created: "2021-05-27T06:54:42Z",
    date_modified: "2021-05-27T06:54:42Z",
    currency: "gbp",
    product_id: 153,
  },
  "154EUR": {
    price_list_id: 1,
    variant_id: 119,
    price: 290,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 290,
    date_created: "2021-05-27T06:54:15Z",
    date_modified: "2021-05-27T06:54:15Z",
    currency: "eur",
    product_id: 154,
  },
  "154GBP": {
    price_list_id: 1,
    variant_id: 119,
    price: 254,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 254,
    date_created: "2021-05-27T06:54:42Z",
    date_modified: "2021-05-27T06:54:42Z",
    currency: "gbp",
    product_id: 154,
  },
  "154CHF": {
    price_list_id: 1,
    variant_id: 119,
    price: 390,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 390,
    date_created: "2021-05-27T06:53:38Z",
    date_modified: "2021-05-27T06:53:38Z",
    currency: "chf",
    product_id: 154,
  },
  "162GBP": {
    price_list_id: 1,
    variant_id: 127,
    price: 254,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 254,
    date_created: "2021-05-27T06:54:42Z",
    date_modified: "2021-05-27T06:54:42Z",
    currency: "gbp",
    product_id: 162,
  },
  "162EUR": {
    price_list_id: 1,
    variant_id: 127,
    price: 290,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 290,
    date_created: "2021-05-27T06:54:15Z",
    date_modified: "2021-05-27T06:54:15Z",
    currency: "eur",
    product_id: 162,
  },
  "162CHF": {
    price_list_id: 1,
    variant_id: 127,
    price: 390,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 390,
    date_created: "2021-05-27T06:53:38Z",
    date_modified: "2021-05-27T06:53:38Z",
    currency: "chf",
    product_id: 162,
  },
  "163CHF": {
    price_list_id: 1,
    variant_id: 128,
    price: 390,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 390,
    date_created: "2021-05-27T06:53:38Z",
    date_modified: "2021-05-27T06:53:38Z",
    currency: "chf",
    product_id: 163,
  },
  "163EUR": {
    price_list_id: 1,
    variant_id: 128,
    price: 290,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 290,
    date_created: "2021-05-27T06:54:15Z",
    date_modified: "2021-05-27T06:54:15Z",
    currency: "eur",
    product_id: 163,
  },
  "163GBP": {
    price_list_id: 1,
    variant_id: 128,
    price: 254,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 254,
    date_created: "2021-05-27T06:54:42Z",
    date_modified: "2021-05-27T06:54:42Z",
    currency: "gbp",
    product_id: 163,
  },
  "164CHF": {
    price_list_id: 1,
    variant_id: 129,
    price: 390,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 390,
    date_created: "2021-05-27T06:53:38Z",
    date_modified: "2021-05-27T06:53:38Z",
    currency: "chf",
    product_id: 164,
  },
  "164EUR": {
    price_list_id: 1,
    variant_id: 129,
    price: 290,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 290,
    date_created: "2021-05-27T06:54:15Z",
    date_modified: "2021-05-27T06:54:15Z",
    currency: "eur",
    product_id: 164,
  },
  "164GBP": {
    price_list_id: 1,
    variant_id: 129,
    price: 254,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 254,
    date_created: "2021-05-27T06:54:42Z",
    date_modified: "2021-05-27T06:54:42Z",
    currency: "gbp",
    product_id: 164,
  },
  "177CHF": {
    price_list_id: 1,
    variant_id: 142,
    price: 1,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 1,
    date_created: "2021-05-27T06:51:27Z",
    date_modified: "2021-05-27T06:51:27Z",
    currency: "chf",
    product_id: 177,
  },
  "177EUR": {
    price_list_id: 1,
    variant_id: 142,
    price: 0,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 0,
    date_created: "2021-05-27T06:52:15Z",
    date_modified: "2021-05-27T06:52:15Z",
    currency: "eur",
    product_id: 177,
  },
  "177GBP": {
    price_list_id: 1,
    variant_id: 142,
    price: 0,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 0,
    date_created: "2021-05-27T06:52:45Z",
    date_modified: "2021-05-27T06:52:45Z",
    currency: "gbp",
    product_id: 177,
  },
  "178CHF": {
    price_list_id: 1,
    variant_id: 143,
    price: 340,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 340,
    date_created: "2021-05-27T06:58:07Z",
    date_modified: "2021-05-27T06:58:07Z",
    currency: "chf",
    product_id: 178,
  },
  "178GBP": {
    price_list_id: 1,
    variant_id: 143,
    price: 219,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 219,
    date_created: "2021-05-27T06:58:30Z",
    date_modified: "2021-05-27T06:58:30Z",
    currency: "gbp",
    product_id: 178,
  },
  "179CHF": {
    price_list_id: 1,
    variant_id: 144,
    price: 135,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 135,
    date_created: "2021-05-27T06:58:07Z",
    date_modified: "2021-05-27T06:58:07Z",
    currency: "chf",
    product_id: 179,
  },
  "179GBP": {
    price_list_id: 1,
    variant_id: 144,
    price: 25,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 25,
    date_created: "2021-05-27T06:58:30Z",
    date_modified: "2021-05-27T06:58:30Z",
    currency: "gbp",
    product_id: 179,
  },
  "121HKD": {
    price_list_id: 1,
    variant_id: 9999,
    price: 105,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 105,
    date_created: "2021-05-27T06:59:12Z",
    date_modified: "2021-05-27T06:59:12Z",
    currency: "hkd",
    product_id: 121,
  },
  "121AED": {
    price_list_id: 1,
    variant_id: 9999,
    price: 105,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 105,
    date_created: "2021-05-27T06:59:12Z",
    date_modified: "2021-05-27T06:59:12Z",
    currency: "aed",
    product_id: 121,
  },
  "121CHF": {
    price_list_id: 1,
    variant_id: 86,
    price: 160,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 160,
    date_created: "2021-05-27T06:59:29Z",
    date_modified: "2021-05-27T06:59:29Z",
    currency: "chf",
    product_id: 121,
  },
  "121GBP": {
    price_list_id: 1,
    variant_id: 86,
    price: 105,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 105,
    date_created: "2021-05-27T06:59:12Z",
    date_modified: "2021-05-27T06:59:12Z",
    currency: "gbp",
    product_id: 121,
  },
  "121EUR": {
    price_list_id: 1,
    variant_id: 86,
    price: 120,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 120,
    date_created: "2021-05-27T06:59:20Z",
    date_modified: "2021-05-27T06:59:20Z",
    currency: "eur",
    product_id: 121,
  },
  "180GBP": {
    price_list_id: 1,
    variant_id: 145,
    price: 32,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 32,
    date_created: "2021-09-13T11:22:52Z",
    date_modified: "2021-09-13T11:22:52Z",
    currency: "gbp",
    product_id: 180,
  },
  "180EUR": {
    price_list_id: 1,
    variant_id: 145,
    price: 37,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 37,
    date_created: "2021-09-09T16:10:26Z",
    date_modified: "2021-09-09T16:10:26Z",
    currency: "eur",
    product_id: 180,
  },
  "181GBP": {
    price_list_id: 1,
    variant_id: 146,
    price: 42,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 42,
    date_created: "2021-09-13T11:19:47Z",
    date_modified: "2021-09-13T11:19:47Z",
    currency: "gbp",
    product_id: 181,
  },
  "181EUR": {
    price_list_id: 1,
    variant_id: 146,
    price: 48,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 48,
    date_created: "2021-09-14T07:30:06Z",
    date_modified: "2021-09-14T07:30:06Z",
    currency: "eur",
    product_id: 181,
  },
  "182GBP": {
    price_list_id: 1,
    variant_id: 147,
    price: 42,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 42,
    date_created: "2021-09-13T11:19:47Z",
    date_modified: "2021-09-13T11:19:47Z",
    currency: "gbp",
    product_id: 182,
  },
  "182EUR": {
    price_list_id: 1,
    variant_id: 147,
    price: 48,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 48,
    date_created: "2021-09-13T11:23:15Z",
    date_modified: "2021-09-13T11:23:15Z",
    currency: "eur",
    product_id: 182,
  },
  "183GBP": {
    price_list_id: 1,
    variant_id: 148,
    price: 42,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 42,
    date_created: "2021-09-13T11:19:47Z",
    date_modified: "2021-09-13T11:19:47Z",
    currency: "gbp",
    product_id: 183,
  },
  "183EUR": {
    price_list_id: 1,
    variant_id: 148,
    price: 48,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 48,
    date_created: "2021-09-14T07:30:06Z",
    date_modified: "2021-09-14T07:30:06Z",
    currency: "eur",
    product_id: 183,
  },
  "184GBP": {
    price_list_id: 1,
    variant_id: 149,
    price: 42,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 42,
    date_created: "2021-09-13T11:19:47Z",
    date_modified: "2021-09-13T11:19:47Z",
    currency: "gbp",
    product_id: 184,
  },
  "184EUR": {
    price_list_id: 1,
    variant_id: 149,
    price: 48,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 48,
    date_created: "2021-09-14T07:30:06Z",
    date_modified: "2021-09-14T07:30:06Z",
    currency: "eur",
    product_id: 184,
  },
  "185GBP": {
    price_list_id: 1,
    variant_id: 150,
    price: 88,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 88,
    date_created: "2021-09-13T11:22:24Z",
    date_modified: "2021-09-13T11:22:24Z",
    currency: "gbp",
    product_id: 185,
  },
  "185EUR": {
    price_list_id: 1,
    variant_id: 150,
    price: 100,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 100,
    date_created: "2021-09-14T07:30:06Z",
    date_modified: "2021-09-14T07:30:06Z",
    currency: "eur",
    product_id: 185,
  },
  "186GBP": {
    price_list_id: 1,
    variant_id: 151,
    price: 154,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 154,
    date_created: "2021-09-13T11:22:24Z",
    date_modified: "2021-09-13T11:22:24Z",
    currency: "gbp",
    product_id: 186,
  },
  "186EUR": {
    price_list_id: 1,
    variant_id: 151,
    price: 175,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 175,
    date_created: "2021-09-14T07:30:06Z",
    date_modified: "2021-09-14T07:30:06Z",
    currency: "eur",
    product_id: 186,
  },
  "187GBP": {
    price_list_id: 1,
    variant_id: 152,
    price: 154,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 154,
    date_created: "2021-09-13T11:22:24Z",
    date_modified: "2021-09-13T11:22:24Z",
    currency: "gbp",
    product_id: 187,
  },
  "187EUR": {
    price_list_id: 1,
    variant_id: 152,
    price: 175,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 175,
    date_created: "2021-09-14T07:30:06Z",
    date_modified: "2021-09-14T07:30:06Z",
    currency: "eur",
    product_id: 187,
  },
  "188GBP": {
    price_list_id: 1,
    variant_id: 153,
    price: 154,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 154,
    date_created: "2021-09-13T11:22:24Z",
    date_modified: "2021-09-13T11:22:24Z",
    currency: "gbp",
    product_id: 188,
  },
  "188EUR": {
    price_list_id: 1,
    variant_id: 153,
    price: 175,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 175,
    date_created: "2021-09-14T07:30:06Z",
    date_modified: "2021-09-14T07:30:06Z",
    currency: "eur",
    product_id: 188,
  },
  "189GBP": {
    price_list_id: 1,
    variant_id: 154,
    price: 154,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 154,
    date_created: "2021-09-13T11:22:24Z",
    date_modified: "2021-09-13T11:22:24Z",
    currency: "gbp",
    product_id: 189,
  },
  "189EUR": {
    price_list_id: 1,
    variant_id: 154,
    price: 175,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 175,
    date_created: "2021-09-14T07:30:06Z",
    date_modified: "2021-09-14T07:30:06Z",
    currency: "eur",
    product_id: 189,
  },
  "190GBP": {
    price_list_id: 1,
    variant_id: 155,
    price: 154,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 154,
    date_created: "2021-09-13T11:22:24Z",
    date_modified: "2021-09-13T11:22:24Z",
    currency: "gbp",
    product_id: 190,
  },
  "190EUR": {
    price_list_id: 1,
    variant_id: 155,
    price: 175,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 175,
    date_created: "2021-09-14T07:30:06Z",
    date_modified: "2021-09-14T07:30:06Z",
    currency: "eur",
    product_id: 190,
  },
  "191GBP": {
    price_list_id: 1,
    variant_id: 156,
    price: 154,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 154,
    date_created: "2021-09-13T11:22:24Z",
    date_modified: "2021-09-13T11:22:24Z",
    currency: "gbp",
    product_id: 191,
  },
  "191EUR": {
    price_list_id: 1,
    variant_id: 156,
    price: 175,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 175,
    date_created: "2021-09-14T07:30:06Z",
    date_modified: "2021-09-14T07:30:06Z",
    currency: "eur",
    product_id: 191,
  },
  "192GBP": {
    price_list_id: 1,
    variant_id: 157,
    price: 154,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 154,
    date_created: "2021-09-13T11:22:24Z",
    date_modified: "2021-09-13T11:22:24Z",
    currency: "gbp",
    product_id: 192,
  },
  "192EUR": {
    price_list_id: 1,
    variant_id: 157,
    price: 175,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 175,
    date_created: "2021-09-14T07:30:06Z",
    date_modified: "2021-09-14T07:30:06Z",
    currency: "eur",
    product_id: 192,
  },
  "193GBP": {
    price_list_id: 1,
    variant_id: 158,
    price: 154,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 154,
    date_created: "2021-09-13T11:22:24Z",
    date_modified: "2021-09-13T11:22:24Z",
    currency: "gbp",
    product_id: 193,
  },
  "193EUR": {
    price_list_id: 1,
    variant_id: 158,
    price: 175,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 175,
    date_created: "2021-09-14T07:30:06Z",
    date_modified: "2021-09-14T07:30:06Z",
    currency: "eur",
    product_id: 193,
  },
  "194GBP": {
    price_list_id: 1,
    variant_id: 159,
    price: 57,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 57,
    date_created: "2021-09-13T11:22:24Z",
    date_modified: "2021-09-13T11:22:24Z",
    currency: "gbp",
    product_id: 194,
  },
  "194EUR": {
    price_list_id: 1,
    variant_id: 159,
    price: 65,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 65,
    date_created: "2021-09-14T07:30:06Z",
    date_modified: "2021-09-14T07:30:06Z",
    currency: "eur",
    product_id: 194,
  },
  "195GBP": {
    price_list_id: 1,
    variant_id: 160,
    price: 57,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 57,
    date_created: "2021-09-13T11:22:24Z",
    date_modified: "2021-09-13T11:22:24Z",
    currency: "gbp",
    product_id: 195,
  },
  "195EUR": {
    price_list_id: 1,
    variant_id: 160,
    price: 65,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 65,
    date_created: "2021-09-14T07:30:06Z",
    date_modified: "2021-09-14T07:30:06Z",
    currency: "eur",
    product_id: 195,
  },
  "196GBP": {
    price_list_id: 1,
    variant_id: 161,
    price: 79,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 79,
    date_created: "2021-09-13T11:22:24Z",
    date_modified: "2021-09-13T11:22:24Z",
    currency: "gbp",
    product_id: 196,
  },
  "196EUR": {
    price_list_id: 1,
    variant_id: 161,
    price: 90,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 90,
    date_created: "2021-09-14T07:30:06Z",
    date_modified: "2021-09-14T07:30:06Z",
    currency: "eur",
    product_id: 196,
  },
  "197GBP": {
    price_list_id: 1,
    variant_id: 162,
    price: 75,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 75,
    date_created: "2021-09-13T11:22:24Z",
    date_modified: "2021-09-13T11:22:24Z",
    currency: "gbp",
    product_id: 197,
  },
  "197EUR": {
    price_list_id: 1,
    variant_id: 162,
    price: 85,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 85,
    date_created: "2021-09-14T07:30:06Z",
    date_modified: "2021-09-14T07:30:06Z",
    currency: "eur",
    product_id: 197,
  },
  "198GBP": {
    price_list_id: 1,
    variant_id: 163,
    price: 30,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 30,
    date_created: "2021-09-13T11:22:24Z",
    date_modified: "2021-09-13T11:22:24Z",
    currency: "gbp",
    product_id: 198,
  },
  "198EUR": {
    price_list_id: 1,
    variant_id: 163,
    price: 34,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 34,
    date_created: "2021-09-14T07:30:06Z",
    date_modified: "2021-09-14T07:30:06Z",
    currency: "eur",
    product_id: 198,
  },
  "199GBP": {
    price_list_id: 1,
    variant_id: 164,
    price: 30,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 30,
    date_created: "2021-09-13T11:22:24Z",
    date_modified: "2021-09-13T11:22:24Z",
    currency: "gbp",
    product_id: 199,
  },
  "199EUR": {
    price_list_id: 1,
    variant_id: 164,
    price: 34,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 34,
    date_created: "2021-09-14T07:30:06Z",
    date_modified: "2021-09-14T07:30:06Z",
    currency: "eur",
    product_id: 199,
  },
  "200GBP": {
    price_list_id: 1,
    variant_id: 165,
    price: 30,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 30,
    date_created: "2021-09-13T11:22:24Z",
    date_modified: "2021-09-13T11:22:24Z",
    currency: "gbp",
    product_id: 200,
  },
  "200EUR": {
    price_list_id: 1,
    variant_id: 165,
    price: 34,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 34,
    date_created: "2021-09-14T07:30:06Z",
    date_modified: "2021-09-14T07:30:06Z",
    currency: "eur",
    product_id: 200,
  },
  "201GBP": {
    price_list_id: 1,
    variant_id: 166,
    price: 30,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 30,
    date_created: "2021-09-13T11:22:24Z",
    date_modified: "2021-09-13T11:22:24Z",
    currency: "gbp",
    product_id: 201,
  },
  "201EUR": {
    price_list_id: 1,
    variant_id: 166,
    price: 34,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 34,
    date_created: "2021-09-14T07:30:06Z",
    date_modified: "2021-09-14T07:30:06Z",
    currency: "eur",
    product_id: 201,
  },
  "202GBP": {
    price_list_id: 1,
    variant_id: 167,
    price: 30,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 30,
    date_created: "2021-09-13T11:22:24Z",
    date_modified: "2021-09-13T11:22:24Z",
    currency: "gbp",
    product_id: 202,
  },
  "202EUR": {
    price_list_id: 1,
    variant_id: 167,
    price: 34,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 34,
    date_created: "2021-09-14T07:30:06Z",
    date_modified: "2021-09-14T07:30:06Z",
    currency: "eur",
    product_id: 202,
  },
  "203GBP": {
    price_list_id: 1,
    variant_id: 168,
    price: 30,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 30,
    date_created: "2021-09-13T11:22:24Z",
    date_modified: "2021-09-13T11:22:24Z",
    currency: "gbp",
    product_id: 203,
  },
  "203EUR": {
    price_list_id: 1,
    variant_id: 168,
    price: 34,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 34,
    date_created: "2021-09-14T07:30:06Z",
    date_modified: "2021-09-14T07:30:06Z",
    currency: "eur",
    product_id: 203,
  },
  "204GBP": {
    price_list_id: 1,
    variant_id: 169,
    price: 30,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 30,
    date_created: "2021-09-13T11:22:24Z",
    date_modified: "2021-09-13T11:22:24Z",
    currency: "gbp",
    product_id: 204,
  },
  "204EUR": {
    price_list_id: 1,
    variant_id: 169,
    price: 34,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 34,
    date_created: "2021-09-14T07:30:06Z",
    date_modified: "2021-09-14T07:30:06Z",
    currency: "eur",
    product_id: 204,
  },
  "205GBP": {
    price_list_id: 1,
    variant_id: 170,
    price: 30,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 30,
    date_created: "2021-09-13T11:22:24Z",
    date_modified: "2021-09-13T11:22:24Z",
    currency: "gbp",
    product_id: 205,
  },
  "205EUR": {
    price_list_id: 1,
    variant_id: 170,
    price: 34,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 34,
    date_created: "2021-09-14T07:30:06Z",
    date_modified: "2021-09-14T07:30:06Z",
    currency: "eur",
    product_id: 205,
  },
  "206GBP": {
    price_list_id: 1,
    variant_id: 171,
    price: 28,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 28,
    date_created: "2021-09-13T11:22:24Z",
    date_modified: "2021-09-13T11:22:24Z",
    currency: "gbp",
    product_id: 206,
  },
  "206EUR": {
    price_list_id: 1,
    variant_id: 171,
    price: 32,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 32,
    date_created: "2021-09-14T07:30:06Z",
    date_modified: "2021-09-14T07:30:06Z",
    currency: "eur",
    product_id: 206,
  },
  "207GBP": {
    price_list_id: 1,
    variant_id: 172,
    price: 28,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 28,
    date_created: "2021-09-13T11:22:24Z",
    date_modified: "2021-09-13T11:22:24Z",
    currency: "gbp",
    product_id: 207,
  },
  "207EUR": {
    price_list_id: 1,
    variant_id: 173,
    price: 32,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 32,
    date_created: "2021-09-14T07:30:06Z",
    date_modified: "2021-09-14T07:30:06Z",
    currency: "eur",
    product_id: 207,
  },
  "167EUR": {
    price_list_id: 1,
    variant_id: 174,
    price: 130,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 130,
    date_created: "2021-12-14T07:30:06Z",
    date_modified: "2021-12-14T07:30:06Z",
    currency: "eur",
    product_id: 167,
  },
  "167GBP": {
    price_list_id: 1,
    variant_id: 175,
    price: 114,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 114,
    date_created: "2021-12-13T11:22:24Z",
    date_modified: "2021-12-13T11:22:24Z",
    currency: "gbp",
    product_id: 167,
  },
  "167CHF": {
    price_list_id: 1,
    variant_id: 176,
    price: 175,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 175,
    date_created: "2021-12-14T07:30:06Z",
    date_modified: "2021-12-14T07:30:06Z",
    currency: "chf",
    product_id: 167,
  },
  "169EUR": {
    price_list_id: 1,
    variant_id: 177,
    price: 0,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 0,
    date_created: "2021-12-14T07:30:06Z",
    date_modified: "2021-12-14T07:30:06Z",
    currency: "eur",
    product_id: 169,
  },
  "169GBP": {
    price_list_id: 1,
    variant_id: 178,
    price: 0,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 0,
    date_created: "2021-12-13T11:22:24Z",
    date_modified: "2021-12-13T11:22:24Z",
    currency: "gbp",
    product_id: 169,
  },
  "169CHF": {
    price_list_id: 1,
    variant_id: 179,
    price: 1,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 1,
    date_created: "2021-12-14T07:30:06Z",
    date_modified: "2021-12-14T07:30:06Z",
    currency: "chf",
    product_id: 169,
  },
  "170EUR": {
    price_list_id: 1,
    variant_id: 180,
    price: 0,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 0,
    date_created: "2021-12-14T07:30:06Z",
    date_modified: "2021-12-14T07:30:06Z",
    currency: "eur",
    product_id: 170,
  },
  "170GBP": {
    price_list_id: 1,
    variant_id: 181,
    price: 0,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 0,
    date_created: "2021-12-13T11:22:24Z",
    date_modified: "2021-12-13T11:22:24Z",
    currency: "gbp",
    product_id: 170,
  },
  "170CHF": {
    price_list_id: 1,
    variant_id: 182,
    price: 1,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 1,
    date_created: "2021-12-14T07:30:06Z",
    date_modified: "2021-12-14T07:30:06Z",
    currency: "chf",
    product_id: 170,
  },
  "171EUR": {
    price_list_id: 1,
    variant_id: 183,
    price: 0,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 0,
    date_created: "2021-12-14T07:30:06Z",
    date_modified: "2021-12-14T07:30:06Z",
    currency: "eur",
    product_id: 171,
  },
  "171GBP": {
    price_list_id: 1,
    variant_id: 184,
    price: 0,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 0,
    date_created: "2021-12-13T11:22:24Z",
    date_modified: "2021-12-13T11:22:24Z",
    currency: "gbp",
    product_id: 171,
  },
  "171CHF": {
    price_list_id: 1,
    variant_id: 185,
    price: 1,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 1,
    date_created: "2021-12-14T07:30:06Z",
    date_modified: "2021-12-14T07:30:06Z",
    currency: "chf",
    product_id: 171,
  },
  "172EUR": {
    price_list_id: 1,
    variant_id: 186,
    price: 0,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 0,
    date_created: "2021-12-14T07:30:06Z",
    date_modified: "2021-12-14T07:30:06Z",
    currency: "eur",
    product_id: 172,
  },
  "172GBP": {
    price_list_id: 1,
    variant_id: 187,
    price: 0,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 0,
    date_created: "2021-12-13T11:22:24Z",
    date_modified: "2021-12-13T11:22:24Z",
    currency: "gbp",
    product_id: 172,
  },
  "172CHF": {
    price_list_id: 1,
    variant_id: 188,
    price: 1,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 1,
    date_created: "2021-12-14T07:30:06Z",
    date_modified: "2021-12-14T07:30:06Z",
    currency: "chf",
    product_id: 172,
  },
  "173EUR": {
    price_list_id: 1,
    variant_id: 189,
    price: 0,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 0,
    date_created: "2021-12-14T07:30:06Z",
    date_modified: "2021-12-14T07:30:06Z",
    currency: "eur",
    product_id: 173,
  },
  "173GBP": {
    price_list_id: 1,
    variant_id: 190,
    price: 0,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 0,
    date_created: "2021-12-13T11:22:24Z",
    date_modified: "2021-12-13T11:22:24Z",
    currency: "gbp",
    product_id: 173,
  },
  "173CHF": {
    price_list_id: 1,
    variant_id: 191,
    price: 1,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 1,
    date_created: "2021-12-14T07:30:06Z",
    date_modified: "2021-12-14T07:30:06Z",
    currency: "chf",
    product_id: 173,
  },
  "174EUR": {
    price_list_id: 1,
    variant_id: 192,
    price: 0,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 0,
    date_created: "2021-12-14T07:30:06Z",
    date_modified: "2021-12-14T07:30:06Z",
    currency: "eur",
    product_id: 174,
  },
  "174GBP": {
    price_list_id: 1,
    variant_id: 193,
    price: 0,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 0,
    date_created: "2021-12-13T11:22:24Z",
    date_modified: "2021-12-13T11:22:24Z",
    currency: "gbp",
    product_id: 174,
  },
  "174CHF": {
    price_list_id: 1,
    variant_id: 194,
    price: 1,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 1,
    date_created: "2021-12-14T07:30:06Z",
    date_modified: "2021-12-14T07:30:06Z",
    currency: "chf",
    product_id: 174,
  },
  "175EUR": {
    price_list_id: 1,
    variant_id: 195,
    price: 0,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 0,
    date_created: "2021-12-14T07:30:06Z",
    date_modified: "2021-12-14T07:30:06Z",
    currency: "eur",
    product_id: 175,
  },
  "175GBP": {
    price_list_id: 1,
    variant_id: 196,
    price: 0,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 0,
    date_created: "2021-12-13T11:22:24Z",
    date_modified: "2021-12-13T11:22:24Z",
    currency: "gbp",
    product_id: 175,
  },
  "175CHF": {
    price_list_id: 1,
    variant_id: 197,
    price: 1,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 1,
    date_created: "2021-12-14T07:30:06Z",
    date_modified: "2021-12-14T07:30:06Z",
    currency: "chf",
    product_id: 175,
  },
  "176EUR": {
    price_list_id: 1,
    variant_id: 198,
    price: 0,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 0,
    date_created: "2021-12-14T07:30:06Z",
    date_modified: "2021-12-14T07:30:06Z",
    currency: "eur",
    product_id: 176,
  },
  "176GBP": {
    price_list_id: 1,
    variant_id: 199,
    price: 0,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 0,
    date_created: "2021-12-13T11:22:24Z",
    date_modified: "2021-12-13T11:22:24Z",
    currency: "gbp",
    product_id: 176,
  },
  "176CHF": {
    price_list_id: 1,
    variant_id: 200,
    price: 1,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 1,
    date_created: "2021-12-14T07:30:06Z",
    date_modified: "2021-12-14T07:30:06Z",
    currency: "chf",
    product_id: 176,
  },
  "208EUR": {
    price_list_id: 1,
    variant_id: 201,
    price: 97,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 97,
    date_created: "2021-12-14T07:30:06Z",
    date_modified: "2021-12-14T07:30:06Z",
    currency: "eur",
    product_id: 208,
  },
  "208GBP": {
    price_list_id: 1,
    variant_id: 202,
    price: 105,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 105,
    date_created: "2021-12-13T11:22:24Z",
    date_modified: "2021-12-13T11:22:24Z",
    currency: "gbp",
    product_id: 208,
  },
  "208CHF": {
    price_list_id: 1,
    variant_id: 203,
    price: 135,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 135,
    date_created: "2021-12-14T07:30:06Z",
    date_modified: "2021-12-14T07:30:06Z",
    currency: "chf",
    product_id: 208,
  },
  "209EUR": {
    price_list_id: 1,
    variant_id: 204,
    price: 142,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 142,
    date_created: "2021-12-14T07:30:06Z",
    date_modified: "2021-12-14T07:30:06Z",
    currency: "eur",
    product_id: 209,
  },
  "209GBP": {
    price_list_id: 1,
    variant_id: 205,
    price: 150,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 150,
    date_created: "2021-12-13T11:22:24Z",
    date_modified: "2021-12-13T11:22:24Z",
    currency: "gbp",
    product_id: 209,
  },
  "209CHF": {
    price_list_id: 1,
    variant_id: 206,
    price: 195,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 195,
    date_created: "2021-12-14T07:30:06Z",
    date_modified: "2021-12-14T07:30:06Z",
    currency: "chf",
    product_id: 209,
  },
  "210EUR": {
    price_list_id: 1,
    variant_id: 207,
    price: 0,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 0,
    date_created: "2021-12-14T07:30:06Z",
    date_modified: "2021-12-14T07:30:06Z",
    currency: "eur",
    product_id: 210,
  },
  "210GBP": {
    price_list_id: 1,
    variant_id: 208,
    price: 0,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 0,
    date_created: "2021-12-13T11:22:24Z",
    date_modified: "2021-12-13T11:22:24Z",
    currency: "gbp",
    product_id: 210,
  },
  "210CHF": {
    price_list_id: 1,
    variant_id: 209,
    price: 0,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 0,
    date_created: "2021-12-14T07:30:06Z",
    date_modified: "2021-12-14T07:30:06Z",
    currency: "chf",
    product_id: 210,
  },
  "207CHF": {
    price_list_id: 1,
    variant_id: 210,
    price: 42,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 42,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 207,
  },
  "206CHF": {
    price_list_id: 1,
    variant_id: 211,
    price: 42,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 42,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 206,
  },
  "205CHF": {
    price_list_id: 1,
    variant_id: 212,
    price: 45,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 45,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 205,
  },
  "204CHF": {
    price_list_id: 1,
    variant_id: 213,
    price: 45,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 45,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 204,
  },
  "203CHF": {
    price_list_id: 1,
    variant_id: 214,
    price: 45,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 45,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 203,
  },
  "202CHF": {
    price_list_id: 1,
    variant_id: 215,
    price: 45,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 45,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 202,
  },
  "201CHF": {
    price_list_id: 1,
    variant_id: 216,
    price: 45,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 45,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 201,
  },
  "200CHF": {
    price_list_id: 1,
    variant_id: 217,
    price: 45,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 45,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 200,
  },
  "199CHF": {
    price_list_id: 1,
    variant_id: 218,
    price: 45,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 45,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 199,
  },
  "198CHF": {
    price_list_id: 1,
    variant_id: 219,
    price: 45,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 45,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 198,
  },
  "197CHF": {
    price_list_id: 1,
    variant_id: 220,
    price: 115,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 115,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 197,
  },
  "196CHF": {
    price_list_id: 1,
    variant_id: 221,
    price: 120,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 120,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 196,
  },
  "195CHF": {
    price_list_id: 1,
    variant_id: 222,
    price: 90,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 90,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 195,
  },
  "194CHF": {
    price_list_id: 1,
    variant_id: 223,
    price: 90,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 90,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 194,
  },
  "193CHF": {
    price_list_id: 1,
    variant_id: 224,
    price: 236,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 236,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 193,
  },
  "192CHF": {
    price_list_id: 1,
    variant_id: 225,
    price: 236,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 236,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 192,
  },
  "191CHF": {
    price_list_id: 1,
    variant_id: 226,
    price: 236,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 236,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 191,
  },
  "190CHF": {
    price_list_id: 1,
    variant_id: 227,
    price: 236,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 236,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 190,
  },
  "189CHF": {
    price_list_id: 1,
    variant_id: 228,
    price: 236,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 236,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 189,
  },
  "188CHF": {
    price_list_id: 1,
    variant_id: 229,
    price: 236,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 236,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 188,
  },
  "187CHF": {
    price_list_id: 1,
    variant_id: 230,
    price: 236,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 236,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 187,
  },
  "186CHF": {
    price_list_id: 1,
    variant_id: 231,
    price: 236,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 236,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 186,
  },
  "185CHF": {
    price_list_id: 1,
    variant_id: 232,
    price: 137,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 137,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 185,
  },
  "184CHF": {
    price_list_id: 1,
    variant_id: 233,
    price: 62,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 62,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 184,
  },
  "183CHF": {
    price_list_id: 1,
    variant_id: 234,
    price: 62,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 62,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 183,
  },
  "182CHF": {
    price_list_id: 1,
    variant_id: 235,
    price: 62,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 62,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 182,
  },
  "181CHF": {
    price_list_id: 1,
    variant_id: 236,
    price: 62,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 62,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 181,
  },
  "180CHF": {
    price_list_id: 1,
    variant_id: 237,
    price: 48,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 48,
    date_created: "2022-1-13T07:30:06Z",
    date_modified: "2022-1-13T07:30:06Z",
    currency: "chf",
    product_id: 180,
  },



  "218EUR": {
    price_list_id: 1,
    variant_id: 238,
    price: 54,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 54,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "eur",
    product_id: 218,
  },
  "218GBP": {
    price_list_id: 1,
    variant_id: 239,
    price: 47,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 47,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "gbp",
    product_id: 218,
  },
  "218CHF": {
    price_list_id: 1,
    variant_id: 240,
    price: 70,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 70,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "chf",
    product_id: 218,
  },


  
  "217EUR": {
    price_list_id: 1,
    variant_id: 241,
    price: 34,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 34,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "eur",
    product_id: 217,
  },
  "217GBP": {
    price_list_id: 1,
    variant_id: 242,
    price: 30,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 30,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "gbp",
    product_id: 217,
  },
  "217CHF": {
    price_list_id: 1,
    variant_id: 243,
    price: 45,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 45,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "chf",
    product_id: 217,
  },


  
  "216EUR": {
    price_list_id: 1,
    variant_id: 244,
    price: 54,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 54,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "eur",
    product_id: 216,
  },
  "216GBP": {
    price_list_id: 1,
    variant_id: 245,
    price: 47,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 47,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "gbp",
    product_id: 216,
  },
  "216CHF": {
    price_list_id: 1,
    variant_id: 246,
    price: 70,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 70,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "chf",
    product_id: 216,
  },


  
  "215EUR": {
    price_list_id: 1,
    variant_id: 247,
    price: 34,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 34,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "eur",
    product_id: 215,
  },
  "215GBP": {
    price_list_id: 1,
    variant_id: 248,
    price: 30,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 30,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "gbp",
    product_id: 215,
  },
  "215CHF": {
    price_list_id: 1,
    variant_id: 249,
    price: 45,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 45,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "chf",
    product_id: 215,
  },


  
  "214EUR": {
    price_list_id: 1,
    variant_id: 250,
    price: 54,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 54,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "eur",
    product_id: 214,
  },
  "214GBP": {
    price_list_id: 1,
    variant_id: 251,
    price: 47,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 47,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "gbp",
    product_id: 214,
  },
  "214CHF": {
    price_list_id: 1,
    variant_id: 252,
    price: 70,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 70,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "chf",
    product_id: 214,
  },


  
  "213EUR": {
    price_list_id: 1,
    variant_id: 253,
    price: 34,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 34,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "eur",
    product_id: 213,
  },
  "213GBP": {
    price_list_id: 1,
    variant_id: 254,
    price: 30,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 30,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "gbp",
    product_id: 213,
  },
  "213CHF": {
    price_list_id: 1,
    variant_id: 255,
    price: 45,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 45,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "chf",
    product_id: 213,
  },


  
  "212EUR": {
    price_list_id: 1,
    variant_id: 256,
    price: 54,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 54,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "eur",
    product_id: 212,
  },
  "212GBP": {
    price_list_id: 1,
    variant_id: 257,
    price: 47,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 47,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "gbp",
    product_id: 212,
  },
  "212CHF": {
    price_list_id: 1,
    variant_id: 258,
    price: 70,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 70,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "chf",
    product_id: 212,
  },


  
  "211EUR": {
    price_list_id: 1,
    variant_id: 259,
    price: 34,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 34,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "eur",
    product_id: 211,
  },
  "211GBP": {
    price_list_id: 1,
    variant_id: 260,
    price: 30,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 30,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "gbp",
    product_id: 211,
  },
  "211CHF": {
    price_list_id: 1,
    variant_id: 261,
    price: 45,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 45,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "chf",
    product_id: 211,
  },


  
  "222EUR": {
    price_list_id: 1,
    variant_id: 262,
    price: 52,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 52,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "eur",
    product_id: 222,
  },
  "222GBP": {
    price_list_id: 1,
    variant_id: 263,
    price: 45,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 45,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "gbp",
    product_id: 222,
  },
  "222CHF": {
    price_list_id: 1,
    variant_id: 264,
    price: 67,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 67,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "chf",
    product_id: 222,
  },


  
  "221EUR": {
    price_list_id: 1,
    variant_id: 265,
    price: 32,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 32,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "eur",
    product_id: 221,
  },
  "221GBP": {
    price_list_id: 1,
    variant_id: 266,
    price: 30,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 30,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "gbp",
    product_id: 221,
  },
  "221CHF": {
    price_list_id: 1,
    variant_id: 267,
    price: 45,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 45,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "chf",
    product_id: 221,
  },


  
  "220EUR": {
    price_list_id: 1,
    variant_id: 268,
    price: 52,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 52,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "eur",
    product_id: 220,
  },
  "220GBP": {
    price_list_id: 1,
    variant_id: 269,
    price: 45,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 45,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "gbp",
    product_id: 220,
  },
  "220CHF": {
    price_list_id: 1,
    variant_id: 270,
    price: 67,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 67,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "chf",
    product_id: 220,
  },


  
  "219EUR": {
    price_list_id: 1,
    variant_id: 271,
    price: 32,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 32,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "eur",
    product_id: 219,
  },
  "219GBP": {
    price_list_id: 1,
    variant_id: 272,
    price: 30,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 30,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "gbp",
    product_id: 219,
  },
  "219CHF": {
    price_list_id: 1,
    variant_id: 273,
    price: 45,
    sale_price: null,
    retail_price: null,
    map_price: null,
    calculated_price: 45,
    date_created: "2022-04-27:30:06Z",
    date_modified: "2022-04-27:30:06Z",
    currency: "chf",
    product_id: 219,
  },
 
 

  
};
