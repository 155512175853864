import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { mediaQueries } from "../../theme";

import { AccountLayout } from "../../components/AccountLayout";
import { Layout } from "../../components/Layout";
import { ListPlain } from "../../components/ListPlain";
import { OrderItem } from "../../components/OrderItem";
import { SEO } from "../../components/SEO";
import { Banner } from "../../components/Banner";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import clsx from "clsx";
import { getOrders, dummyOrderList } from "../../utils/orders_dataService";
import { navigate } from "gatsby";
import SkeletonProduct from "../../components/SkeletonProduct";

const OrdersColumn = styled.section`
  // h1 {
  //   text-transform: capitalize;
  //   margin: 0;
  //   font-weight: lighter;
  //   font-size: var(--font-size-xl, 24px);
  // }

  .account-title {
    margin: 0 0 40px 0;
    font-size: 32px;
  }

  .no-orders {
    font-size: 18px;
    font-weight: 600;
    align-self: center;
    justify-self: left;
  }

  .current-orders {
    h2 {
      font-size: ${themeGet("fontSizes.6")}px;

      ${mediaQueries.md} {
        font-size: 24px;
      }
    }
    display: grid;
    grid-auto-flow: row;
    gap: ${themeGet("space.6")}px;
    padding-block-end: 50px;
  }
  .previous-orders {
    h2 {
      font-size: ${themeGet("fontSizes.6")}px;

      ${mediaQueries.md} {
        font-size: 24px;
      }
    }
    display: grid;
    grid-auto-flow: row;
    gap: ${themeGet("space.6")}px;
    border-top: ${themeGet("border.width")} solid ${themeGet("border.color")};
    padding-block-start: 50px;
  }

  .grid-item {
    display: grid;
    grid-auto-flow: row;
    gap: 50px;
  }
`;

const BannerSection = styled.div`
  .banner-section {
    padding: 0;
    margin-bottom: 40px;

    .accounts-imagebanner {
      aspect-ratio: unset;

      .image-overlay-gradient {
        background: linear-gradient(
          180deg,
          rgba(102, 95, 107, 0) 0%,
          #36313980 100%
        );
      }
    }

    .accounts-promobanner {
      aspect-ratio: unset;
      max-width: 1266px;
      margin: 70px auto;

      .image-overlay-gradient {
        background: linear-gradient(
          180deg,
          rgba(102, 95, 107, 0) 0%,
          #36313980 100%
        );
      }
    }
  }
`;

const OrdersSection = styled.section`
  .isLoading {
    display: none;
  }
`;

const AccountLayoutContainer = styled.div`
  max-width: 1266px;
  margin: auto;
`;

const OrdersPage: React.FC = () => {
  const isMobile = useMediaQuery(mediaQueries.qmd);
  const [isWithData, setWithData] = useState(false);

  const [current, setCurrent]: any[] = useState([]);
  const [previous, setPrevious]: any[] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getOrders()
      .then(ordersData => {

          setCurrent([]);
          setPrevious([]);

          if (ordersData.status.toLowerCase() !== "failed" && ordersData && ordersData.data && ordersData.data.length > 0 ) {
            setWithData(true);

            ordersData.data.map(item => {
              if (
                item.status.toLowerCase() !== "shipped" &&
                item.status.toLowerCase() !== "completed"
              ) {
                setCurrent(_current => [..._current, item]);
              } else {
                setPrevious(_previous => [..._previous, item]);
              }
            });
          } else {
            setWithData(false);
          }
        // }

        setIsLoading(false);
      })
      .catch(err => {
        console.log("error: ", err);
      });
  }, []);

  return (
    <Layout>

      <AccountLayoutContainer>
        <AccountLayout>
          <SEO title="My Orders" />
          <OrdersColumn>
          <h1 className="account-title">Orders</h1>
            <div className="current-orders">
              <h2>Current orders</h2>
              <ListPlain className="grid-item">
                {!isLoading ? (
                  current && current.length > 0 ? (
                    current.map((item, index) => (
                      <li key={index}>
                        <OrderItem current={item} {...item} />
                      </li>
                    ))
                  ) : (
                    <li>
                      <span>No current orders</span>
                    </li>
                  )
                ) : (
                  [1, 2].map(loading => (
                    <li key={loading}>
                      <SkeletonProduct />
                    </li>
                  ))
                )}
              </ListPlain>
            </div>
            <div className="previous-orders">
              <h2>Previous Orders</h2>
              <ListPlain className="grid-item">
                {!isLoading ? (
                  previous && previous.length > 0 ? (
                    previous.map((item, index) => (
                      <li key={index}>
                        <OrderItem previous={item} {...item} />
                      </li>
                    ))
                  ) : (
                    <li>
                      <span>No previous orders</span>
                    </li>
                  )
                ) : (
                  [1, 2].map(loading => (
                    <li key={loading}>
                      <SkeletonProduct />
                    </li>
                  ))
                )}
              </ListPlain>
            </div>
          </OrdersColumn>
        </AccountLayout>
      </AccountLayoutContainer>

      {isWithData && (
        <BannerSection>
          <section
            className={clsx(
              "banner-section",
              `${isMobile ? "mobile-container" : "container"}`
            )}
          >
            <Banner
              style={{ height: "543px" }}
              backgroundPosition="center center"
              className={clsx("banner accounts-promobanner fade")}
              image={{
                alt: "Lingerie",
                src:
                  "https://eu-images.contentstack.com/v3/assets/blt2bb45aeece463821/blt4b73cf46739544c8/60ae87c97ab51d2fcfc770dd/Lingerie.jpg?quality=80",
              }}
              isMobile={isMobile}
              justifyItems="left"
              layout="overlay"
              title="Looking for lingerie orders?"
              iosHeight={true}
              tag="VISIT LA PERLA LINGERIE"
              link={{ href: "https://www.laperla.com" }}
              isAnimated={false}
            />
          </section>
        </BannerSection>
      )}
    </Layout>
  );
};

export default OrdersPage;
