import { themeGet } from "@styled-system/theme-get";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { ReactComponent as Profile } from "../../static/icons/Profile.svg";
import { ReactComponent as Newsletter } from "../../static/icons/Newsletter.svg";
import { ReactComponent as Shipping } from "../../static/icons/Shipping.svg";
import { ReactComponent as Return } from "../../static/icons/Return.svg";
import { ReactComponent as Address } from "../../static/icons/Address.svg";
import { ReactComponent as WishlistAbsent } from "../../static/icons/WishlistAbsent.svg";


import { Link } from "../components/Button";

import { myAccount as myAccountPath } from "../utils/paths";

import { mediaQueries } from "../theme";

import { ListPlain } from "./ListPlain";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { ImgOptimized } from "./ImgOptimized";

const MainStyled = styled.main`
  display: grid;
  gap: 1rem;
  grid-auto-flow: row;
  max-width: 1266px;
  margin: 70px auto;

  ${mediaQueries.md} {
    align-items: flex-start;
    gap: 123px;
    grid-auto-flow: column;
    grid-template-columns: minmax(200px, 300px) auto;
    margin-block-end: 70px;
    margin-block-start: 70px;
  }

  .section-title {
    font-family: "Quicksand", sans-serif;
    font-size: ${themeGet("fontSizes.2")}px;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;

    ${mediaQueries.md} {
      display: none;
    }
  }
`;

const AccountNav = styled(ListPlain)`
  border-color: ${themeGet("colors.black")};
  border-radius: ${themeGet("radii.4")}px;
  border-style: solid;
  display: grid;
  gap: 1.5rem;
  grid-auto-flow: row;
  justify-items: flex-start;
  padding: 1rem;
  place-content: start;
  display: none;

  ${mediaQueries.md} {
    align-content: flex-start;
    align-items: flex-start;
    border: none;
    place-content: unset;
    display: grid;
  }

  li {
    ${mediaQueries.md} {
      width: 100%;
    }

    a {
      align-items: center;
      display: grid;
      gap: 1rem;
      grid-auto-flow: column;
      text-transform: uppercase;

      ${mediaQueries.md} {
        border-bottom-style: solid;
        justify-content: start;
        padding-block-end: 12px;

        &.active {
          border-bottom-color: ${themeGet("colors.black")};
          font-weight: bold;
        }
      }

      svg {
        height: auto;
        width: 15px;
      }
    }
  }
`;

const AccountNavMobile = styled.div`
display: block;
${mediaQueries.md} {
  display: none;
}

.accounts-select {
  padding: 15px;
  margin-bottom: 14px;
  text-transform: uppercase;
  color: #363139;
  font-family: "Quicksand";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 17px;

  option {
    text-transform: uppercase;
    color: #363139;
    font-family: "Quicksand";
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 17px;
  }
}


`;

export const AccountLayout = ({ children }) => {
  const isMobile = useMediaQuery(mediaQueries.qmd);
  const [selectedNavValue, setSelectedNavValue] = useState('');

  useEffect(() => {
    window.location.pathname ==
      document.querySelector("a[href='" + window.location.pathname + "']")
        ?.pathname &&
      document
        .querySelector("a[href='" + window.location.pathname + "']")
        ?.classList.add("active");

    window.location.pathname.includes(selectedNavValue) && setSelectedNavValue(window.location.pathname);
  }, []);

  const handleNavChange = (e) => {
    setSelectedNavValue(e.target.value);
    window.location = e.target.value;
  }

  return (
    <MainStyled className="container">
      <h1 className="section-title">Navigate to section</h1>
      <AccountNav>
        <li>
          <Link to={`${myAccountPath}/information`}>
            <Profile />
            Account information
          </Link>
        </li>
        <li>
          <Link to={`${myAccountPath}/orders`}>
            <Shipping />
            Orders
          </Link>
        </li>
        {/* <li>
        <Link to={`${myAccountPath}/returns`}>
          <Return />
          Returns
        </Link>
      </li>*/}
      <li>
        <Link to={`${myAccountPath}/address-book`}>
          <Address />
          Address book
        </Link>
      </li>
      <li>
        <Link to={`${myAccountPath}/wishlist`}>
          <WishlistAbsent />
          Wishlist
        </Link>
      </li>
      {/* <li>
        <Link to={`${myAccountPath}/newsletter-preferences`}>
          <Newsletter />
          Newsletter preferences
        </Link>
      </li>  */}
      </AccountNav>

      <AccountNavMobile>
        <select className="accounts-select" value={selectedNavValue} onChange={handleNavChange}>
          <option value={`${myAccountPath}/information`}>
            Account information
          </option>
          <option value={`${myAccountPath}/orders`}>
            Orders
          </option>
          <option value={`${myAccountPath}/address-book`}>
            Address Book
          </option>
          <option value={`${myAccountPath}/wishlist`}>
            Wishlist
          </option>
        </select>
      </AccountNavMobile>

      {children}
    </MainStyled>
  );
};
