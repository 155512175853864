import type { ModularBlocksTypes } from "../../types/ModularBlocks"

import type { Image as ImageProp } from "../../types/components"

import clsx from "clsx"
import React from "react"
import styled, { css } from "styled-components"

import { ModularBlocks } from "../components/ModularBlocks"

import { mediaQueries } from "../theme"

export type Section = {
  background_attachment: string
  background_colour: string
  background_image: ImageProp[]
  background_position: string
  background_repeat: string
  background_size: string
  container: "compact" | "full" | "normal"
  id: string
  modular_blocks: ModularBlocksTypes[]
  title: string
}

export type PageSectionsProps = {
  sections: Section[]
}

const SectionStyled = styled.section`
  ${props => props.section?.background_attachment ? css`
  background-attachment: ${props.section?.background_attachment};
  ` : ""}

  ${props => props.section?.background_colour ? css`
  background-color: ${props.section?.background_colour};
  ` : ""}

  ${props => props.section?.background_image ? css`
  ${mediaQueries.md} {
    background-image: ${props.section?.background_image.map(image => `url("${image?.src}")`)};
  }  
  ` : ""}

  ${props => props.section?.background_position ? css`
  background-position: ${props.section?.background_position};
  ` : ""}

  ${props => props.section?.background_repeat ? css`
  background-repeat: ${props.section?.background_repeat};
  ` : ""}

  ${props => props.section?.background_size ? css`
  background-size: ${props.section?.background_size};
  ` : ""}
`

export const PageSections: React.FC<PageSectionsProps> = props => (
  <React.Fragment>
    {props.sections?.map(section => {
      return (
        <SectionStyled
          className={clsx(section?.container === "normal" && "container", section?.container === "compact" && "container-compact")}
          id={section?.id}
          key={section?.id}
          section={section}
        >
          <ModularBlocks modularBlocks={section?.modular_blocks} />
        </SectionStyled>
      )
    })}
  </React.Fragment>
)