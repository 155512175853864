import {themeGet} from "@styled-system/theme-get";
import React, {useState} from "react";
import styled from "styled-components";
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system";
import {mediaQueries} from "../theme";
import {ListPlain} from "./ListPlain";
import Moment from "moment";
import {Accordion} from "./Accordion";
// import {useToggle} from "../hooks/useToggle";
// import {Button, Link} from "./Button";

export type OrderItemProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps & {
  current: string;
  previous: string;
};

const OrderItemStyled = styled.article`
  display: grid;
  gap: 2.25rem;
  grid-template-columns: 1fr;
  place-content: start;
  column-gap: 0;

  ${mediaQueries.md} {
    grid-auto-flow: column;
    // grid-template-columns: 1fr 1fr;
    column-gap: unset;
  }

  a {
    cursor: pointer;
  }

  img {
    width: 100%;
    object-fit: cover;
  }

  .items {
    display: grid;
    gap: 1rem;
    grid-auto-flow: row;
    grid-column: span 2;
    margin-top: 22px;

    ${mediaQueries.md} {
      grid-column: span 1;

      // place-content: start;
      // padding-left: 3rem;
    }

    .track-order {
      font-family: "Quicksand", serif;
      font-size: 14px;
      font-weight: bold;
      background-color: #efeee9;
      color: #858585;
      cursor: default;
    }
  }

  .view-desktop {
    display: none;
    font-weight: bold;
    text-transform: uppercase;

    ${mediaQueries.md} {
      display: block;
    }
  }

  .order-products-accordion {
    grid-column: span 2;
    margin-top: 14px;

    .accordion__item {
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    }

    .order-products {
      display: grid;
      gap: 8px;
      grid-auto-flow: row;
      grid-template-columns: 1fr;
      padding-top: 9px;
      font-weight: 600;
      justify-content: left;
      text-align: left;
      text-transform: uppercase;
      font-family: "Quicksand", serif;
      font-size: 11px;

      li {
        text-transform: uppercase;
        color: #363139;
        font-family: "Quicksand", serif;
        font-size: 11px;
        font-weight: 600;
        letter-spacing: 0.7px;
        line-height: 15px;
        padding: 0;
        // margin-bottom: 0;

        span {
          float: right;
          font-weight: normal;
        }
      }
    }
  }
`;
const SecondColumnStyled = styled(ListPlain)`
  display: grid;
  gap: 12px;
  grid-auto-flow: row;
  grid-template-columns: 1fr;

  justify-content: left;
  text-align: left;

  li {
    text-transform: uppercase;
    color: #363139;
    font-family: "Quicksand", serif;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.7px;
    line-height: 15px;

    span {
      float: right;
      font-weight: normal;
    }

  }


  .order-name {
    font-weight: normal;
    font-size: 11px;
    letter-spacing: 0.7px;
    line-height: 15px;
    text-transform: uppercase;
  }

  .order-number {
    font-family: "Tiempos", serif;
    font-size: 16px;
    font-weight: lighter;
    margin-left: 15px;
  }

  .price {
    font-family: "Tiempos", serif;
    font-size: 14px;
    font-weight: lighter;
  }

  .order-history {
    display: grid;
    gap: 8px;
    grid-auto-flow: row;

    li {
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;

      span {
        font-weight: normal;
      }
    }
  }


  li {
    .view-mobile {
      font-size: ${themeGet("fontSizes.2")}px;
      font-weight: bold;
      text-transform: uppercase;

      ${mediaQueries.md} {
        display: none;
      }
    }

    .order-products {
      list-style: none;
      margin-block-end: unset;
      margin-block-start: unset;
      padding-inline-start: unset;
      // margin: 1rem 0 0 1.25rem;

      li {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: .5rem;

        &:last-child {
          margin-bottom: 0;
        }

        span {
          font-weight: normal;
        }
      }

    }

  }
}

${compose(color, flexbox, grid, layout, position, space)}
`;

export const OrderItem: React.FC<OrderItemProps> = props => {
  // const [viewOrders, toggleViewOrders] = useToggle();
  const [accordionTitle, setAccordionTitle] = useState("View Order Details");

  return (
    <OrderItemStyled>
      <SecondColumnStyled>
        <li className="order-name">
          order <strong className="order-number">#{props.id}</strong>{" "}
          <span className="price">
            {props?.currency && props?.locale &&
              new Intl.NumberFormat(props?.locale, {
                style: "currency",
                currency: props?.currency,
              }).format(props?.total_tax as number)}
          </span>
        </li>

        <li>
          order date:{" "}
          <span>{Moment(props.date_created).format("MM/DD/YYYY")}</span>
        </li>
        <li>
          last update:{" "}
          <span>{Moment(props.date_modified).format("MM/DD/YYYY")}</span>
        </li>
        <li>
          status: <span>{props.status}</span>
        </li>

        <li>
          <Accordion
            className="order-products-accordion"
            onClick={() => {
              accordionTitle == "View Order Details"
                ? setAccordionTitle("Hide Order Details")
                : setAccordionTitle("View Order Details");
            }}
            items={[
              {
                title: accordionTitle,
                panel: (
                  <ul className="order-products">
                    {props.products.map((x, ind) => {
                      return (
                        <React.Fragment key={ind}>
                          <li>Order Details:</li>
                          <li>
                            Product Name: <span>{x.name}</span>
                          </li>
                          <li>
                            Qty: <span>{x.quantity}</span>
                          </li>
                          <li>
                            Total:{" "}
                            <span>
                              {" "}
                              {props?.locale && new Intl.NumberFormat(props?.locale, {
                                style: "currency",
                                currency: props?.currency,
                              }).format(x.total_inc_tax as number)}
                            </span>
                          </li>
                        </React.Fragment>
                      );
                    })}
                  </ul>
                ),
              },
            ]}
          />
        </li>

        {props.current && (
          <li>
            <div className="items"></div>
          </li>
        )}

        {/*{props.current && (*/}
        {/*    <li>*/}
        {/*        <div className="items">*/}
        {/*            <Button*/}
        {/*                variant="primary"*/}
        {/*                py="5"*/}
        {/*                px="7"*/}
        {/*                onClick={() => {*/}
        {/*                    toggleViewOrders();*/}
        {/*                }}*/}
        {/*            >*/}
        {/*                view order details*/}
        {/*            </Button>*/}
        {/*            <Button variant="tertiary" py="5" px="7" className="track-order">*/}
        {/*                track order*/}
        {/*            </Button>*/}
        {/*        </div>*/}
        {/*    </li>*/}
        {/*)}*/}
      </SecondColumnStyled>

      {/*{props.previous && (*/}
      {/*    <Link*/}
      {/*        className="view-desktop"*/}
      {/*        onClick={() => {*/}
      {/*            toggleViewOrders();*/}
      {/*        }}*/}
      {/*    >*/}
      {/*        View order details*/}
      {/*    </Link>*/}
      {/*)}*/}
    </OrderItemStyled>
  );
};
