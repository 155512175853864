import { themeGet } from "@styled-system/theme-get"
import React, { useContext } from "react"
import styled from "styled-components"
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system"

import { ReactComponent as Basket } from "../../static/icons/Basket.svg"
import { ReactComponent as Profile } from "../../static/icons/Profile.svg"
import { ReactComponent as ProfileFilled } from "../../static/icons/ProfileFilled.svg"
import { ReactComponent as Search } from "../../static/icons/Search.svg"

import { mediaQueries } from "../theme"

import { Link } from "./Button"
import { ListPlain } from "./ListPlain"
import { BagContext } from "./Bag"

import {
  bag as bagPath,
  myAccount as myAccountPath,
  search as searchPath,
} from "../utils/paths"
import { isSignedIn } from "../utils/user"
import { useState } from "react"

export const MenuActionsStyle = styled(ListPlain)`
  align-items: center;
  display: flex;
  gap: ${themeGet("space.4")}px;
  grid-auto-flow: column;
  justify-content: end;

  ${mediaQueries.md} {
    gap: ${themeGet("space.3")}px;
    padding: ${themeGet("space.2")}px;
  }

  li {
    a {
      align-items: center;
      display: flex;
      position: relative;

      svg {
        height: 18px;
        object-fit: contain;
        object-position: center;
      }
    }

    &.profile {
      display: block;
    }
  }

  .basket-wrapper {
        position: relative;
        padding: 0;

        &.isBagToggled {
          box-shadow: none;
        }

        ${mediaQueries.md} {
          padding: 11px 20px;

          &.isBagToggled {
            box-shadow: 2px -3px 5px -1px rgb(0 0 0 / 10%);
            background-color: white;
            border-radius: 3px 3px 0 0;
          }
        }
    }
  }

  .bubble {
    position: absolute;
    right: 0;
    left: 0;
    text-align: center;
    font-size: 0.7rem;
    // line-height: 1.4rem;
    margin-top: 4px;
    padding: 0;


    ${mediaQueries.md} {
    }
  }

  ${compose(color, flexbox, grid, layout, position, space)}
`

export type MenuActionsProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps & {
    toggleBagPreviewVisibility: React.DispatchWithoutAction
    toggleQuickSearchVisibility: React.DispatchWithoutAction
  }

export const MenuActions: React.FC<MenuActionsProps> = ({
  toggleBagPreviewVisibility,
  toggleQuickSearchVisibility,
  ...props
}) => {

    const { bag } = useContext(BagContext);
    const [bagToggled, setBagToggled] = useState(false);

    return (
      <MenuActionsStyle {...props}>
        {/* <li>
          <Link
            to={searchPath}
            onClick={event => {
              // TODO: Only intercept link on desktop
              toggleQuickSearchVisibility();
              event?.preventDefault();
            }}
          >
            <Search />
          </Link>
        </li> */}
        <li className="profile">
          <Link to={isSignedIn() ? "/account/information" : "/account"}>
            {isSignedIn() ? <ProfileFilled /> : <Profile />}
          </Link>
        </li>
        <li className={"basket-wrapper " + (bagToggled ? "isBagToggled" : "")}>
          <Link
            id="miniBag"
            to={bagPath}
            onClick={event => {
              // TODO: Only intercept link on desktop
              toggleBagPreviewVisibility();

              bagToggled ? setBagToggled(false) : setBagToggled(true);
            
              event?.preventDefault();
            }}
          >
            <div className="bubble">
              {bag.line_items?.physical_items
                ?.map(item => item.quantity)
                .reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0,
                )}
            </div>
            <Basket />
          </Link>
        </li>
      </MenuActionsStyle>
    );
  }
