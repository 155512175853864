export const headings = `
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: "Tiempos", Times, serif;
        font-weight: lighter;
        margin-block-start: 0.25em;
    }

    .heading-1 {
        font-size: var(--font-size-heading-1, 32px);
    }

    .heading-2 {
        font-size: var(--font-size-heading-2, 24px);
    }

    .heading-3 {
        font-size: var(--font-size-heading-3, 18px);
    }

    .heading-4 {
        font-size: var(--font-size-heading-4, 16px);
    }

    .heading-5 {
        font-size: var(--font-size-heading-5, 14px);
    }

    .heading-6 {
        font-size: var(--font-size-heading-6, 12px);
    }
`
