import React from 'react';

export const optimizeUrl = (src : string | undefined, isThumbnail:boolean) => {
  if(src === undefined) {
    return undefined;
  }
  if(src.indexOf('bigcommerce') >= 0) {
    const replacement = isThumbnail ? '/120w/' : '/1024w/';
    return src.replace('/original/', replacement);
  }
  return src + '?quality=80';
}

export const ImgOptimized: React.FC<React.ImgHTMLAttributes<HTMLImageElement>> = ({
  src,
  ...props
}) => {
  return <img src={optimizeUrl(src, false)} {...props} />;
};
