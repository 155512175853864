import type {
  // GlobalHeaderQuery
  LayoutQuery,
} from "../../graphql-types";

import { themeGet } from "@styled-system/theme-get";
import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import { useToggle } from "../hooks/useToggle";

import { mediaQueries } from "../theme";

import { MenuHamburger } from "./MenuHamburger";
import { LogotypeLink } from "./LogotypeLink";
import { MenuActions } from "./MenuActions";
import { MenuMainNavigation } from "./MenuMainNavigation";
import { MenuStore } from "./MenuStore";
import { BagPreview } from "./BagPreview";
import { QuickSearch } from "./QuickSearch";
import { useState } from "react";




const GlobalHeaderStyled = styled.header`
  background-color: ${(props) =>
    props.transparent ? "transparent" : themeGet("colors.white")};
  border-bottom-style: ${(props) => (props.transparent ? "none" : "solid")};
  min-height: var(--header-min-height, 38px);
  padding-block-start: ${themeGet("space.3")}px;
  position: sticky;
  top: 0;
  transition: background-color ease-in 0.25s;
  z-index: 999;

  ${mediaQueries.md} {
    padding-block-start: ${themeGet("space.8")}px;
  }

  &:hover {
    background-color: ${themeGet("colors.background")};
  }

  .permanent {
    position: relative;
    z-index: 50;
  }


  .minibag {
    box-shadow: 0 -2px 10px 0 rgba(0,0,0,0.04);
    ${mediaQueries.md} {
      box-shadow: 1px 1px 5px 0 rgb(0 0 0 / 10%);
    }
  
  }
`;

const ContainerStyled = styled.div`
  align-items: center;
  display: grid;
  grid-template-areas: "menu-primary logo menu-secondary" "menu-navigation menu-navigation menu-navigation" "quick-search quick-search quick-search";
  grid-template-columns: 1fr 2fr 1fr;
  position: relative;
  z-index: 500;

  ${mediaQueries.md} {
    text-align: center;
  }
`;

export type GlobalHeaderProps = {
  // data?: GlobalHeaderQuery
  data?: LayoutQuery;
  siteTitle?: string;
  transparent?: boolean;
};

export const GlobalHeader: React.FC<GlobalHeaderProps> = ({
  data,
  ...props
}) => {
  const [miniBagVisibility, toggleBagPreviewVisibility] = useToggle();
  const [quickSearchVisibility, toggleQuickSearchVisibility] = useToggle();

  // const searchInputRef = React.useRef()
  // console.log(searchInputRef)

  return (
    <GlobalHeaderStyled transparent={props.transparent}>
      <React.Fragment>
        <ContainerStyled className="container">
          <LogotypeLink
            className="permanent"
            gridArea="logo"
            siteTitle={props.siteTitle}
          />
          <MenuStore
            display={{ _: "none", md: "flex" }}
            gridArea="menu-primary"
            left="-12px"
            position="relative"
          />
          <MenuHamburger data={data} gridArea="menu-primary" />
          <MenuActions
            className="permanent"
            gridArea="menu-secondary"
            toggleBagPreviewVisibility={toggleBagPreviewVisibility}
            toggleQuickSearchVisibility={toggleQuickSearchVisibility}
            // searchInputRef={searchInputRef}
          />
          <MenuMainNavigation
            display={{ _: "none", md: "block" }}
            gridArea="menu-navigation"
            data={data}
          />
          {miniBagVisibility && (
            <BagPreview className="minibag"/>               
          )}
        </ContainerStyled>
        {quickSearchVisibility && (
          <QuickSearch
            gridArea="quick-search"
            // searchInputRef={searchInputRef}
            searchIndex={data?.siteSearchIndex?.index}
          />
        )}
        <Helmet>
          <meta
            name="facebook-domain-verification"
            content="s5z8yhfn6abxt85mc5e2ustq3g6ztw"
          />
          {
            process.env.GATSBY_ENV === "STAGING" ? (
              <script type="text/javascript">
                var cssElement = document.createElement('link'); cssElement.rel =
                'stylesheet'; cssElement.id = 'GEPIStyles'; cssElement.href =
                '//intgepi.bglobale.com/includes/css/824';
                document.getElementsByTagName('head')[0].appendChild(cssElement);
              </script>
            ) : !process.env.GATSBY_ENV?.toLowerCase().includes("us") ? (
              <script type="text/javascript">
                var cssElement = document.createElement('link'); cssElement.rel =
                'stylesheet'; cssElement.id = 'GEPIStyles'; cssElement.href =
                '//gepi.global-e.com/includes/css/824';
                document.getElementsByTagName('head')[0].appendChild(cssElement);
              </script>
            ) : null
          }
        </Helmet>
        {/* { process.env.GATSBY_ENV === "PRODUCTION" && <><div id="cookiefirst-policy-page"></div> <div>This cookie policy has been created and updated by <a href="https://cookiefirst.com">CookieFirst.com</a>.</div></>} */}
      </React.Fragment>
    </GlobalHeaderStyled>
  );
};
