// import type { Context } from "react"
import type { Product } from "schema-dts"

import type { Bag } from "../../types/BigCommerce"

import { themeGet } from "@styled-system/theme-get"
import React, { useContext } from "react"
import styled from "styled-components"
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system"

import { mediaQueries } from "../theme"

import { BagContext } from "./Bag"
import { BagProduct } from "./BagProduct"
import { Button, LinkButton } from "./Button"
import { ReactComponent as Close } from "../../static/icons/Close.svg"
import { useMediaQuery } from "../hooks/useMediaQuery"

export type BagPreviewProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps & {
    bag: Bag
    items: Product[],
    toggleBagPreviewVisibility: React.DispatchWithoutAction
  }

export const BagPreviewStyled: React.FC<BagPreviewProps> = styled.div`
  background-color: ${themeGet("colors.background")};
  border-style: solid;
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1fr auto;
 
  ${mediaQueries.smDown} {
    border-radius: 10px 10px 0 0;
    position: fixed;
    right: 0;
    top: auto;
    bottom: 0;
    min-width: 100vw;
    max-width: 100%;
    min-height: 235px;
    padding: 30px 20px; 
    grid-template-rows: auto;
  }

  ${mediaQueries.md} {
    border-radius: 20px 0 20px 20px;
    max-height: 60vh;
    min-width: 32rem;
    padding: 1rem;
    margin-block-start: 2rem;

    position: absolute;
    right: 35px;
    top: 8px;
    min-width: 425px;
    max-width: 425px;
    max-height: 584px;
    padding: 30px;
  }

  ${mediaQueries.lg} {
    right: 51px;
  }

  ${mediaQueries.xl} {
    right: 67px;
  }

  

  &:disabled {
    color: "disabled";
    cursor: "not-allowed";
  }

  .close-button {
    position: absolute;
    right: 3px;
    top: 18px;
  
    ${mediaQueries.md} {
      display: none;
    }
  }

  .items {
    display: grid;
    gap: 2rem;
    grid-auto-flow: row;
    margin-block-end: 1rem;
    overflow-y: auto;
    place-content: stretch;

    .title {
      max-width: 40ch;
    }

    &.mini-basket-desktop {
      display: none;
      ${mediaQueries.md} {
        display: grid;
      }
    } 

    &.mini-basket-mobile {
      display: grid;
      ${mediaQueries.md} {
        display: none;
      }
    } 
  
  }

  .checkout-section {
    border-top-style: none;
    display: grid;
    gap: 1rem;
    grid-auto-flow: row;
    padding-block-end: 0;
    padding-block-start: 1rem;

    ${mediaQueries.md} {
      border-top-style: solid;
      padding-block-end: 1rem;
      padding-block-start: 1rem;
    }
  }

  .grid-wrapper {
  
    display: none;

    ${mediaQueries.md} {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    span {
      text-transform: uppercase;
    }

    .title {
      font-weight: bold;
    }

    .price {
      font-family: "Tiempos", serif;
      font-size: var(--font-size-heading-5, 14px);
      font-weight: lighter;
    }
  }

  ${compose(color, flexbox, grid, layout, position, space)}
`

export const BagPreview: React.FC<BagPreviewProps> = props => {
  const { bag }: { bag: Bag } = useContext(BagContext)
  const isNotMobile = useMediaQuery(mediaQueries.md);

  return (
    <BagPreviewStyled {...props}>

      <Button  className="close-button" onClick={event => {  
        const element = document.getElementById("miniBag");
        element?.click();
        document.getElementsByClassName("basket-wrapper")[0].classList.remove("isBagToggled");
        event?.preventDefault();
      }}>
          <Close />
      </Button>


      <section className="items mini-basket-list mini-basket-desktop">
        {bag?.line_items?.physical_items?.map((item, index) => <BagProduct  isMiniBag={true} key={index} locale={bag?.locale} currency_code={bag?.currency?.code} layout="compact" {...item} />)}
        
        {props.items?.map((item, index) => (
          <BagProduct isMiniBag={true} key={index} layout="compact" locale={bag?.locale} currency_code={bag?.currency?.code} {...item} />
        ))}

        {!props.items && !bag?.cart_amount && <span>No items in shopping bag</span>}

       
      </section>

      <section className="items mini-basket-list mini-basket-mobile">
        <span>Item added to bag</span>
      </section>
      <section className="checkout-section" key={bag?.redirect_urls?.checkout_url}>
        <div className="grid-wrapper">
          <span className="title">Total (inc vat)</span>
          <span className="price">
            {bag?.cart_amount && new Intl.NumberFormat(bag?.locale, { style: 'currency', currency: bag?.currency?.code }).format(bag?.cart_amount as number)}
          </span>
        </div>
        <LinkButton to="/bag" variant="secondary">
          View Bag
        </LinkButton>
        <LinkButton to={bag?.redirect_urls?.checkout_url ? bag?.redirect_urls?.checkout_url : "/checkout"} variant="primary">
          Checkout
        </LinkButton>
      </section>
    </BagPreviewStyled>
  )
}
