import type {
  Image as ImageProp,
    Link as LinkProp,
} from "../../types/components"

import { themeGet } from "@styled-system/theme-get"
import React from "react"
import styled from "styled-components"
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system"

import { Link } from "./Button"
import { useState } from "react"
import { VideoPlayer } from "./VideoPlayer";
import { mediaQueries } from "../theme";
import 'reactjs-popup/dist/index.css';
import { ImgOptimized } from "./ImgOptimized"
import { Modal } from "./Modal"


export type ArticleCardProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps & {
    aspectRatio: string
    image: ImageProp
    link: LinkProp
    summary: string
    tag: string
    title: string
    titlePosition: "bottom" | "top"
    url: string
  }

const ArticleCardStyled = styled.article`

  margin-bottom: 2em;

  header {
    align-items: start;
    display: grid;
  }

  figure {
    aspect-ratio: ${props => (props.aspectRatio ? props.aspectRatio : "3/4")};
  }

  img {
    height: 100%;
    width: 100%;
  }

  .content {
    display: grid;
    justify-content: start;
    padding-inline-end: 1rem;
    padding-inline-start: 1rem;
    text-align: start;
    z-index: 999;
  }

  .tag {
    display: block;
  }

  .title {
    font-size: ${themeGet("fontSizes.7")}px;
    margin-block-start: 1rem;
    margin-block-end: 10px;
    order: ${props => (props.titlePosition === "top" ? "-1" : "unset")};
    letter-spacing: .5px;

    ${mediaQueries.md} {
      margin-block-end: 1rem;
    }
  }

  .summary {
    display: block;
    margin-block-end: 1rem;
    margin-block-start: 1rem;
  }

  a {
    text-transform: uppercase;
    font-weight: bold;
    margin-block-start: 8px;
  }

  .play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: transparent;
    border:1px solid #000;
    color: white;
    font-size: 16px;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    text-align: center;
  }
  .play-btn:active {
    background-color: #595456;
  }
  #clicked:active {
    fill: white;
  }
  #clicked:active {
    fill: white;
  }
  #clicked {
    margin-left:5px;
    margin-top:5px;
  }
  .figure-video {
    position: relative;
  }

  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }

  ${compose(color, flexbox, grid, layout, position, space)}
`

export const ArticleCard: React.FC<ArticleCardProps> = props => {

  const [modalIsOpen, setIsOpen] = useState(false)

  function closeModal() {
    document.body.style.overflow = 'scroll';
    setIsOpen(false);
  }

  function openModal() {
    document.body.style.overflow = 'hidden';
    setIsOpen(true);
  }

  return (
    <ArticleCardStyled {...props}>
      {!modalIsOpen ?
      <React.Fragment>
      <header>
        {props.image && (
          <figure className="figure-video">
            <a href={props.url}>
              <ImgOptimized alt={props.image?.title} src={props.image?.src} />
            </a>
            <a className="play-btn" style={{display: (props.isVideo ? (props.isVideo === true ? "block" : "none") : "none")}} onClick={() => openModal()}>
              <svg width="26" height="26" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" id="clicked"><path d="M23 12l-22 12v-24l22 12zm-21 10.315l18.912-10.315-18.912-10.315v20.63z" /></svg>
            </a>
          </figure>
        )}
      </header>
      <div className="content">
        {props.title && <h1 className="title">{props.title}</h1>}
        {props.tag && <span className="tag">{props.tag}</span>}
        {props.summary && <span className="summary">{props.summary}</span>}
        {props.link && <Link to={props.link ?.href}>{props.link ?.title}</Link>}
        {props.url && <Link to={props.url}>Read more</Link>}
      </div>
      </React.Fragment> :
      <React.Fragment>
        {/* <figure aspect-ratio="2/2">
              <VideoPlayer
                alt="something"
                muted={true}
                url={props.video?.src}
              />
            </figure> */}
        <Modal
                  closeModal={closeModal}
                  contentLabel="Video player"
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}
                  className="video-player"
                  ModalOverlayStyleCustom={{
                    border: "none",
                    borderRadius: 0,
                    padding: "0 !important",
                    aspectRatio: "16/9",
                    maxHeight: "90vh",
                    maxWidth: "90vw",
                    margin: "auto",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    height: "min-content",
                  }}>
          <figure>
            <VideoPlayer
              layout="video"
              alt="something"
              muted={props.video?.muted !== undefined ? props.video?.muted: true}
              url={props.video?.src}
            />
          </figure>
        </Modal>
        {/* <ModalVideo channel='custom' autoplay isOpen={isOpen} url={props.video?.src} onClose={() => setOpen(false)} /> */}
      </React.Fragment>
      }
    </ArticleCardStyled>

  )
}
