import Checkout from "../../static/icons/Checkout.svg"
import Cookies from "../../static/icons/Cookies.svg"
import Diamond from "../../static/icons/Diamond.svg"
import GiftWrap from "../../static/icons/GiftWrap.svg"
import Newsletter from "../../static/icons/Newsletter.svg"
import Nib from "../../static/icons/Nib.svg"
import Perfume from "../../static/icons/Perfume.svg"
import Plant from "../../static/icons/Plant.svg"
import Recycle from "../../static/icons/Recycle.svg"
import Return from "../../static/icons/Return.svg"
import Shipping from "../../static/icons/Shipping.svg"
import Tracking from "../../static/icons/Tracking.svg"
import WishlistAbsent from "../../static/icons/WishlistAbsent.svg"
import Bow from "../../static/icons/Bow.svg"
import Vegan from "../../static/icons/Vegan.svg"
import Refill from "../../static/icons/Refill.svg"
import AwardWinning from "../../static/icons/Award.svg"

export const iconClasses = `
    .checkout {
        background-image: url(${Checkout});
    }

    .cookies {
        background-image: url(${Cookies});
    }

    .diamond {
        background-image: url(${Diamond});
    }

    .envelope {
        background-image: url(${Newsletter});
    }

    .gift-wrap {
        background-image: url(${GiftWrap});
    }

    .heart {
        background-image: url(${WishlistAbsent});
    }

    .nib {
        background-image: url(${Nib});
    }

    .perfume {
        background-image: url(${Perfume});
    }

    .plant {
        background-image: url(${Plant});
    }

    .recycle {
        background-image: url(${Recycle});
    }

    .return {
        background-image: url(${Return});
    }

    .shipping {
        background-image: url(${Shipping});
    }

    .tracking {
        background-image: url(${Tracking});
    }
    
    .bow {
        background-image: url(${Bow});
    }

    .vegan {
        background-image: url(${Vegan});
    }

    .refill {
        background-image: url(${Refill});
    }

    .awardWinning {
        background-image: url(${AwardWinning});
    }
`