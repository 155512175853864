import FileSaver from "file-saver";

export const downloadVCard = async (values: any): Promise<any> => {

let phone2 = values.phone_2 ? `TEL;type=HOME;type=pref:${values.phone_2}` : '';
    var file = new Blob(
        [
        `BEGIN:VCARD
VERSION:4.0
N:${values.first_name};${values.surname};;;
FN:${values.first_name} ${values.surname}
ORG:${values.business_name};
TITLE:${values.position};
TEL;type=WORK:${values.phone}
${phone2}
EMAIL;type=INTERNET;type=pref:${values.email}
ADR;type=intl,work,postal,parcel;type=pref:${values.address}
SOCIALPROFILE;type=linkedin:${values.linkedin}
SOCIALPROFILE;type=instagram:${values.instagram}
SOCIALPROFILE;type=facebook:${values.facebook}
SOCIALPROFILE;type=youtube:${values.youtube}
SOCIALPROFILE;type=twitter:${values.twitter}
END:VCARD
        `
        ],
        { type: "text/vcard;charset=utf-8" }
      );
      FileSaver.saveAs(
        file,
        `${values.first_name}_${values.surname}.vcf`,
        true
      );
}