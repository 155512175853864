import { IconContext } from "@react-icons/all-files"
import { FiPlay } from "@react-icons/all-files/fi/FiPlay"
import React from "react"
import { Helmet } from "react-helmet"
import ReactPlayer from "react-player/lazy"
import styled from "styled-components"
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system"

import { getContent, LayoutStyled } from "./Banner"

export type VideoPlayerProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps & ReactPlayer & {
    aspectRatio: string,
    onEnded?: () => void,
    playing?: boolean
  }

export const VideoPlayerStyled: React.FC<VideoPlayerProps> = styled.div`
  position: relative;
  overflow: hidden;
  ${compose(color, flexbox, grid, layout, position, space)}
`

export const VideoPlayer: React.FC<VideoPlayerProps> = props => (
  <LayoutStyled layout={props.vimeo_embed_code ? "embedVideo" : "video"} {...props}>
          {props.vimeo_embed_code ?
          <div className="embedVideo"
          dangerouslySetInnerHTML={{
            __html: props.vimeo_embed_code as string,
          }}
        />:
        <>
    <VideoPlayerStyled aspect-ratio={props.aspectRatio} className="media">
      <ReactPlayer
        className="react-player"
        controls={false}
        height="100%"
        light={props.image?.url}
        loop={!props.onEnded}
        playIcon={
          <IconContext.Provider value={{ color: "white", size: "2rem" }}>
            <FiPlay />
          </IconContext.Provider>
        }
        playing={props.playing ?? true}
        playsinline={true}
        url={props.video?.url}
        width="100%"
        onEnded={props.onEnded}
        {...props}
      />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "VideoObject",
            "name": props.video?.title,
            "description": props.video?.text,
            "thumbnailUrl": [
              props.image?.src
            ],
            "uploadDate": props.publish_details?.time,
            "contentUrl": props.video?.url
          })}
        </script>
      </Helmet>
    </VideoPlayerStyled>
    {getContent({...props, layout: "video"})}
    </>}
  </LayoutStyled>
)
