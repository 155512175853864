import type { Product, ProductGroup, WithContext } from "schema-dts"

import type {
  BigCommerceGql_CategoryEdge,
  BigCommerceGql_CustomFieldEdge,
  BigCommerceGql_ImageEdge,
  BigCommerceGql_Money,
  BigCommerceGql_OptionEdge,
  BigCommerceGql_OptionValueEdge,
  BigCommerceGql_Product,
  BigCommerceGql_Variant,
  BigCommerceGql_VariantEdge,
} from "../../graphql-types"

import {priceList} from "./priceList";
import Cookies from "universal-cookie"
import { string } from "yup";
export interface BigCommerceGql_CustomObject{
  show_options_on_lister?: string,
  display_size?: string,
  swatch_colour?: string,
  manufactured_in?: string,
  sorting?: string,
  has_refill?: string
}

export function standardiseBigCommerceProduct({
  productFormatBigCommerce,
}: {
  productFormatBigCommerce: BigCommerceGql_Product
}): WithContext<Product> {
  let availability = "https://schema.org/InStock"

  // if (
  //   typeof node?.inventory?.aggregated?.warningLevel === "number" &&
  //   typeof node?.inventory?.aggregated?.warningLevel === "number" &&
  //   node?.inventory_level <= node?.inventory?.aggregated?.warningLevel
  // ) {
  //   availability = "https://schema.org/LimitedAvailability"
  // }

  if (productFormatBigCommerce?.inventory?.isInStock === false) {
    availability = "https://schema.org/OutOfStock"
  }

  const customObject = extractCustomObject(productFormatBigCommerce);

  const data: WithContext<Product> = {
    "@context": "https://schema.org",
    "@type": "Product",
    brand: productFormatBigCommerce?.brand?.name,
    category: (productFormatBigCommerce?.categories
      ?.edges as BigCommerceGql_CategoryEdge[])?.map(({ node: category }) => ({
      "@type": "Thing",
      identifier: category?.entityId?.toString() as string | undefined,
      name: category?.name,
      url: category?.path,
    })),
    depth: productFormatBigCommerce?.depth,
    description: productFormatBigCommerce?.description,
    gtin: productFormatBigCommerce?.gtin,
    height: productFormatBigCommerce?.height,
    identifier: productFormatBigCommerce?.entityId?.toString(),
    image: (productFormatBigCommerce?.images
      ?.edges as BigCommerceGql_ImageEdge[])?.map(({ node: image }) => ({
      "@type": "ImageObject",
      caption: image?.altText,
      contentUrl: image?.urlOriginal,
      representativeOfPage: image?.isDefault,
    })),
    // mpn: productFormatBigCommerce?.mpn,
    name: productFormatBigCommerce?.name,
    offers: {
      "@type": "Offer",
      availability,
      price: extractPrice(productFormatBigCommerce?.entityId, productFormatBigCommerce?.prices?.price)?.value,
      priceCurrency: extractPrice(productFormatBigCommerce?.entityId, productFormatBigCommerce?.prices?.price)?.currencyCode,
    },
    productID: productFormatBigCommerce?.entityId?.toString(),
    sku: productFormatBigCommerce?.sku,
    // upc: productFormatBigCommerce?.upc,
    url: process.env.GATSBY_SITE_URL + productFormatBigCommerce?.path,
    weight: productFormatBigCommerce?.weight,
    width: productFormatBigCommerce?.width,
    variesBy: customObject,
    showOptionsOnLister: customObject?.show_options_on_lister === "Y",
    manufacturedIn: customObject?.manufactured_in
  } as WithContext<Product>

  return data
}

export function standardiseBigCommerceVariant({
  productFormatBigCommerce,
  variantFormatBigCommerce,
}: {
  productFormatBigCommerce: BigCommerceGql_Product
  variantFormatBigCommerce: BigCommerceGql_Variant
}): WithContext<Product> {
  const product = standardiseBigCommerceProduct({
    productFormatBigCommerce,
  }) as WithContext<ProductGroup>

  let availability = "https://schema.org/InStock"

  if (variantFormatBigCommerce?.inventory?.isInStock === false) {
    availability = "https://schema.org/OutOfStock"
  }

  const variant: WithContext<Product> = {
    "@context": "https://schema.org",
    "@type": "Product",
    brand: productFormatBigCommerce?.brand?.name,
    category: (productFormatBigCommerce?.categories
      ?.edges as BigCommerceGql_CategoryEdge[])?.map(({ node: category }) => ({
      "@type": "Thing",
      identifier: category?.entityId as number | undefined,
      name: category?.name,
      url: category?.path,
    })),
    depth: productFormatBigCommerce?.depth,
    description: productFormatBigCommerce?.description,
    gtin: productFormatBigCommerce?.gtin,
    height: productFormatBigCommerce?.height,
    identifier: productFormatBigCommerce?.entityId.toString(),
    image: (productFormatBigCommerce?.images
      ?.edges as BigCommerceGql_ImageEdge[])?.map(({ node: image }) => ({
      "@type": "ImageObject",
      caption: image?.altText,
      contentUrl: image?.urlOriginal,
      representativeOfPage: image?.isDefault,
    })),
    // mpn: productFormatBigCommerce?.mpn,
    name: productFormatBigCommerce?.name,
    offers: {
      "@type": "Offer",
      availability,
      price: extractPrice(productFormatBigCommerce?.entityId, productFormatBigCommerce?.prices?.price)?.value,
      priceCurrency: extractPrice(productFormatBigCommerce?.entityId, productFormatBigCommerce?.prices?.price)?.currencyCode,
      url: process.env.GATSBY_SITE_URL + productFormatBigCommerce?.path,
    },
    productID: productFormatBigCommerce?.entityId.toString(),
    sku: productFormatBigCommerce?.sku,
    // upc: productFormatBigCommerce?.upc,
    url: process.env.GATSBY_SITE_URL + productFormatBigCommerce?.path,
    weight: productFormatBigCommerce?.weight,
    width: productFormatBigCommerce?.width,
  } as WithContext<Product>

  return { ...product, ...variant }
}

export function standardiseBigCommerceProductGroup({
  productFormatBigCommerce,
}: {
  productFormatBigCommerce: BigCommerceGql_Product
}): WithContext<ProductGroup> {
  const data = standardiseBigCommerceProduct({
    productFormatBigCommerce,
  }) as WithContext<ProductGroup>

  const productGroupID = productFormatBigCommerce?.id;

  const hasVariant: (Product | {variesBy : any})[] = (productFormatBigCommerce?.relatedProducts
    ?.edges as any[])?.map(({ node } : {node: BigCommerceGql_Product}) => {
      const product = standardiseBigCommerceProduct({
        productFormatBigCommerce :node
      }) as WithContext<ProductGroup>
    return {
      ...product,
      url: node.path,
    }
  }) as (Product | {variesBy : any})[]

  // add itself as product variant and sort
  hasVariant.push({
    ...data,
    url: productFormatBigCommerce.path,
  } as (Product | {variesBy : any}));

  const valueToSort = (product) => {
    const stringToCompare = (product.variesBy.display_size && product.variesBy.display_size.replace("ml", '')) ?? product.variesBy.sorting ?? (product.variesBy.swatch_colour && product.variesBy.swatch_colour.replace(/^\D+/g, '')) ?? '';
    // console.log("stringToCompare: ", stringToCompare)
    return stringToCompare && stringToCompare;//.replace("ml", '') //? parseInt(stringToCompare.replace(/^\D+/g, '') || -1) : 0 valueToSort(a) == valueToSort(b) ? 0 : 
  };
  
  hasVariant.sort((a:any,b:any) =>  valueToSort(a).includes("Refill") || valueToSort(a) == "" ? 1 : valueToSort(b).includes("Refill") || valueToSort(b) == "" ? -1 : parseInt(valueToSort(a)) < parseInt(valueToSort(b)) ? -1 : 1);
  
  return {
    ...data,
    "@type": "ProductGroup",
    hasVariant: hasVariant as Product[],
    productGroupID,
    url: process.env.GATSBY_SITE_URL + productFormatBigCommerce?.path,
  }
}

function extractCustomObject(node: BigCommerceGql_Product): BigCommerceGql_CustomObject{
  return (node?.customFields
    ?.edges as BigCommerceGql_CustomFieldEdge[])?.reduce(
      (acc, { node }) =>  {acc[node.name] = node.value; return acc;}, {}); 
}

function extractPrice(productId?: number, price?: BigCommerceGql_Money): BigCommerceGql_Money | undefined {
  let currencyCode: any;

  if (process.env.GATSBY_ENV?.toLowerCase().includes("us")) {
    currencyCode = "USD";

      return {
        value: price ? price?.value : 0,
        currencyCode: currencyCode,
      };

  } else {
    currencyCode = (new Cookies()).get('geOriginalCurrencyCode');
    // this logic should be moved to gatsby
    let pricing = priceList[`${productId}${currencyCode}`];

    if(pricing) {
      return {
        value: pricing.calculated_price,
        currencyCode: currencyCode,
      };
    }
  }

 

  return price;
}