import type { ProductGroup } from "schema-dts"

import clsx from "clsx"
import React from "react"
import styled from "styled-components"
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system"
import { Link } from "gatsby"

const ProductSelectorSizeStyled = styled.div`

  .form-fields {
    grid-auto-flow: column;
    justify-content: start;
    display: flex;
    flex-wrap: wrap;
  }

  .active label {
    text-decoration: underline;
    font-weight: bold;
  }

  ${compose(color, flexbox, grid, layout, position, space)}
`

export type ProductSelectorSizeProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps & {
    productGroup: ProductGroup
  }

export const ProductSelectorSize: React.FC<ProductSelectorSizeProps> = ({
  product,
  ...props
}) => (
  <ProductSelectorSizeStyled {...props}>
    <h3 className="title" id="group_label_sizes">
      SIZES
    </h3>
    <div aria-labelledby="group_label_sizes" className={clsx('form-fields')}>
      {product?.hasVariant.map((variant, index) => (
        <Link
          to={variant.url}
          className={"field " + (variant.productID === product.productID ? 'active' : '')}
          key={index}
        >
          <label className="product-radio-label">{variant.variesBy.display_size}</label>
        </Link>
      ))}
    </div>
  </ProductSelectorSizeStyled>
);
