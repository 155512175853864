import type from "react";

import { themeGet } from "@styled-system/theme-get"
import clsx from "clsx"
import React, { useEffect, useRef, useState, MutableRefObject } from "react"
import styled from "styled-components"
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system"

import { Button } from "./Button"
import { mediaQueries } from "../theme";

const CarouselStyled = styled.section`
  --item-gap: ${props => props.itemGap ? props.itemGap : 1}rem;
  --item-size: ${props => props.visibleItems ? 80 : 100}%;
  ${mediaQueries.md} {
    --item-size: ${props => props.visibleItems ? (props.isMobile ? 80 : 100 / props.visibleItems) : 100}%;
  }

  display: grid;
  grid-auto-flow: column;
  position: relative;
  

  .scrollable-container {
    max-height: ${props => props.orientation === "vertical" ? "var(--item-size, 100%)" : "unset"};
    max-width: ${props => props.orientation !== "vertical" ? `calc(${props.visibleItems ? (props.isMobile ? 3 : props.visibleItems) : 1} * var(--item-size, 100%))` : "unset"};
    overflow-x: ${props => props.orientation !== "vertical" ? "scroll" : "hidden"};
    overflow-y: ${props => props.orientation === "vertical" ? "scroll" : "hidden"};
    padding-block-end: ${props => props.showPickers ? "3rem" : "unset"};
    scroll-behavior: smooth;
    scroll-snap-type: ${props => props.orientation === "vertical" ? "y mandatory" : "x mandatory"};
    transition-delay: 125ms;
    transition-duration: 125ms;
    transition-property: filter;
    transition-timing-function: ease-in-out;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .items {
    position: relative;
    display: grid;
    gap: var(--item-gap, 1rem);
    ${props => props.orientation === "vertical" ? `
      grid-auto-rows: calc(var(--item-size, 100%) - var(--item-gap, 1rem));
      grid-auto-flow: row;
      grid-template-rows: repeat(auto-fill, calc(var(--item-size, 100%) - var(--item-gap, 1rem)));
    ` : `
      grid-auto-columns: calc(var(--item-size, 100%) - var(--item-gap, 1rem));
      grid-auto-flow: column;
      grid-template-columns: repeat(auto-fill, calc(var(--item-size, 100%) - var(--item-gap, 1rem)));
    `}
    position: relative;
  }

  .pickers {
    align-content: center;
    bottom: 0;
    display: ${props => props.showPickers ? "grid" : "none"};
    grid-auto-flow: ${props => props.orientation === "vertical" ? "row" : "column"};
    justify-content: center;
    left: ${props => props.orientation === "vertical" ? "auto" : 0};
    pointer-events: none;
    position: absolute;
    right: 0;
    top: ${props => props.orientation !== "vertical" ? "auto" : 0};
  }

  ${props => props.pickerColour === "dark" && `
    .picker {
      color: ${themeGet("colors.white")};
      mix-blend-mode: overlay;
    }
  `}

  .item,
  .picker,
  .ui-button {
    transition-duration: 500ms;
    transition-property: filter, opacity;
  }

  .item {
    scroll-snap-align: ${props => props.isMobile ? "center" : "start"};
    //border-radius: 10px;
    //height: ${props => props.isMobile ? "371px" : "unset"};
    //width: ${props => props.isMobile ? "278px" : "unset"};

  }

 

  .ui-button {
    display: ${props => props.showArrows ? "flex" : "none"};
    height: var(--button-height, 24px);
    position: absolute;
    top: calc(50% - (var(--button-height, 24px) * 0.5));
    z-index: 1;

    &:not(.active) {
      opacity: 0.25;
    }

    svg {
      margin-inline-start: unset !important;
    }
  }

  ${compose(color, flexbox, grid, layout, position, space)}
`

export type CarouselProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps & {
    children: React.ReactNode
    itemGap: number
    layout?: "overlay" | "chrome"
    orientation?: "horizontal" | "vertical"
    pickerColour: "dark" | "light"
    showArrows?: boolean
    showPickers?: boolean
    isMobile?: boolean
    visibleItems?: 1 | 2 | 3 | 4
  }

export const Carousel: React.FC<CarouselProps> = props => {
  const pickersRef = useRef() as MutableRefObject<HTMLDivElement>
  const itemsRef = useRef() as MutableRefObject<HTMLDivElement>
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    const items = itemsRef.current.children;


    Array.from(items)?.forEach((item, index) => {
      item.classList.add("item");

      item.addEventListener("click", articleCardClicked, false);

      if (index === slideIndex) {
        item.classList.add("active")
      } else {
        item.classList.remove("active")
      }
    })

    const pickers = pickersRef.current.children

    Array.from(pickers).forEach((picker, index) => {
      if (index === slideIndex) {
        picker.classList.add("active")
      } else {
        picker.classList.remove("active")
      }
    })
  })

  const articleCardClicked = (event) => {
    const items = itemsRef.current.children

    const currentTargetIndex = Array.from(items).indexOf(event.currentTarget)

    setSlideIndex(currentTargetIndex)
  }


  return (
    <CarouselStyled className={clsx("carousel")} {...props}>
      <div className="scrollable-container">
        <div className="items " ref={itemsRef}>
          {props.children}
        </div>
      </div>
      <div className="pickers" ref={pickersRef}>
        {props.children?.map((_item, index) => (
          <Button className="picker" key={index} onClick={() => setSlideIndex(index)} />
        ))}
      </div>
    </CarouselStyled>
  )
}

export default Carousel
