
import { functions as functionsPath } from "../utils/paths";
import { Account } from "../utils/user";


export interface ResetPassword {
    email: string
  }

  export interface ChangePassword {
    password: string,
    confirmPassword: string
  }


export const resetPassword = async (email: ResetPassword): Promise<any> => {
  console.log("email:", email);

    const path = `${functionsPath}/password?action=reset_password`;

    const url = new URL(path, `${process.env.GATSBY_SITE_URL}`);

    const response = await fetch(url.toString(), {
      body: JSON.stringify(email),
      headers: {
        Accept: "application/json",
      },
      method: "POST",
    });

    if (response.ok) {


      return { statusCode: response.status, status: "success" };
    } else {
      return{ statusCode: null, status: "Failed" };
    }
};

export const changePassword = async (password: ChangePassword, customerId: string): Promise<any> => {
  console.log("password:", password);

    const path = `${functionsPath}/password?action=change_password&customer_id=${customerId}`;

    const url = new URL(path, `${process.env.GATSBY_SITE_URL}`);


    const response = await fetch(url.toString(), {
      body: JSON.stringify(password),
      headers: {
        Accept: "application/json",
      },
      method: "PUT",
    });

    if (response.ok) {


      return { statusCode: response.status, status: "success" };
    } else {
      return{ statusCode: null, status: "Failed" };
    }
};

export const getUserToken = () => {
  if (typeof window === "undefined") {
    return false;
  }
  const user = localStorage.getItem("user");
  if (!user) {
    return false;
  }
  const userToken = (JSON.parse(user) as Account)?.userToken;

  return userToken && userToken;
};
