import React from 'react';

export const UserContext = React.createContext({
  user: {},
  setUser: user => {
    // console.log(user);
  },
} as {
  user: any;
  setUser: (user: any) => void;
});
