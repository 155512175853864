import React, { useEffect, useState } from "react";
import { themeGet } from "@styled-system/theme-get";
import styled from "styled-components";
import { mediaQueries } from "../../theme";

import { Layout } from "../../components/Layout";
import { ListPlain } from "../../components/ListPlain";
import { AccountLayout } from "../../components/AccountLayout";
import { SEO } from "../../components/SEO";
import { ProductCard } from "../../components/ProductCard";
import { getWishlistsByCustomerToken } from "../../utils/wishlists_dataService";
import { Banner } from "../../components/Banner";
import clsx from "clsx";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { getProductList } from "../../utils/products_dataService";
import SkeletonProduct from "../../components/SkeletonProduct";
import { navigate } from "gatsby";

const WishlistColumn = styled(ListPlain)`
  .wishlist-title {
    margin: 0 0 40px 0;
    font-size: 32px;
  }
  .recent-heading {
    margin: 0;
    padding-block-start: 1rem;
    padding-block-end: 1rem;
  }

  .wishlist-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px 30px;
    ${mediaQueries.md} {
      grid-template-columns: repeat(3, 1fr);
    }
    // border-bottom: solid ${themeGet("border.color")} ${themeGet(
      "border.width"
    )};
    padding-block-end: 2rem;
  }
  .recently-viewed {
    display: grid;
    grid-auto-flow: column;
    gap: 1rem;
  }
`;

const BannerSection = styled.div`
  .banner-section {
    padding: 0;
    margin-bottom: 40px;

    .accounts-imagebanner {
      aspect-ratio: unset;

      .image-overlay-gradient {
        background: linear-gradient(
          180deg,
          rgba(102, 95, 107, 0) 0%,
          #36313980 100%
        );
      }
    }

    .accounts-promobanner {
      aspect-ratio: unset;
      max-width: 1266px;
      margin: 70px auto;

      .image-overlay-gradient {
        background: linear-gradient(
          180deg,
          rgba(102, 95, 107, 0) 0%,
          #36313980 100%
        );
      }
    }
  }
`;

const AccountLayoutContainer = styled.div`
  max-width: 1266px;
  margin: auto;
`;

const WishlistPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = useMediaQuery(mediaQueries.qmd);
  const [products, setProducts]: any[] = useState([]);

  useEffect(() => {
    getProductList()
      .then(productsData => {
        // console.log("productsData", productsData);

        if (productsData.status.toLowerCase() === "nologin") {
          navigate("/account");
          return;
        }

        getWishlistsByCustomerToken()
          .then(wishlistsData => {
            setProducts([]);


            if (wishlistsData && wishlistsData.data.length > 0 && wishlistsData.data[0].items.length > 0) {
              wishlistsData.data[0].items.map(item => {
                let filteredProducts = productsData.data.find(
                  prod => prod.productID === item.product_id
                );

                if (filteredProducts) {
                  // console.log("filteredProducts", filteredProducts);
                  setProducts(result => [...result, filteredProducts]);
                  setIsLoading(false);
                } else {
                  setIsLoading(false);
                }
              });
            } else {
              setIsLoading(false);
            }
          })
          .catch(err => {
            console.log("error: ", err);
          });
      })
      .catch(err => {
        console.log("error: ", err);
      });
  }, []);

  return (
    <Layout>
      {/* <BannerSection>
        <section
          className={clsx(
            "banner-section",
            `${isMobile ? "mobile-container" : "container"}`
          )}
        >
          <Banner
            style={{ height: "326px" }}
            backgroundPosition="center center"
            className={clsx("banner accounts-imagebanner fade")}
            image={{
              alt: "Wishlist",
              src:
                "https://eu-images.contentstack.com/v3/assets/blt2bb45aeece463821/bltfc9f099050274a00/61e75d72b0046e58172f2a09/Spa_Day.jpg?quality=80",
            }}
            isMobile={isMobile}
            justifyItems="left"
            layout="hero"
            tag="La Perla Beauty"
            iosHeight={true}
            title="Wishlist"
            isAnimated={false}
          />
        </section>
      </BannerSection> */}
      <AccountLayoutContainer>
        <AccountLayout>
          <SEO title="My wishlist" />
          <WishlistColumn>
            <h1 className="wishlist-title">Wishlist</h1>
            {/* <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores
          doloremque blanditiis veniam at temporibus provident eveniet a fuga
          atque excepturi!
        </p> */}
            <ListPlain className="wishlist-grid">
              {!isLoading ? (
                products && products.length > 0 ? (
                  products.map((item, index) => (
                    <li key={index}>
                      <ProductCard product={item} />
                    </li>
                  ))
                ) : (
                  <li>
                    <span>You have no wishlist</span>
                  </li>
                )
              ) : (
                [1, 2, 3].map(loading => (
                  <li key={loading}>
                    <SkeletonProduct />
                  </li>
                ))
              )}
            </ListPlain>
            {/* <h1 className="recent-heading">Recently viewed</h1>
          <ListPlain className="recently-viewed">

          </ListPlain> */}
          </WishlistColumn>
        </AccountLayout>
      </AccountLayoutContainer>
    </Layout>
  );
};

export default WishlistPage;
