import { functions as functionsPath } from "./paths";

import Cookies from "universal-cookie";
import { getUserToken } from "./user";


export const getProductList = async (): Promise<any> => {
  const _userToken = getUserToken();

  if (!_userToken) {
    return { data: null, status: "NoLogin" };
  }

  const path = `${functionsPath}/products?action=getAllBCProducts`;

  const url = new URL(path, `${process.env.GATSBY_SITE_URL}`);

  const response = await fetch(url.toString(), {
    headers: {
      Accept: "application/json",
    },
    method: "GET",
  });

  if (response.ok) {
    const data: any = await response.json();

    return { data: data, status: "Ok" };
  } else {
    return { data: null, status: "Failed" };
  }
};
