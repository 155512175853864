import type { ProductGroup } from 'schema-dts';

export const browserScope = () => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    return true;
  }
  return false;
};

const extractProductData = (
  product: ProductGroup | { manufacturedIn?: string }
) => {
  return {
    name: product?.name,
    id: product?.sku,
    price: product?.offers?.price,
    brand: 'LAPERLA',
    category: product?.brand
  };
};

export const gtmEventProductDetailView = (
  product: ProductGroup | { manufacturedIn?: string }
) => {
  if (!browserScope()) {
    return;
  }

  if (!window.cookieAccepted) {
    return;
  }

  // Clear the previous ecommerce object.
  window.dataLayer.push({ecommerce: null});
  window.dataLayer.push({
    event: 'ecommerceDetail',
    ecommerce: {
      currencyCode: product?.offers?.priceCurrency,
      detail: {
        products: [
          {
            ...extractProductData(product)
            // variant:
            //   product?.variesBy?.display_size ??
            //   product?.variesBy?.swatch_colour,
          }
        ]
      }
    }
  });
};


export const gtmEventEcommerceImpressions = (
  products: ProductGroup [],
  listName: string
) => {
  if (!browserScope()) {
    return;
  }

  if (!window.cookieAccepted) {
    return;
  }

  // Clear the previous ecommerce object.
  window.dataLayer.push({ecommerce: null}); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'ecommerceImpressions',
    ecommerce: {
      currencyCode: products[0]?.offers?.priceCurrency ?? '',
      impressions: products?.map((product, index) => {
        return {...extractProductData(product), position: index + 1, list: listName};
      })
    }
  });
};


export const gtmEventActions = (
  product: ProductGroup | { manufacturedIn?: string },
  eventName: string,
  detailType: string,
  quantity?: number
) => {
  if (!browserScope()) {
    return;
  }

  if (!window.cookieAccepted) {
    return;
  }

  let dataLayer = {
    event: eventName,
    ecommerce: {
      currencyCode: product?.offers?.priceCurrency,
      [detailType]: {
        products:
          [{
            name: product?.name,
            id: product?.sku,
            price: product?.offers?.price,
            brand: 'LAPERLA',
            category: product?.brand,
            // variant:
            //   product?.variesBy?.display_size ??
            //   product?.variesBy?.swatch_colour,
            quantity: quantity
          }]

      }
    }
  };

  // Clear the previous ecommerce object.
  window.dataLayer.push({ecommerce: null});
  window.dataLayer.push(dataLayer);
};


export const gtmEventEcommerceClick = (
  product: ProductGroup | { manufacturedIn?: string },
  listName: string
) => {
  if (!browserScope()) {
    return;
  }

  if (!window.cookieAccepted) {
    return;
  }

  // Clear the previous ecommerce object.
  window.dataLayer.push({ecommerce: null});
  window.dataLayer.push({
    event: 'ecommerceClick',
    ecommerce: {
      currencyCode: product?.offers?.priceCurrency,
      click: {
        actionField: {list: listName},
        products:
          [{
            ...extractProductData(product)
          }]
      }
    }
  });
};


export const gtmRemoveAction = (
  bagProps: any,
  eventName: string,
  detailType: string
) => {
  if (!browserScope()) {
    return;
  }

  if (!window.cookieAccepted) {
    return;
  }

  let dataLayer = {
    event: eventName,
    ecommerce: {
      currencyCode: bagProps.currency_code,
      [detailType]: {
        products:
          [{
            name: bagProps.name,
            id: bagProps.sku,
            price: bagProps.sale_price,
            brand: 'LAPERLA',
            category: bagProps.brand ? bagProps.brand : '',
            quantity: bagProps.quantity
          }]
      }
    }
  };

  // Clear the previous ecommerce object.
  window.dataLayer.push({ecommerce: null});
  window.dataLayer.push(dataLayer);
};


export const dataLayerEvents = (
  eventCategory: any
) => {
  if (!browserScope()) {
    return;
  }

  if (!window.cookieAccepted) {
    return;
  }

  let dataLayer = {
    event: 'analyticsEvent',
    eventCategory: eventCategory,
    eventAction: 'submit',
    eventLabel: 'success'
  };

  // Clear the previous ecommerce object.
  window.dataLayer.push({ecommerce: null});
  window.dataLayer.push(dataLayer);
};


export const dataLayerFormErrorsEvents = (
  fieldNameErrors: any
) => {
  if (!browserScope()) {
    return;
  }

  if (!window.cookieAccepted) {
    return;
  }

  let dataLayer = {
    event: 'form errors',
    fieldNameErrors: fieldNameErrors
  };

  // Clear the previous ecommerce object.
  window.dataLayer.push({ecommerce: null});
  window.dataLayer.push(dataLayer);
};

export const customDimensionsEvents = (
  dataEvents: any
) => {
  if (!browserScope()) {
    return;
  }

  if (!window.cookieAccepted) {
    return;
  }

  let dataLayer = dataEvents;

  window.dataLayer.unshift(dataLayer);
};
