import { themeGet } from "@styled-system/theme-get";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system";
import { Field, Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { CountryDropdown } from 'react-country-region-selector';

import { mediaQueries } from "../theme";

import { Button, Link } from "./Button";
import { SnackbarContext } from "./Snackbar";

import {
  AddressBook,
  addAddressByCustId,
  updateAddress
} from "../utils/addressBook_dataService";

export type AddressBookInputFieldsProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps & {
    addressItem: AddressBook;
  };

const AddressBookInputFieldsStyled = styled.div`
  .address-book-input-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 50px 30px;
    border-bottom: solid ${themeGet("border.color")} ${themeGet("border.width")};
    padding-block-end: 10px;
    margin-bottom: 20px;

    ${mediaQueries.md} {
      grid-template-columns: repeat(2, 1fr);
    }

    label {
      margin-bottom: 10px;
    }

    input {
      width: 100%;
    }

    select[name="country"] {
      border-color: #efeee9;
      border-width: 1px;
      font-family: "Quicksand";
      font-size: 14px;
      padding: 12px 24px;
    }

    .error {
      font-size: 12px;
      margin-top: 8px;
    }
  }

  .save-btn {

    margin-top: 10px;

    ${mediaQueries.md} {
      margin-top: 30px;
      grid-column: span 2;
    }
  }

  .cancel-save-btn {  
    color: #363139;

    margin-top: -30px;

    ${mediaQueries.md} {
      grid-column: span 2;
    }
  }
`;

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const AddressBookValidation = Yup.object().shape({
  first_name: Yup.string().min(2, "Name is too short").required("Required"),
  last_name: Yup.string().min(2, "Name is too short").required("Required"),
  phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
  street_1: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  zip: Yup.string().required("Required"),
});

export const AddressBookInputFields: React.FC<AddressBookInputFieldsProps> = ({
  setToggleAddNewAddress,
  setToggleEditNewAddress,
  isEdit = false,
  snackbar,
  refreshProps,
  ...props
}) => {
  // const [toggleOuterAddBtn, setToggleOuterAddBtn] = useState(toggleAddNewAddressBtn);
  const { labelText, setSnackbar } = useContext(SnackbarContext);


  return (
    <AddressBookInputFieldsStyled>
      <Formik
        initialValues={{
          first_name: props?.first_name ? props?.first_name : "",
          last_name: props?.last_name ? props?.last_name : "",
          phone: props?.phone ? props?.phone : "",
          company: props?.company ? props?.company : "",
          street_1: props?.street_1 ? props?.street_1 : "",
          street_2: props?.street_2 ? props?.street_2 : "",
          city: props?.city ? props?.city : "",
          country: props?.country ? props?.country : process.env.GATSBY_ENV?.toLowerCase().includes("us") ? "United States" : "United Kingdom",
          state: props?.state ? props?.state : " ",
          zip: props?.zip ? props?.zip : "",
          address_type: "residential"
        }}
        onSubmit={async (
          values: AddressBook,
          { setSubmitting }: FormikHelpers<AddressBook>
        ) => {

          if (!isEdit) {
            
            await addAddressByCustId(values, setSubmitting, setSnackbar);
            
  
            // setToggleAddNewAddress(false);
          } else {
              const data = await updateAddress(props?.id, values, snackbar);

              refreshProps(data.data);
  
              setToggleEditNewAddress(false);
          }
      
        }}
        validationSchema={AddressBookValidation}
      >
        {({ errors, touched, values, handleChange }) => (
          <Form className="address-book-input-container">
            <section>
              <label htmlFor="first_name">First Name*</label>
              <Field
                as="input"
                id="first_name"
                name="first_name"
                placeholder="Enter first name"
                type="text"
              />
              {errors.first_name && touched.first_name && (
                <div className="error">{errors.first_name}</div>
              )}
            </section>

            <section>
              <label htmlFor="last_name">Last Name*</label>
              <Field
                as="input"
                id="last_name"
                name="last_name"
                placeholder="Enter last name"
                type="text"
              />
              {errors.last_name && touched.last_name && (
                <div className="error">{errors.last_name}</div>
              )}
            </section>

            <section>
              <label htmlFor="phone">Phone*</label>
              <Field
                as="input"
                id="phone"
                name="phone"
                placeholder="Enter phone"
                type="text"
              />
              {errors.phone && touched.phone && (
                <div className="error">{errors.phone}</div>
              )}
            </section>

            <section>
              <label htmlFor="company">Company</label>
              <Field
                as="input"
                id="company"
                name="company"
                placeholder="Enter company"
                type="text"
              />
            </section>

            <section>
              <label htmlFor="street_1">Street 1*</label>
              <Field
                as="input"
                id="street_1"
                name="street_1"
                placeholder="Enter street1"
                type="text"
              />
              {errors.street_1 && touched.street_1 && (
                <div className="error">{errors.street_1}</div>
              )}
            </section>

            <section>
              <label htmlFor="street_2">Street 2</label>
              <Field
                as="input"
                id="street_2"
                name="street_2"
                placeholder="Enter street2"
                type="text"
              />
            </section>

            <section>
              <label htmlFor="city">City*</label>
              <Field
                as="input"
                id="city"
                name="city"
                placeholder="Enter city"
                type="text"
              />
              {errors.city && touched.city && (
                <div className="error">{errors.city}</div>
              )}
            </section>

            <section>
              <label htmlFor="state">State*</label>
              <Field
                as="input"
                id="state"
                name="state"
                placeholder="Enter state"
                type="text"
              />
              {errors.state && touched.state && (
                <div className="error">{errors.state}</div>
              )}
            </section>

            <section>
              <label htmlFor="zip">Zip*</label>
              <Field
                as="input"
                id="zip"
                name="zip"
                placeholder="Enter zip"
                type="text"
              />
              {errors.zip && touched.zip && (
                <div className="error">{errors.zip}</div>
              )}
            </section>

            <section>
              <label htmlFor="country">Country*</label>

              <CountryDropdown name="country" value={values.country} onChange={(_, e) => handleChange(e)} />
              {/* <Field
                as="input"
                id="country"
                name="country"
                placeholder="Enter country"
                type="text"
              /> */}
              {errors.country && touched.country && (
                <div className="error">{errors.country}</div>
              )}
            </section>

            {/* 
            <label htmlFor="address_type">Address Type</label>
            <Field
              as="input"
              id="address_type"
              name="address_type"
              placeholder="Enter address type"
              type="text"
            /> */}

            <Button
              className="save-btn"
              type="submit"
              variant="primary"
              py={{ md: 4 }}
              px={{ md: 9 }}
              // onClick={() => {submitClick({values: values})}}
            >
              Save
            </Button>

            <Button
              className="cancel-save-btn"
              type="button"
              variant="tertiary"
              py={{ md: 4 }}
              px={{ md: 9 }}
              onClick={() => {isEdit ? setToggleEditNewAddress(false) : setToggleAddNewAddress(false)}}
            >
              Cancel
            </Button>
          </Form>
        )}
      </Formik>
    </AddressBookInputFieldsStyled>
  );
};
