import type { ProductGroup } from "schema-dts"

import clsx from "clsx"
import React from "react"
import styled from "styled-components"
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system"
import { Link } from "gatsby"

const ProductSelectorColourStyled = styled.div`

  .form-fields {
    grid-auto-flow: column;
    justify-content: start;
    display: flex;
    flex-wrap: wrap;
  }

  .dot {
    height: 1.8rem;
    width: 1.8rem;
    border-radius: 50%;
    display: inline-block;
    // position: absolute;
    // left: 0.25rem;
    // top: 0.25rem;
  }

  .circle{
    position: relative;
    display: grid;
    place-items: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 1px solid #EFEEE9;
  }

  .active {
    border: 1px solid #363139;
  }
  
  ${compose(color, flexbox, grid, layout, position, space)}
`

export type ProductSelectorColourProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps & {
    productGroup: ProductGroup
  }

export const ProductSelectorColour: React.FC<ProductSelectorColourProps> = ({
  product,
  ...props
}) => {
  return (
    <ProductSelectorColourStyled {...props}>
      <h3 className="title" id="group_label_colours">
        COLOUR
      </h3>
      <div
        aria-labelledby="group_label_colours"
        className={clsx('form-fields')}
      >
        {product?.hasVariant.map((variant, index) => (
          <Link
            to={variant.url}
            className={"field circle " + (variant.productID === product.productID ? 'active' : '')}
            key={index}
          >
            <span className="dot" style={{ backgroundColor: variant.variesBy.swatch_colour }} />
          </Link>
        ))}
      </div>
    </ProductSelectorColourStyled>
  );
}
