import React from "react"
import styled from "styled-components"
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system"

import { BannerGallery, BannerGalleryProps } from "./BannerGallery"
import { mediaQueries } from "../theme"

export type ImageGalleryProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps & BannerGalleryProps

export const ImageGalleryStyled: React.FC<ImageGalleryProps> = styled.section`
${mediaQueries.xsmDown} {
  .image-gallery {
    margin-left: -1rem;
    margin-right: -1rem;
  }
}

  ${compose(color, flexbox, grid, layout, position, space)}
`

export const ImageGallery: React.FC<ImageGalleryProps> = props => (
  <ImageGalleryStyled
    {...props}
  >
    <BannerGallery className="image-gallery" layout="image-gallery" {...props} />
  </ImageGalleryStyled>
)
