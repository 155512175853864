import type { Bag } from "../../types/BigCommerce"

import { functions as functionsPath } from "../utils/paths"
import { Account } from "../utils/user"
import Cookies from "universal-cookie"

export const getCartContents = async (setBagContents: (data: Bag) => void): Promise<void> => {
  // console.log("document.cookie", document.cookie)

  // const cookies = cookie.parse(document.cookie)
  // console.log("cookies", cookies)

  // const { cartId } = cookies

  const cartId = window.localStorage.getItem("cartId")

  if (!cartId) {
    return
  }

  const path = `${functionsPath}/carts?action=getCart&cartId=${cartId}`

  const url = new URL(path, `${process.env.GATSBY_SITE_URL}`)

  const response = await fetch(url.toString(), {
    // credentials: "same-origin",
    headers: {
      Accept: "application/json",
    },
    method: "GET",
  })

  if (response.ok) {
    const data: Bag = await response.json()
    setBagContents(data)
  }
  else {
    console.log("cart is not valid");
    setBagContents({});
  }
}

export const getCartId = async (): Promise<string | undefined> => {
    const path = `/.netlify/functions/carts`

    const url = new URL(path, `${process.env.GATSBY_SITE_URL}`)

    const response = await fetch(url.toString(), {
      headers: {
        Accept: "application/json",
      },
      method: "GET",
    })

    if (response.ok) {
        const cart = await response.json()

        return cart.id
    }

    return undefined
}

export interface AddValues {
  identifier: string,
  currencyCode?: string,
  userToken?: string
}
export interface UpdateValues {
  itemId: string,
  identifier: number,
  quantity: number
}
export interface DeleteValues {
  itemId: string
}

export const addToCart = async (values: AddValues, setSubmitting: (isSubmitting: boolean) => void, setBag: (bag: Bag) => void, setSnackbar: (labelText: string | undefined) => void): Promise<void> => {
  let path = `${functionsPath}/carts?action=createCart`

  const cartId = window.localStorage.getItem("cartId")

  if (cartId) {
    path = `${functionsPath}/carts?action=addCartLineItems&cartId=${cartId}`
  } else {
    values.currencyCode = process.env.GATSBY_ENV?.toLowerCase().includes("us") ? "USD" : (new Cookies()).get('geOriginalCurrencyCode') || 'EUR';
    const user = window.localStorage.getItem("user");
    if(user) {
        values.userToken = (JSON.parse(user) as Account)?.userToken;
    }
  }

  const url = new URL(path, `${process.env.GATSBY_SITE_URL}`)

  const response = await fetch(url.toString(), {
    body: JSON.stringify(values),
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
    },
    method: "POST",
  })

  setSubmitting(false)

  if (response.ok) {
    const data: Bag = await response.json()
    setBag(data)
  } else {
    setSnackbar(response.statusText)
  }
}

export const updateCartUser = async (): Promise<any> => {

  const cartId = window.localStorage.getItem("cartId");
  const user = window.localStorage.getItem("user");
  if(!cartId || !user) {
    return;
  }
  const userToken = (JSON.parse(user) as Account)?.userToken;
  if(userToken === undefined) {
    return;
  }
  // console.log(`Setup cart ${cartId} for user ${userToken}`);
  const path = `${functionsPath}/carts?action=updateCartUser&cartId=${cartId}&userToken=${userToken}`
  const url = new URL(path, `${process.env.GATSBY_SITE_URL}`)

  return await fetch(url.toString(), {
    body: JSON.stringify({}),
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
    },
    method: "PUT",
  });
}

export const updateCartItem = async (values: UpdateValues, setBag: (bag: Bag) => void, setSnackbar: (labelText: string | undefined) => void): Promise<void> => {

  const cartId = window.localStorage.getItem("cartId")
  if (!cartId) {
    return;
  }

  let path = `${functionsPath}/carts?action=updateCartLineItem&cartId=${cartId}&itemId=${values.itemId}`
  const url = new URL(path, `${process.env.GATSBY_SITE_URL}`)

  const response = await fetch(url.toString(), {
    body: JSON.stringify(values),
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
    },
    method: "PUT",
  })

  if (response.ok) {
    const data: Bag = await response.json()

    setBag(data)
  } else {
    setSnackbar(response.statusText)
  }
}

export const deleteCartItem = async (values: DeleteValues, setBag: (bag: Bag) => void, setSnackbar: (labelText: string | undefined) => void): Promise<void> => {

  const cartId = window.localStorage.getItem("cartId")
  if (!cartId) {
    return;
  }

  let path = `${functionsPath}/carts?action=deleteCartLineItem&cartId=${cartId}&itemId=${values.itemId}`
  const url = new URL(path, `${process.env.GATSBY_SITE_URL}`)

  const response = await fetch(url.toString(), {
    body: JSON.stringify(values),
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
    },
    method: "DELETE",
  })
  if (response.status === 200) {
    const data: Bag = await response.json()
    setBag(data)
  } else if (response.status === 204){
    setBag({});
    console.log("cart has been removed");
  }else {
    setSnackbar(response.statusText)
  }
}

export const deleteCart = async (setBagContents: (data: Bag) => void): Promise<void> => {

  const cartId = window.localStorage.getItem("cartId")

  if (!cartId) {
    return
  }

  const path = `${functionsPath}/carts?action=deleteCart&cartId=${cartId}`

  const url = new URL(path, `${process.env.GATSBY_SITE_URL}`)

  const response = await fetch(url.toString(), {
    headers: {
      Accept: "application/json",
    },
    method: "DELETE",
  })

  if (response.ok) {
    console.log("cart deleted");
    setBagContents({});
  }
  else {
    console.log("cart is not valid");
    setBagContents({});
  }
}
