import type {
  // FooterQuery
  // Contentstack_Menu_Component,
  LayoutQuery,
} from "../../graphql-types"

import { themeGet } from "@styled-system/theme-get"
import React, { useState } from "react"
import styled from "styled-components"
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system"

import { ReactComponent as Close } from "../../static/icons/Close.svg"
import { ReactComponent as Hamburger } from "../../static/icons/Hamburger.svg"

import { mediaQueries } from "../theme"

import { Accordion } from "./Accordion"
import { Link } from "./Button"
import { MenuStore } from "./MenuStore"

const StyledHamburger = styled.nav`
  button {
    left: -${themeGet("space.5")}px;
    position: relative;
    z-index: 50;

    svg {
      cursor: pointer;
      height: ${themeGet("space.5")}px;
      object-fit: contain;

      ${mediaQueries.md} {
        display: none;
      }
    }
  }

  .container {
    background-color: ${themeGet("colors.background")};
    bottom: 0;
    height: 100vh;
    left: 0;
    overflow-y: auto;
    padding-block-start: ${themeGet("space.12")}px;
    position: absolute;
    right: 0;
    top: 0;
  }

  ${compose(color, flexbox, grid, layout, position, space)}
`

export type MenuHamburgerProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps & {
    data?: LayoutQuery
  }

export const MenuHamburger: React.FC<MenuHamburgerProps> = ({
  data,
  ...props
}) => {
  const [toggle, setToggle] = useState(false)
  return (
    <React.Fragment>
      <StyledHamburger {...props}>
        <button onClick={() => setToggle(!toggle)}>
          {(toggle && <Close />) || <Hamburger />}
        </button>

        {toggle && (
          <div className="container">
            {process.env.GATSBY_ENV?.toLowerCase().includes("us") ? (
              <MenuStore
                display={{ md: "flex" }}
                gridArea="menu-primary"
                left="-2px"
                position="relative"
                marginBottom="20px"
              />
            ) : (
              <MenuStore
                display={{ _: "none", md: "flex" }}
                gridArea="menu-primary"
                left="-2px"
                position="relative"
                marginBottom="20px"
              />
            )}
            {
              <Accordion
                className="hamburger-accordion"
                items={data?.allContentstackMenuComponent?.edges
                  .find(({ node: menu }) =>
                    menu.slot?.startsWith("mobile-navigation")
                  )
                  ?.node?.links.map(link => ({
                    title: link.link.title,
                    panel: (
                      <ul>
                        {link.sub_menus?.[0]?.links?.map((link, index) => (
                          <li key={index}>
                            <Link to={link?.link?.href}>
                              {link?.link?.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    ),
                  }))}
              />
            }
          </div>
        )}
      </StyledHamburger>
    </React.Fragment>
  )
}
