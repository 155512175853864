
import styled from "styled-components";
import { mediaQueries } from "../theme";
import React, { useContext } from "react";
import { Button, Link } from "./Button";

import { SnackbarContext } from "./Snackbar";
import { dataLayerFormErrorsEvents } from "../utils/gtm";
import { ImgOptimized } from "./ImgOptimized";
import EnvelopeBrownIcon from "../../static/icons/Envelope_brown_icon.svg";
import SuccessGreenIcon from "../../static/icons/Success-green-icon.svg";
import Cookies from "universal-cookie";
import { resetPassword } from "../utils/password_dataService";

export const PasswordResetResult: React.FC<any> = ({
  isChangePassword = false,
  ...props
}) => {
  const cookies = new Cookies();

  const PasswordResetResultStyled = styled.section`
    text-align: center;

    .main-content-container {
      text-align: center;
      
    }

    .icon {
      max-width: 40px;
      margin-bottom: 10px;

      ${mediaQueries.sm} {
        max-width: 100%;
        width: 50px;
      }
    }

    .title {
      font-size: 24px;
      letter-spacing: 0.5px;
      line-height: 30px;
      margin-bottom: 12px;

      ${mediaQueries.sm} {
        font-size: 32px;
        letter-spacing: 0.7px;
        line-height: 38px;
      }
    }

    .message {
      font-size: 13px;
      letter-spacing: 1px;
      line-height: 17px;

      ${mediaQueries.sm} {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .btn-container {
      margin-top: 30px;
    }

    .resend-btn {
      max-width: 200px;
      width: 100%;
      margin: auto;
      font-weight: bold;
      color: #363139;
      padding: 14px 16px;

      ${mediaQueries.sm} {
        max-width: 210px;
      }
    }

    ${mediaQueries.sm} {
    }
  `;

  return (
    <PasswordResetResultStyled>
      {!isChangePassword ? (
        <>
          <div className="main-content-container">
            <img className="icon" src={EnvelopeBrownIcon} title="icon" />
            <h1 className="title">Check your email</h1>
            <p className="message">
              We have sent password reset instructions to your email. Please
              follow the instructions to reset your password.
            </p>
          </div>
          <div className="btn-container">
            <p className="message">Didn’t receive an email?</p>
            <Button
              className="resend-btn"
              variant="tertiary"
              py={{ md: 4 }}
              px={{ md: 9 }}
              onClick={async () => { 
                let status = await resetPassword({email: localStorage.getItem('reset_pw_email') || '' });

          
                if (status.status == "success") {

                  console.log("Sent again");
                  
                }
              } }
            >
              Resend Email
            </Button>
          </div>{" "}
        </>
      ) : (
        <>
          <div className="main-content-container">
            <img className="icon" src={SuccessGreenIcon} title="icon" />
            <h1 className="title">Password Updated</h1>
            <p className="message">
              Your password has been changed successfully. <br/>Please use your new
              password to sign in.
            </p>
          </div>
        </>
      )}
    </PasswordResetResultStyled>
  );
};
