import { themeGet } from "@styled-system/theme-get";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system";

import { mediaQueries } from "../theme";
import { SnackbarContext } from "./Snackbar";

import { Button, Link } from "./Button";
import { ListPlain } from "./ListPlain";
import getSymbolFromCurrency from "currency-symbol-map";
import Moment from "moment";
import { useToggle } from "../hooks/useToggle";
import { Accordion } from "./Accordion";
import { deleteAddress, updateAddress } from "../utils/addressBook_dataService";
import { AddressBookInputFields } from "./AddressBookInputFields";

export type AddressBookItemProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps & {
    addressItem: any;
  };

const AddressBookItemStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 30%;
  gap: 50px 30px;
  border-bottom: solid ${themeGet("border.color")} ${themeGet("border.width")};
  padding-block-end: 30px;
  margin-bottom: 30px;

  .address-book-text-container {
    .address-book-text-name {
      color: #363139;
      font-family: "Quicksand";
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 20px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    .address-book-text-address,
    .address-book-text-phone {
      font-family: "Quicksand";
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 20px;
      margin-top: 0;
    }
  }

  .address-book-cta-container {
    button {
      width: 100%;
      margin-bottom: 20px;
      font-family: "Quicksand";
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 18px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .edit-btn {
      color: #363139;
    }

    .disabled {
      background-color: #efeee9;
      color: #858585;
      cursor: default;
      pointer-event: none;
    }
  }
`;

export const AddressBookItem: React.FC<AddressBookItemProps> = props => {
  const { labelText, setSnackbar } = useContext(SnackbarContext);
  const [toggleEditNewAddress, setToggleEditNewAddress] = useState(false);
  const [dataProps, setProps] = useState(props);

  return (
    <AddressBookItemStyled>
      <div className="address-book-text-container">
        {!toggleEditNewAddress ? (
          <React.Fragment>
            <h4 className="address-book-text-name">
              {dataProps.first_name} {dataProps.last_name}
            </h4>
            <p className="address-book-text-address">
              {dataProps.street_1}, {dataProps.city} <br />
              {dataProps.state}, {dataProps.country}, {dataProps.zip} <br />
              Phone No: {dataProps.phone}
            </p>
          </React.Fragment>
        ) : (
          <AddressBookInputFields setToggleEditNewAddress={setToggleEditNewAddress} isEdit={true} snackbar={setSnackbar} refreshProps={setProps} {...dataProps} />
        )}
        
      </div>
      <div className="address-book-cta-container">
        <Button
          variant="tertiary"
          py="5"
          px="7"
          className={"edit-btn " + (toggleEditNewAddress ? "disabled" : "")}
          onClick={() => setToggleEditNewAddress(true)}
        >
          Edit
        </Button>
        <Button
          variant="primary"
          py="5"
          px="7"
          onClick={async () => await deleteAddress(dataProps.id, setSnackbar)}
        >
          Delete
        </Button>
      </div>
    </AddressBookItemStyled>
  );
};
