import React, { useEffect, useState } from "react"
import styled from "styled-components"
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system"

import { mediaQueries } from "../theme"

import { ArticleCard, ArticleCardProps } from "./ArticleCard"
import { Carousel } from "./Carousel"

const ArticleCardGalleryStyled = styled.section`

.two-item {

  ${mediaQueries.sm} {
    .figure-video {
      max-height: 450px;
      aspect-ratio: 4/2;
  
      img {
        object-fit: cover;
        object-position: center;
      }
    }
  }

}


  .title {
    font-size: var(--font-size-lg, 18px);
  }

  ${mediaQueries.md} {
    margin-top: ${props => props.marginTop ? `${props.marginTop.md}rem !important;` : undefined};
    margin-bottom: ${props => props.marginBottom ? `${props.marginBottom.md}rem !important;` : undefined};
  }

  margin-top: ${props => props.marginTop ? `${props.marginTop._}rem !important;` : undefined};
  margin-bottom: ${props => props.marginBottom ? `${props.marginBottom._}rem !important;` : undefined};

  ${compose(color, flexbox, grid, layout, position, space)}
`

export type ArticleCardGalleryProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps & {
    items: ArticleCardProps[]
    text: string
    title: string
  }

export const ArticleCardGallery: React.FC<ArticleCardGalleryProps> = props => {
  const [visibleItems, setVisibleItems] = useState(3);

  useEffect(() => {
    
    if ((props.items.length == 2)) {
      setVisibleItems(2);
    }

  }); 
  
  return (
  <ArticleCardGalleryStyled {... props}>
    {props.title && <h1 className="title">{props.title}</h1>}
    {props.text && <p className="text">{props.text}</p>}
    <Carousel visibleItems={visibleItems} showArrows>
      {props.items?.map((item, index) => (
        <ArticleCard
          className={"item " + (visibleItems == 2 ? "two-item": "")}
          key={index}
          titlePosition={index % 2 == 0 ? "bottom" : "top"}
          {...item}
        />
      ))}
    </Carousel>
  </ArticleCardGalleryStyled>
)}

export default ArticleCardGallery
