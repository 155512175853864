import type * as CSS from 'csstype';

import React from "react"
import ReactModal from "react-modal"
import styled from "styled-components"
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system"

import { ReactComponent as Close } from "../../static/icons/Close.svg"
import { ReactComponent as CloseWhite } from "../../static/icons/close_white.svg"

import { theme } from "../theme"

import { Button } from "./Button"

import { mediaQueries } from "../theme";

const ModalOverlayStyleDefault: CSS.Properties = {
  backgroundColor: "rgb(255, 255, 255)",
  borderRadius: theme.radii[4],
  inset: "180px 40px",
  overflow: "auto",
  padding: "20px 30px",
  position: "absolute",
}

const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  justify-content: center;

  .close-button {
    align-self: end;
    align-self: flex-end;
    padding: 0;

    ${mediaQueries.smDown}{
      position: absolute;
      top: 50px;
      right: 20px;
    }

  }

  .title {
    text-align: center;
  }

  &.modal-questions {
    padding: 0;

    &.modal-gift {
      .close-button {
        color: #ffffff;        
      }
    }

    .modal-main {
      padding: 0 47px;

      ${mediaQueries.smDown}{
        padding: 0;
      }
    }
  }

  &.video-player {
    padding: 0;

    aside {
      border-radius: 0;
    }

    figure {
      margin-top: 0;
    }

    button {
      position: absolute;
      right: 1rem;
      top: 1rem;
      z-index: 1;
    }

    iframe {
      border: none;
    }
  }

  &.newsletter-thankyou {

    ${mediaQueries.smDown}{
      padding: 0;
    }


    .content-container {
      display: flex;
      column-gap: 45px;
      align-items: center;
    }
  
    .image-container {
      width: 375px;
      height: 250px;
      border-radius: 20px;

      ${mediaQueries.smDown}{
        display: none;
      }
  
      img {
        object-fit: cover;
        object-position: center -65px;
        width: 100%;
        max-height: 100%;
      }
    }
  
    .text-container {
  
    }
  
    .close-button {
      right: 3rem;
      top: 2rem;
      position: absolute;

      ${mediaQueries.smDown}{
        right: 2rem;
      }
    }
  }

  ${compose(color, flexbox, grid, layout, position, space)}
`

export type ModalProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps & {
    closeModal: React.DispatchWithoutAction
    contentLabel: string
    isOpen: boolean
    ModalOverlayStyleCustom?: CSS.Properties
  }

function afterOpenModal() {
  ReactModal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.5)"
  ReactModal.defaultStyles.overlay.zIndex = "99999"
}

export const Modal: React.FC<ModalProps> = ({
  children,
  closeModal,
  ModalOverlayStyleCustom,
  useWhite = false,
  ...props
}) => {
  return (
    <ReactModal
      contentLabel={props.contentLabel}
      isOpen={props.isOpen ? props.isOpen : false}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={{
        content: { ...ModalOverlayStyleDefault, ...ModalOverlayStyleCustom },
      }}
    >
      <WrapperStyled {...props}>
        <Button className="close-button" onClick={closeModal}>
          {useWhite ? <CloseWhite /> : <Close />}
        </Button>

        {children}
      </WrapperStyled>
    </ReactModal>
  )
}
